import React, { useState, useReducer, useEffect, useMemo } from 'react';
import { Table, InputGroup, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import axios from '../../utils/axiosInstance';
import { getUser } from '../../utils/requireAuth';
import Paging from '../Paging/Paging';

const INITIAL_STATE = {
    sources: [],
    startIndex: 0,
    pageNumber: 0,
    pageSize: 10,
    totalRecords: 0
}

const styles = {
    pointer: { cursor: 'pointer' },
    center: { textAlign: 'center' }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'load':
            if (action.payload.items) {
                return {
                    ...state,
                    sources: action.payload.items,
                    pageNumber: action.payload.pageNumber,
                    totalRecords: action.payload.totalCount,
                    startIndex: action.payload.recordNumber
                };
            }
            return state;
        case 'paging':
            const maxPageNumber = Math.ceil(state.totalRecords / state.pageSize);
            const pageNumber = Math.min(Math.max(action.payload, 1), maxPageNumber);
            return {
                ...state,
                pageNumber: pageNumber,
                startIndex: (pageNumber - 1) * state.pageSize
            }
        case 'reset':
            return INITIAL_STATE;
        default:
            throw new Error(`Action type ${action.type} is no part of reducer in SourceTable.jsx`);
    }
}

const SourceTable = ({ callbackFunction }) => {
    const [search, setSearch] = useState('');
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const tableRows = useMemo(() => createTableRows(state.sources), [state.sources]);
    const user = getUser();

    useEffect(() => {
        populateSourceData(search);
    }, [search, state.pageSize, state.pageNumber, state.totalCount]);

    function populateSourceData(searchText) {
        const token = user ? user.token : '';
        axios.get('/source/search', {
            params: {
                search: searchText,
                startIndex: state.startIndex,
                pageSize: state.pageSize,
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch({ type: 'load', payload: response.data });
        }).catch(err => {
            dispatch({ type: 'reset' });
            console.log(err);
        })
    }

    function createTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.center}>
                <td colSpan={3}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx} style={styles.pointer} onClick={() => callbackFunction(item.id)}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.sourceType || 'N/A'}</td>
            </tr>
        })
    }

    return (
        <div>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Searchtext</InputGroup.Text>
                <Form.Control
                    placeholder={'Searchtext for sources...'}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} />
            </InputGroup>

            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>

            <Paging currentPage={state.pageNumber} totalCount={state.totalRecords} pageSize={state.pageSize} callbackFunction={(i) => dispatch({ type: 'paging', payload: i })} />
        </div>
    )
}

SourceTable.propTypes = {
    callbackFunction: PropTypes.func.isRequired
}

export default SourceTable;
