import React, { useState, useEffect, useMemo } from 'react';
import { FloatingLabel, Form, Table } from 'react-bootstrap';
import axios from 'utils/axiosInstance';
import './WorkRoleSearch.css';

export default function WorkRoleSearch({ callbackFunction }) {
  const [search, setSearch] = useState('');
  const [workRoles, setWorkRoles] = useState([]);
  const tableRows = useMemo(() => createTableRows(workRoles), [workRoles]);

  useEffect(() => {
    axios.get('options/workroles', {
      params: {
        search: search
      }
    }).then(response => {
      if (response.status === 200) {
        setWorkRoles(response.data);
      }
    }).catch(err => {
      console.log(err);
    });
  }, [search]);

  function createTableRows(data) {
    if (data.length === 0) {
      return <tr className='WorkRoleCenter'>
        <td colSpan={3}>No data available</td>
      </tr>
    }

    return data.map((item, idx) => {
      return <tr className={'WorkRoleRow'} onClick={() => callbackFunction(item)}>
        <td>{item.id}</td>
        <td>{item.role}</td>
        <td>{item.roleCode}</td>
      </tr>
    })
  }

  return (
    <div>
      <FloatingLabel className={'mb-2'} label={'🔍 Searchtext'}>
        <Form.Control
          placeholder={'Searchtext'}
          value={search}
          onChange={(e) => setSearch(e.target.value)} />
      </FloatingLabel>
      <div className="WorkRoleTable">
        <Table striped hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Role</th>
              <th>Role Code</th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </Table>
      </div>
    </div>
  )
}