import React, { useState, useEffect, useMemo, useReducer } from "react";
import { FloatingLabel, Form, Table } from 'react-bootstrap';
import Paging from "../Paging/Paging";
import PropTypes from 'prop-types';
import axios from '../../utils/axiosInstance';
import './CompanyTable.css';

/**
 * Initial state of the component containing all relevant parameters
 */
const INITIAL_STATE = {
    companies: [],
    startIndex: 0,
    pageNumber: 0,
    pageSize: 10,
    totalRecords: 0
}

/**
 * Reducer function for managing the state of the component
 * @param {object} state Current state of the component
 * @param {object} action Action that contains the information how the
 * current state should be manipulated.
 * @returns{object} new state of the component
 */
const reducer = (state, action) => {
    switch (action.type) {
        case "SET":
            if (action.payload.items) {
                return {
                    ...state,
                    companies: action.payload.items,
                    pageNumber: action.payload.pageNumber,
                    totalRecords: action.payload.totalCount,
                    startIndex: action.payload.recordNumber
                };
            }
            return state;
        case "paging":
            return {
                ...state,
                startIndex: action.payload.startIndex,
                pageNumber: action.payload.pageNumber
            }
        case 'pageNumber':
            const maxPageNumber = Math.ceil(state.totalRecords / state.pageSize);
            const pageNumber = Math.min(Math.max(action.payload, 1), maxPageNumber);
            return {
                ...state,
                pageNumber: pageNumber,
                startIndex: (pageNumber - 1) * state.pageSize
            }
        case 'reset':
            return INITIAL_STATE;
        default:
            throw new Error(`Action type ${action.type} is no part of reducer in CompanyTable`);
    }
}

/**
 * Function creates the table for displaying the companies to the website
 * @param {object} props Properties that were passed from the parent component
 * @returns HTML object for the company table
 */
export default function CompanyTable(props) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [search, setSearch] = useState('');
    const companyRows = useMemo(() => createTableRows(state.companies), [state.companies, search]);

    /**
     * Function gets called when the page is loaded or an
     * parameter for search is entered
     */
    useEffect(() => {
        populateCompanyData(props.link);
    }, [search, state.startIndex, state.pageSize]);

    /**
     * Function fetches the companies from the backend
     * @param {string} link Link from where the companies will be fetched
     */
    function populateCompanyData(link) {
        axios.get(link, {
            params: {
                startIndex: state.startIndex,
                pageSize: state.pageSize,
                searchParameter: search
            }
        }).then(response => {
            dispatch({ type: 'SET', payload: response.data })
        }).catch(err => {
            dispatch({ type: 'reset' });
            console.log(err);
        });
    }

    /**
     * Function will map the companies as table rows
     * @param {array} companies Array with companies
     * @returns Array HTML elements that represent the table row
     */
    function createTableRows(companies) {
        return companies.map((company, idx) => {
            return (
                <tr key={idx} onClick={() => props.callbackFunction(company.id)}>
                    <td>{company.id}</td>
                    <td>{company.name}</td>
                    <td>{company.isEnabled ? 'Yes' : 'No'}</td>
                </tr>
            )
        });
    }

    return (
        <div className="companyTableWrapper">
            <FloatingLabel label={'🔍 Searchtext'} className='mb-2'>
                <Form.Control
                    autoFocus={true}
                    placeholder="Searchtext"
                    aria-label="Searchtext"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} />
            </FloatingLabel>

            <div className="companyTable">
                <Table bordered striped hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Enabled</th>
                        </tr>
                    </thead>
                    <tbody>
                        {companyRows}
                    </tbody>
                </Table>
            </div>
            <Paging currentPage={state.pageNumber} totalCount={state.totalRecords} pageSize={state.pageSize} callbackFunction={(i) => dispatch({ type: 'pageNumber', payload: i })} />
        </div>
    )
}

CompanyTable.propTypes = {
    /**
     * Link from where the companies will be fetched
     */
    link: PropTypes.string.isRequired,
    /**
     * Callback function that will be called when the user is
     * clicking on a row inside of the table. The function will
     * take the companyId as an input parameter
     */
    callbackFunction: PropTypes.func.isRequired
}