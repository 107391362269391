import React, { useEffect, useMemo, useState } from 'react';
import { InputGroup, Form, Table, Button, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AppModal from '../Modal/Modal';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';
import CompanySourceModal from './CompanySourceModal';
import SourceContacts from './SourceContacts';
import SourceContactModal from './SourceContactModal';

const styles = {
    fullWidth: { width: '100%' },
    alignCenter: { textAlign: 'center' }
}

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const CompanySources = ({ companyId }) => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [companySources, setCompanySources] = useState([]);
    const [contactPeople, setContactPeople] = useState([]);
    const tableRows = useMemo(() => createTableRows(companySources), [companySources]);
    const user = getUser();

    useEffect(() => {
        populateCompanySourceData();
    }, []);

    useEffect(() => {
        if (companySources.length > 0) {
            populateSourceContactData();
        } else {
            setContactPeople([]);
        }
    }, [companySources]);

    function populateCompanySourceData() {
        const token = user ? user.token : '';
        axios.get('company/sources', {
            params: {
                company: companyId
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            setCompanySources(response.data);
        }).catch(err => {
            console.log(err);
            setCompanySources([]);
        });
    }

    function populateSourceContactData() {
        const token = user ? user.token : '';
        axios.get('company/sourcecontactperson', {
            params: {
                company: companyId
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 200) {
                setContactPeople(response.data);
            }
        }).catch(err => {
            console.log(err);
            setContactPeople([]);
        })
    }

    function deleteCompanySourceModal(source) {
        setModal({
            show: true,
            title: 'Delete source from company',
            body: <div>
                <p>
                    Do you really want to delete the source from the company?
                    Please keep in mind that this action cannot be reversed.
                </p>
                <Row>
                    <Col>
                        <Button style={styles.fullWidth} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button style={styles.fullWidth} variant={'danger'} onClick={() => deleteCompanySourceHandler(source)}>Delete</Button>
                    </Col>
                </Row>
            </div>
        })
    }

    function deleteCompanySourceHandler(source) {
        const token = user ? user.token : '';
        axios.delete('company/sources', {
            params: {
                company: companyId,
                source: source
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 204) {
                populateCompanySourceData();
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            setModal({
                show: true,
                title: 'Failed to delete company source',
                body: <p>
                    The source could not be deleted from the company because of an error: {err?.response?.data?.message || err.message}
                </p>
            });
        })
    }

    function createTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={5}>No dava available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx}>
                <td>{item.source}</td>
                <td>{item.sourceName}</td>
                <td>{item.sourceType || 'N/A'}</td>
                <td><Button size={'sm'} variant={item.hasContactPerson ? 'success' : 'warning'} onClick={() => createNewContactPerson(item.source)}>{item.hasContactPerson ? 'Add further contact' : 'Add contact person'}</Button></td>
                <td><Button size={'sm'} variant={'outline-danger'} onClick={() => deleteCompanySourceModal(item.source)}>Delete</Button></td>
            </tr>
        })
    }

    function createNewContactPerson(source) {
        const token = user ? user.token : '';
        const data = {
            company: companyId,
            source: source
        }
        axios.post('company/sourcecontactperson', data, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 201) {
                populateCompanySourceData();
                const data = response.data;
                editContactPerson(data.id);
            }
        }).catch(err => {
            console.log(err);
            setModal({
                show: true,
                title: 'Failed to create source contact',
                body: (
                    <p>
                        The source contact could not be created because of an error: {err?.response?.data?.message || err.message}
                    </p>
                )
            })
        })
    }

    function showAddCompanySourceModal() {
        setModal({
            show: true,
            title: 'Select source',
            body: <CompanySourceModal callbackFunction={addCompanySourceModal} />
        })
    }

    function closeEditContactPersonModal() {
        populateSourceContactData();
        setModal(INITIAL_MODAL_STATE);
    }

    function editContactPerson(contactId) {
        setModal({
            show: true,
            title: 'Edit Source Contact',
            body: <SourceContactModal contactId={contactId} callbackFunction={closeEditContactPersonModal} />
        });
    }

    function addCompanySourceModal(source) {
        const token = user ? user.token : '';
        const data = {
            company: companyId,
            source: source
        }
        axios.post('company/sources', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 201) {
                populateCompanySourceData();
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            setModal({
                show: true,
                title: '',
                body: <p>
                    The source could not be assigned to the
                    company because of an error:
                    {err?.response?.data?.message || err.message}
                </p>
            });
        });
    }

    /**
     * Asks the user if the contact person should be deleted
     * @param {number} contactId ID of the source contact
     */
    function deleteContactPersonModal(contactId) {
        setModal({
            show: true,
            title: 'Delete contact person',
            body: <div>
                <p>
                    Do you really want to delete the contact person? Please keep
                    in mind that this action cannot be reversed.
                </p>
                <Row>
                    <Col>
                        <Button style={styles.fullWidth} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button style={styles.fullWidth} variant={'danger'} onClick={() => deleteContactPerson(contactId)}>Delete</Button>
                    </Col>
                </Row>
            </div>
        });
    }


    /**
     * Deletes the contact person from the company
     * @param {number} contactId ID of the contact person
     */
    function deleteContactPerson(contactId) {
        const token = user ? user.token : '';
        axios.delete('company/sourcecontactperson', {
            params: {
                contactId: contactId
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 204) {
                populateSourceContactData();
                populateCompanySourceData();
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            console.log(err);
            setModal({
                show: true,
                title: 'Failed to delete contact person',
                body: <p>
                    The contact person for the source could not be deleted because of an error: {err?.response?.data?.message || err.message}
                </p>
            });
        });
    }

    return (
        <div>
            <AppModal {...modal} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>Source</th>
                        <th>Source Name</th>
                        <th>Source Type</th>
                        <th>Contact Person</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
            <Button variant={'success'} onClick={showAddCompanySourceModal}>Add Company Source</Button>

            <hr />
            <h4>Source contacts</h4>
            <SourceContacts contactPeople={contactPeople} deleteContactPerson={deleteContactPersonModal} editContactPerson={editContactPerson} />
        </div>
    )
}

CompanySources.propTypes = {
    /**
     * ID of the company
     */
    companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default CompanySources;
