import React, { useState, useEffect, useMemo } from 'react';
import { Table, InputGroup, Button, Form, Breadcrumb } from 'react-bootstrap';

import AppModal from '../Modal/Modal';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';


const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const styles = {
    alignCenter: {
        textAlign: 'center'
    }
}

const ImpactEditModal = ({ impactId, callbackFunction }) => {
    const [impact, setImpact] = useState('');
    const user = getUser();

    useEffect(() => {
        requestimpact();
    }, []);

    function requestimpact() {
        const token = user ? user.token : '';
        axios.get(`/notifications/impact/${impactId}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                const data = response.data;
                setImpact(data?.impact || '');
            }
        }).catch(err => {
            console.warn(err);
            setImpact('');
        })
    }

    function setImpactHandler(value) {
        if (value.length <= 50) {
            setImpact(value);
        }
    }

    return (
        <div>
            <p>
                The impact can be renamed
            </p>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Impact</InputGroup.Text>
                <Form.Control
                    placeholder={'Impact...'}
                    value={impact}
                    onChange={(e) => setImpactHandler(e.target.value)} />
            </InputGroup>

            <Button variant={'success'} onClick={() => callbackFunction({ impactId, impact })}>Update changes</Button>
        </div>
    )
}

const NotificationImpact = () => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [search, setSearch] = useState('');
    const [impacts, setImpacts] = useState([]);
    const tableRows = useMemo(() => createTableRows(impacts), [impacts])

    const user = getUser();

    useEffect(() => {
        populateImpactData();
    }, [search]);

    function populateImpactData() {
        const token = user ? user.token : '';
        axios.get('/notifications/impact', {
            params: {
                search: search
            },
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                setImpacts(response.data);
            }
        }).catch(err => {
            console.warn(err);
            setImpacts([]);
        })
    }

    function showEditModal(impactId) {
        setModal({
            show: true,
            title: 'Edit call to impact',
            body: <ImpactEditModal impactId={impactId} callbackFunction={updateImpactHandler} />
        });
    }

    function updateImpactHandler({ impactId, impact }) {
        const token = user ? user.token : '';
        const data = {
            id: impactId,
            impact: impact
        }
        axios.put('notifications/impact', data, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                setModal(INITIAL_MODAL_STATE);
                populateImpactData();
            }
        }).catch(err => {
            console.warn(err);
            setModal({
                show: true,
                title: 'Failed to update impact',
                body: <p>
                    The update of the impact failed because of an error: {err?.response?.data?.message || err.message}
                </p>
            });
        })
    }

    function setSearchHandler(value) {
        if (value.length <= 50) {
            setSearch(value);
        }
    }

    function createImpact() {
        const token = user ? user.token : '';
        const data = {
            impact: search
        }
        axios.post('/notifications/impact', data, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 201) {
                setSearch('');
            }
        }).catch(err => {
            console.warn(err);
            setModal({
                show: true,
                title: 'Failed to create impact',
                body: <p>
                    The impact could not be created because of an error: {err?.response?.data?.message || err.message}
                </p>
            })
        })
    }

    function createTableRows(data) {
        if (!data || data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={3}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx}>
                <td>{item.id}</td>
                <td>{item.impact}</td>
                <td>
                    <Button size={'sm'} variant={'primary'} onClick={() => showEditModal(item.id)}>Edit</Button>
                </td>
            </tr>
        })
    }

    return (
        <div>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <Breadcrumb>
                <Breadcrumb.Item href={'/metadata'}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={'/metadata/notificationimpace'} active={true}>Notification Impact</Breadcrumb.Item>
            </Breadcrumb>
            <h3>Impact</h3>
            <p>
                Tells the user how critical the alert is and how much it should be prioritised
            </p>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Searchtext</InputGroup.Text>
                <Form.Control
                    placeholder={'Name of the impact'}
                    value={search}
                    onChange={(e) => setSearchHandler(e.target.value)} />
                <Button variant={search.length === 0 ? 'secondary' : 'success'} onClick={createImpact}>New</Button>
            </InputGroup>
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Impact</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
        </div>
    )
}

export default NotificationImpact;
