import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';
import './CountrySearch.css';

/**
 * Creates the JSX.Elements for displaying the country search
 * @param {object} props Properties of the component
 * @returns JSX.Element for the country search
 */
export default function CountrySearch(props) {
    const [search, setSearch] = useState('');
    const [countries, setCountries] = useState([]);
    const tableRows = useMemo(() => createTableRows(countries), [countries]);

    /**
     * Function gets called every time the search parameter was changed by the user
     * in order to load the countries from the database
     */
    useEffect(() => {
        populateCountryData(search);
    }, [search]);

    /**
     * Fetches the data about the countries from the database
     * @param {string} searchText Searchtext the user has entered
     */
    function populateCountryData(searchText) {
        axios.get('options/country', {
            params: {
                search: searchText
            }
        }).then(response => {
            setCountries(response.data);
        }).catch(err => {
            console.log(err);
        });
    }

    /**
     * Maps the countries to table rows that will be shown in the table for the countries
     * @param {array} data Array that represents the countries
     * @returns JSX.Elements that represent the table rows
     */
    function createTableRows(data) {
        return data.map((elem, idx) => {
            return (
                <tr key={idx} className='CountryTableRow' onClick={() => props.countryCallback(elem.id)}>
                    <td>{elem.id}</td>
                    <td>{elem.countryName}</td>
                    <td>{elem.twoDigitCode}</td>
                </tr>
            );
        });
    }

    return (
        <div>
            <InputGroup className='mb-2'>
                <InputGroup.Text>Searchfield</InputGroup.Text>
                <Form.Control
                    placeholder='Searchtext'
                    aria-label='Searchtext'
                    aria-describedby='basic-addon2'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </InputGroup>
            <div className="CountryTable">
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Country Name</th>
                            <th>Two Digit Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}