import React, { useEffect, useReducer, useState } from 'react';
import { Alert, Button, Form, FloatingLabel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';

const INITIAL_STATE = {
    id: '',
    company: '',
    source: '',
    firstName: '',
    lastName: '',
    mail: '',
    phone: '',
    description: ''
}

const INITIAL_ALERT_STATE = {
    message: '',
    show: false,
    type: ''
}

const styles = {
    fullWidth: { width: '100%' }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'load':
            return {
                ...state,
                id: action.payload.id || '',
                company: action.payload.company,
                source: action.payload.source,
                firstName: action.payload.firstName || '',
                lastName: action.payload.lastName || '',
                mail: action.payload.mail || '',
                phone: action.payload.phone || '',
                description: action.payload.description || ''
            }
        case 'update':
            return {
                ...state,
                [action.key]: action.payload
            }
        case 'reset':
            return INITIAL_STATE;
        default:
            throw new Error(`Action type ${action.type} is no part of reducer in SourceContactModal.jsx`);
    }
}

const SourceContactModal = ({ contactId, callbackFunction }) => {
    const [alert, setAlert] = useState(INITIAL_ALERT_STATE);
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const user = getUser();

    useEffect(() => {
        populateContactPersonData();
    }, []);

    function populateContactPersonData() {
        const token = user ? user.token : '';
        axios.get(`company/sourcecontactperson/${contactId}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'load', payload: response.data });
            }
        }).catch(err => {
            console.log(err);
            dispatch({ type: 'reset' });
            setAlert({
                message: 'The data for the contact person could not be loaded. Please try again!',
                show: true,
                type: 'warning'
            });
        })
    }

    function updateSourceContactHandler() {
        const token = user ? user.token : '';
        const data = {
            id: contactId,
            company: state.company,
            source: state.source,
            firstName: state.firstName,
            lastName: state.lastName,
            mail: state.mail,
            phone: state.phone,
            description: state.description
        }
        axios.put('company/sourcecontactperson', data, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                callbackFunction();
            }
        }).catch(err => {
            console.log(err);
            setAlert({
                show: true,
                type: 'warning',
                message: `The information about the source could not be updated because of an error: ${err?.response?.data?.message || err.message}`
            })
        })
    }

    return (
        <div>
            <Alert variant={alert.type} show={alert.show}>
                {alert.message}
                <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={() => setAlert(INITIAL_ALERT_STATE)} variant={`outline-${alert.type}`}>
                        Close me
                    </Button>
                </div>
            </Alert>

            <p>
                The information about the contact person that is responsible
                for the company can be edited here. In case there are any further
                remarks or comments, just put it into the description.
            </p>

            <FloatingLabel className={'mb-2'} label={'First Name'}>
                <Form.Control value={state.firstName} onChange={(e) => dispatch({ type: 'update', key: 'firstName', payload: e.target.value })} placeholder={'First Name...'} />
            </FloatingLabel>
            <FloatingLabel className={'mb-2'} label={'Last Name'}>
                <Form.Control value={state.lastName} onChange={(e) => dispatch({ type: 'update', key: 'lastName', payload: e.target.value })} placeholder={'Last Name'} />
            </FloatingLabel>
            <FloatingLabel className={'mb-2'} label={'E-Mail'}>
                <Form.Control value={state.mail} onChange={(e) => dispatch({ type: 'update', key: 'mail', payload: e.target.value })} placeholder={'name@example.com'} />
            </FloatingLabel>
            <FloatingLabel className={'mb-2'} label={'Phone'}>
                <Form.Control value={state.phone} onChange={(e) => dispatch({ type: 'update', key: 'phone', payload: e.target.value })} placeholder={'+41 79 123 4567'} />
            </FloatingLabel>
            <FloatingLabel className={'mb-2'} label={'Description'}>
                <Form.Control as={'textarea'} style={{ height: '10rem' }} value={state.description} onChange={(e) => dispatch({ type: 'update', key: 'description', payload: e.target.value })} placeholder={'Description and remarks'} />
            </FloatingLabel>

            <Button style={styles.fullWidth} onClick={updateSourceContactHandler} variant={'success'}>Save</Button>
        </div>
    )
}

SourceContactModal.propTypes = {
    contactId: PropTypes.number.isRequired
}

export default SourceContactModal;
