import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import axios from 'utils/axiosInstance';
import { CompositionInputFields, CompositionWriters, Sources, Splits } from 'components/CompositionDetails';
import WorkTracks from 'components/CompositionDetails/WorkTracks';
import WorkIdentifiers from 'components/CompositionDetails/WorkIdentifiers';

const CompositionDetail = () => {
  const [composition, setComposition] = useState(undefined);
  const { companyId, compositionId } = useParams();

  useEffect(() => {
    getCompositionInformation();
  }, [compositionId]);

  function getCompositionInformation() {
    if (compositionId === undefined) {
      return;
    }

    axios.get('catalogue/publishing', {
      params: {
        work: compositionId
      }
    }).then(response => {
      if (response.status === 200) {
        setComposition(response.data);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="/customers">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/customers/catalogue/publishing">Publishing</Breadcrumb.Item>
        <Breadcrumb.Item href={`/customers/catalogue/publishing/company-${companyId}`}>Catalogue</Breadcrumb.Item>
        <Breadcrumb.Item href={`/customers/catalogue/publishing/company-${companyId}/composition-${compositionId}`} active={true}>Details</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Publishing Details:{composition && ` ${composition.title}`}</h2>
      <CompositionInputFields compositionId={compositionId} />
      <CompositionWriters compositionId={compositionId} />
      <Sources compositionId={compositionId} />
      <WorkTracks workId={compositionId} />
      <WorkIdentifiers workId={compositionId} />
      <Splits />
    </div>
  )
}

export default CompositionDetail;
