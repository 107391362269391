import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { FloatingLabel, Form, Table } from 'react-bootstrap';
import Paging from 'components/Paging/Paging';
import PropTypes from 'prop-types';
import axios from 'utils/axiosInstance';

const INITIAL_STATE = {
  trialUsers: [],
  startIndex: 0,
  pageNumber: 0,
  pageSize: 10,
  totalRecords: 0
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      if (action.payload.items) {
        return {
          ...state,
          trialUsers: action.payload.items,
          pageNumber: action.payload.pageNumber,
          totalRecords: action.payload.totalCount,
          startIndex: action.payload.recordNumber
        };
      }
      return state;
    case 'paging':
      const maxPageNumber = Math.ceil(state.totalRecords / state.pageSize);
      const pageNumber = Math.min(Math.max(action.payload, 1), maxPageNumber);
      return {
        ...state,
        pageNumber: pageNumber,
        startIndex: (pageNumber - 1) * state.pageSize
      }
    case 'reset':
      return INITIAL_STATE;
    default:
      throw new Error(`Action type ${action.type} is no part of reducer in TrialUserTable.jsx`);
  }
}

const TrialUserTable = ({ callbackFunction }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [search, setSearch] = useState('');
  const trialUserRows = useMemo(() => createTableRows(state.trialUsers), [state.trialUsers]);

  useEffect(() => {
    axios.get('user/trial/search', {
      params: {
        startIndex: state.startIndex,
        pageSize: state.pageSize,
        search: search
      }
    }).then(response => {
      if (response.status === 200) {
        dispatch({ type: 'set', payload: response.data });
      }
    }).catch(err => {
      console.log(err);
      dispatch({ type: 'reset' })
    });
  }, [search, state.pageSize, state.pageNumber]);

  function createTableRows(data) {
    if (data.length === 0) {
      return <tr className='text-center'>
        <td colSpan={7}>No data available</td>
      </tr>
    }

    return data.map((item, idx) => {
      const trialStart = new Date(item.trialStart);
      const trialEnd = new Date(item.trialEnd);
      return (
        <tr key={idx} style={{ 'cursor': 'pointer' }} onClick={() => callbackFunction(item.id)}>
          <td>{item.id}</td>
          <td>{item.name || 'N/A'}</td>
          <td>{item.email || 'N/A'}</td>
          <td>{item.version || 'N/A'}</td>
          <td>{item.accountManager || 'N/A'}</td>
          <td>{`${trialStart.toLocaleDateString()} ${trialStart.toLocaleTimeString()}`}</td>
          <td>{`${trialEnd.toLocaleDateString()} ${trialEnd.toLocaleTimeString()}`}</td>
        </tr>
      )
    });
  }

  return (
    <div className={'mb-4'}>
      <FloatingLabel label={'🔍 Searchtext'} className={'mb-2'}>
        <Form.Control
          autoFocus={true}
          placeholder={'Searchtext'}
          value={search}
          onChange={(e) => setSearch(e.target.value)} />
      </FloatingLabel>
      <div className={'w-100'}>
        <Table bordered striped hover responsive={true}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>E-Mail</th>
              <th>Version</th>
              <th>Account Manager</th>
              <th>Trial Start</th>
              <th>Trial End</th>
            </tr>
          </thead>
          <tbody>
            {trialUserRows}
          </tbody>
        </Table>
      </div>
      <Paging currentPage={state.pageNumber} totalCount={state.totalRecords} pageSize={state.pageSize} callbackFunction={(i) => dispatch({ type: 'paging', payload: i })} />
    </div>
  )
}

TrialUserTable.propTypes = {
  /**
   * Function that get's called whenever the user clicks on a row.
   * This function is expected to take an ID as input.
   */
  callbackFunction: PropTypes.func.isRequired
}

export default TrialUserTable;