import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Form, Table, FloatingLabel } from 'react-bootstrap';
import Paging from 'components/Paging/Paging';
import AppModal from 'components/Modal/Modal';
import axios from 'utils/axiosInstance';

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const INITIAL_STATE = {
    compositions: [],
    startIndex: 0,
    totalCount: 0,
    pageNumber: 0,
    pageSize: 15
}

const styles = {
    paddingTop1: { paddingTop: '1rem' },
    pointer: { cursor: 'pointer' },
    center: { textAlign: 'center' },
    small: { width: '100px' },
    flex: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    marginRight: { marginRight: '1rem' },
    card: {
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '12px',
        boxShadow: '5px 10px 18px rgba(0, 0, 0, 0.1)'
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'load':
            return {
                ...state,
                compositions: action.payload.items,
                pageNumber: action.payload.pageNumber,
                startIndex: action.payload.recordNumber,
                totalCount: action.payload.totalCount
            }
        case 'reset':
            return INITIAL_STATE;
        case 'paging':
            const maxPageNumber = Math.ceil(state.totalCount / state.pageSize);
            const pageNumber = Math.min(Math.max(action.payload, 1), maxPageNumber);
            return {
                ...state,
                pageNumber: pageNumber,
                startIndex: (pageNumber - 1) * state.pageSize
            }
        default:
            new Error(`Actiont type ${action.type} is no part of reducer in Publishings.jsx`);
    }
}

const Publishings = () => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const tableRows = useMemo(() => mapTableRows(state.compositions), [state.compositions]);
    const [search, setSearch] = useState('');
    const [company, setCompany] = useState(undefined);
    const { companyId } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        populatePublishingsData();
    }, [companyId, search, state.startIndex, state.pageSize, state.pageNumber, state.totalCount]);

    useEffect(() => {
        if (companyId) {
            getCompanyDetails();
        }
    }, [companyId])

    function getCompanyDetails() {
        axios.get(`company`, {
            params: { companyId: companyId }
        }).then(response => {
            setCompany(response.data);
        }).catch(err => {
            console.log(err);
        });
    }

    function populatePublishingsData() {
        axios.get('catalogue/publishing/search', {
            params: {
                company: companyId,
                startIndex: state.startIndex,
                pageSize: state.pageSize,
                search: search
            }
        }).then(response => {
            dispatch({ type: 'load', payload: response.data });
        }).catch(err => {
            console.log(err);
            dispatch({ type: 'reset' });
        });
    }

    function compositionClickedHandler(id) {
        navigate(`composition-${id}`);
    }

    function mapTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.center}>
                <td colSpan={3}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx} onClick={() => compositionClickedHandler(item.id)} style={styles.pointer}>
                <td>{item.id}</td>
                <td>{item.title}</td>
                <td>{new Date(item.releaseDate).toLocaleDateString()}</td>
            </tr>
        })
    }

    return (
        <div>
            <AppModal size='xl' show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <Breadcrumb>
                <Breadcrumb.Item href="/customers/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href={`/customers/catalogue/publishing`}>Publishing</Breadcrumb.Item>
                <Breadcrumb.Item href={`/customers/catalogue/publishing/company-${companyId}`} active={true}>Catalogue</Breadcrumb.Item>
            </Breadcrumb>
            <h1>Publishing Catalogue{company && ` for ${company.name}`}</h1>

            <FloatingLabel className={'mb-2'} label={'🔍 Searchtext'}>
                <Form.Control
                    placeholder={'Searchtext for compositions...'}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} />
            </FloatingLabel>
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>First Release</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>

            <Paging currentPage={state.pageNumber} totalCount={state.totalCount} pageSize={state.pageSize} callbackFunction={(i) => dispatch({ type: 'paging', payload: i })} />
        </div>
    )
}

export default Publishings;
