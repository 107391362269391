import React, { useState, useEffect, useMemo } from 'react';
import { FloatingLabel, Form, Table } from 'react-bootstrap';
import axios from '../../utils/axiosInstance';
import './CompanyTypeSearch.css';

export default function CompanyTypeSearch(props) {
  const [search, setSearch] = useState('');
  const [companyTypes, setCompanyTypes] = useState([]);
  const tableRows = useMemo(() => createTableRows(companyTypes), [companyTypes]);

  useEffect(() => {
    populateCompanyTypesData(search);
  }, [search]);

  function populateCompanyTypesData(searchText) {
    axios.get('options/companytypes', {
      params: {
        search: searchText
      }
    }).then(response => {
      setCompanyTypes(response.data);
    }).catch(err => {
      console.log(err);
      setCompanyTypes([]);
    });
  }

  function createTableRows(data) {
    if (data.length === 0) {
      return <tr className='CompanyTypeTableCenter'>
        <td colSpan={2}>No data available</td>
      </tr>
    }

    return data.map((elem, idx) => {
      return (
        <tr key={idx} className={'CompanyTypeTableRow'} onClick={() => props.callback(elem.id)}>
          <td>{elem.id}</td>
          <td>{elem.type}</td>
        </tr>
      )
    })
  }

  return (
    <div>
      <FloatingLabel className={'mb-2'} label={"🔍Searchtext"}>
        <Form.Control
          placeholder={'Searchtext'}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </FloatingLabel>
      <div className={'CompanyTypeTable'}>
        <Table striped hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </Table>
      </div>
    </div>
  )
}