import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TrackDetails from 'components/TrackDetails/TrackDetails';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Isrc from "components/Isrc/Isrc";
import AssetIds from "components/AssetIds/AssetIds";
import TrackSplits from "components/TrackSplits/TrackSplits";
import axios from 'utils/axiosInstance';

const styles = {
    paddingTop: { paddingTop: '1rem' }
}

export default function CatalogueTrackDetails() {
    const { companyId, trackId } = useParams();
    const [track, setTrack] = useState(undefined);

    useEffect(() => {
        getTrackDetails();
    }, []);

    function getTrackDetails() {
        axios.get('catalogue/recording/detail', {
            params: {
                track: trackId
            }
        }).then((res) => {
            setTrack(res.data);
        }).catch((err) => {
            console.log(err)
        });
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item href="/customers">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/customers/catalogue/recording">Recording</Breadcrumb.Item>
                <Breadcrumb.Item href={`/customers/catalogue/recording/company-${companyId}`}>Catalogue</Breadcrumb.Item>
                <Breadcrumb.Item href={`/customers/catalogue/recording/company-${companyId}/track-${trackId}`} active={true}>Details</Breadcrumb.Item>
            </Breadcrumb>
            <h1>Track details - {track ? track.title : trackId}</h1>

            <TrackDetails style={styles.paddingTop} />

            <h2>Manage Splits of the track</h2>
            <TrackSplits />

            <h2>Manage ISRCs of the track</h2>
            <Isrc />

            {/* Display AssetIds */}
            {/* <br />
            <h2>Manage Asset IDs of the track</h2>
            <AssetIds /> */}
        </div>
    )
}