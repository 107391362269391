import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { Table, Button, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AppModal from '../Modal/Modal';
import AddSpotifyLink from './AddSpotifyLink';
import axios from '../../utils/axiosInstance';
import './SpotifyLink.css';


const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const INITIAL_STATE = {
  uId: '',
  userId: '',
  spotifyLinks: [],
  canBeUpdated: false
}

/**
 * Modifies the state of the component
 * @param {Object} state The current state of the component
 * @param {Object} action The action that describes how the state should be modified
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'load':
      return {
        ...state,
        uId: action.payload.uId,
        userId: action.payload.userId,
        spotifyLinks: action.payload.spotifyLinks,
        canBeUpdated: true
      }
    case 'fetchFailed':
      return { ...state, canBeUpdated: false }
    case 'delete':
      let links = [...state.spotifyLinks];
      links.splice(action.payload.idx, 1);
      return { ...state, spotifyLinks: links }
    case 'add':
      return { ...state, spotifyLinks: [...state.spotifyLinks, action.payload] }
    default:
      throw new Error(`Action type ${action.type} is no part of reducer in FirestoreSpotifyLinks.jsx`);
  }
}

/**
 * Creates the JSX.Elements for displaying and editing the spotify links on firebase
 * @param {Object} props
 */
export default function FirestoreSpotifyLinks({ userId }) {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const tableRows = useMemo(() => mapTableRows(state.spotifyLinks), [state.spotifyLinks]);

  /**
   * Function gets called every time the companyID changes.
   * It initiates the component by loading the spotify links
   */
  useEffect(() => {
    populateSpotifyLinksData(userId);
  }, [userId]);

  /**
   * Fetches the spotify links from the backend.
   * @param {string} user
   */
  function populateSpotifyLinksData(user) {
    axios.get('/user/firestorespotifylinks', {
      params: { userId: user },
    }).then(response => {
      dispatch({ type: 'load', payload: response.data });
    }).catch(err => {
      dispatch({ type: 'fetchFailed' });
    });
  }

  /**
   * Maps the data coming from the backend to the table rows for
   * the table.
   * @param {Array<string>} data
   * returns JSX.Elements representing the table rows
   */
  function mapTableRows(data) {
    if (data.length === 0) {
      return <tr className={'text-center'}><td colSpan={3}>No data available</td></tr>
    }

    return data.map((item, idx) => {
      return <tr key={idx}>
        <td>{idx + 1}</td>
        <td>{item}</td>
        <td className={'text-center'}><Button variant='outline-danger' size='sm' onClick={() => dispatch({ type: 'delete', payload: { idx: idx } })}>Delete</Button></td>
      </tr>
    })
  }

  /**
   * Adds the spotify link to the state and closes the modal
   * @param {string} link
   */
  function addSpotifyLink(link) {
    dispatch({ type: 'add', payload: link });
    setModal(INITIAL_MODAL_STATE);
  }

  /**
   * Opens the modal for entering a spotify link
   */
  function showInputModal() {
    setModal({
      show: true,
      title: 'Add Spotify Link',
      body: <AddSpotifyLink callbackFunction={addSpotifyLink} />
    });
  }

  /**
   * Updates the spotify links in firebase and the backend.
   */
  function saveSpotifyLinks() {
    const data = {
      uId: state.uId,
      userId: state.userId,
      spotifyLinks: [...state.spotifyLinks]
    }

    axios.post('/user/firestorespotifylinks', data).then(response => {
      setModal({
        show: true,
        title: 'Update successfull',
        body: <p>The spotify links in firebase were updated successfully!</p>
      });
    }).catch(err => {
      setModal({
        show: true,
        title: 'Update failed',
        body: <p>The update of the Spotify links failed</p>
      });
    });
  }

  return (
    <div className={'mb-4'}>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />

      {(state.userId === '' || state.uId === '') &&
        <Alert variant='warning' title='No information'>No connection between the HELGA user and the account on firestore</Alert>}

      <Table striped hover bordered responsive>
        <thead>
          <tr>
            <th>&#8470;</th>
            <th>Spotify Link</th>
            <th className={'text-center'}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </Table>
      <div className='SpotifyLinkRow'>
        <div className='SpoitfyLinkCol50'>
          <Button
            variant='primary'
            onClick={() => showInputModal()}
            disabled={state.uId === '' || state.userId === ''}>Add spotify Link</Button>
        </div>
        <div className='SpoitfyLinkCol50'>
          <Button
            variant='success'
            onClick={() => saveSpotifyLinks()}
            disabled={state.uId === '' || state.userId === ''}>Save changes</Button>
        </div>
      </div>
    </div>
  )
}

FirestoreSpotifyLinks.propTypes = {
  /**
   * Specifies for which user the spotify links should be loaded from firestore.
   * */
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}
