import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../utils/requireAuth';
import './NavMenu.css';

export function NavMenu() {
  let navigate = useNavigate();
  const user = getUser();

  /**
   * Handles the sign out of the user
   * @param {object} e Click event of sign out Navlink
   */
  function signOutHandler(e) {
    e.preventDefault();

    localStorage.clear();
    navigate('/signin');
  }

  return (
    <Navbar collapseOnSelect expand='lg' bg='dark' variant='dark' className={'nav-container'}>
      <Navbar.Brand href='/'>HELGA.admin</Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav className='me-auto'>
          <Nav.Link href='/'>Home</Nav.Link>
          <Nav.Link href='/customers'>Customers</Nav.Link>
          <Nav.Link href='/metadata'>Metadata</Nav.Link>
          <Nav.Link href='/administration'>Administration</Nav.Link>
          <Nav.Link href='/settings' disabled>Settings</Nav.Link>
        </Nav>
        {
          user ?
            <Nav>
              <Navbar.Text href='#'>Logged in as {user.userName}</Navbar.Text>
              <Nav.Link href='/signout' onClick={signOutHandler}>Sign out</Nav.Link>
            </Nav>
            :
            <Nav>
              <Nav.Link href='/signin'>Sign in</Nav.Link>
            </Nav>
        }
      </Navbar.Collapse>
    </Navbar>
  )
}
