import React, { useState, useMemo, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import AppModal from 'components/Modal/Modal';
import { Button, FloatingLabel, Form, Table, Breadcrumb, Row, Col } from 'react-bootstrap';
import Paging from 'components/Paging/Paging';
import axios from 'utils/axiosInstance';

const INITIAL_MODAL_STATE = {
    show: false,
    body: null,
    title: ''
}

const INITIAL_STATE = {
    sources: [],
    startIndex: 0,
    pageNumber: 0,
    pageSize: 10,
    totalRecords: 0
}

const styles = {
    alignCenter: {
        textAlign: 'center'
    },
    card: {
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '12px'
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case "SET":
            if (action.payload.items) {
                return {
                    ...state,
                    sources: action.payload.items,
                    pageNumber: action.payload.pageNumber,
                    totalRecords: action.payload.totalCount,
                    startIndex: action.payload.recordNumber
                };
            }
        case 'paging':
            const maxPageNumber = Math.ceil(state.totalRecords / state.pageSize);
            const pageNumber = Math.min(Math.max(action.payload, 1), maxPageNumber);
            return {
                ...state,
                pageNumber: pageNumber,
                startIndex: (pageNumber - 1) * state.pageSize
            };
        case 'reset':
            return INITIAL_STATE;
    }
}

const Home = () => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [search, setSearch] = useState('');
    const tableRows = useMemo(() => createTableRows(state.sources), [state.sources]);
    let navigate = useNavigate();

    /**
     * Function gets executed whenever the user searches for a source or changes the pagination
     */
    useEffect(() => {
        populateSourceData();
    }, [search, state.startIndex, state.pageSize]);

    /**
     * Redirects the user to the page where the details for the source can be displayed.
     * @param {number} sourceId ID of the source
     */
    function sourceClickedHandler(sourceId) {
        navigate(`${sourceId}`);
    }

    function createNewSourceHandler() {
        if (search === '' || search === null) {
            return;
        }

        const data = {
            name: search
        };
        axios.post('/source', data).then(response => {
            if (response.status === 201) {
                const source = response.data;
                sourceClickedHandler(source.id);
            }
        }).catch(err => {
            setModal({
                title: 'Failed to create source',
                body: (
                    <p>
                        The creation of the source failed because of an error: {err.message}
                    </p>
                )
            })
        })
    }

    /**
     * Creates the rows for the table
     * @param {Array} data Array of sources
     * @returns JSX.Element[]
     */
    function createTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.alignCenter}><td colSpan={3}>No data available</td></tr>
        }

        return data.map((item, idx) => {
            return (
                <tr key={idx} onClick={() => sourceClickedHandler(item.id)}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.sourceType || 'N/A'}</td>
                </tr>
            )
        })
    }

    /**
     * Requests the sources from the database.
     */
    function populateSourceData() {
        axios.get('/source/search', {
            params: {
                startIndex: state.startIndex,
                pageSize: state.pageSize,
                search: search
            }
        }).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'SET', payload: response.data });
            }
        }).catch(err => {
            console.log(err);
            dispatch({ type: 'reset' });
        });
    }

    return (
        <div>
            <AppModal title={modal.title} body={modal.body} show={modal.show} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <Breadcrumb>
                <Breadcrumb.Item href={'/metadata'}>Home</Breadcrumb.Item>
                <Breadcrumb.Item active={true} href={'/metadata/source'}>Sources</Breadcrumb.Item>
            </Breadcrumb>
            <h1>Sources</h1>
            <div style={styles.card}>
                <Row>
                    <Col>
                        <FloatingLabel className='mb-2' label={'🔍 Searchtext'}>
                            <Form.Control
                                placeholder='Searchtext'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)} />
                        </FloatingLabel>
                    </Col>
                    <Col xs={2}>
                        <div className="h-100 pb-2">
                            <Button className='h-100 w-100' variant={search ? 'success' : 'secondary'} onClick={createNewSourceHandler}>New</Button>
                        </div>
                    </Col>
                </Row>
                <div className="companyTable">
                    <Table bordered striped hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRows}
                        </tbody>
                    </Table>
                </div>
                <Paging currentPage={state.pageNumber} totalCount={state.totalRecords} pageSize={state.pageSize} callbackFunction={(i) => dispatch({ type: 'paging', payload: i })} />
            </div>
        </div>
    )

}

export default Home;
