import React, { useState, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FloatingLabel, Form, Button } from 'react-bootstrap';
import axios from 'utils/axiosInstance';
import AppModal from 'components/Modal/Modal';

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const INITIAL_STATE = {
  company: {
    name: '',
    artistName: '',
    spotifyLink: '',
    companyType: 0,
  },
  companyTypes: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'edit':
      return {
        ...state,
        company: {
          ...state.company,
          [action.key]: action.payload
        }
      }
    case 'loadCompanyTypes':
      return {
        ...state,
        companyTypes: action.payload
      }
    default:
      throw new Error(`Action type ${action.type} is no part of reducer in CompanyNew.jsx`);
  }
}

const CompanyNew = () => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  let navigate = useNavigate();

  useEffect(() => {
    axios.get('options/companytypes').then(response => {
      dispatch({ type: 'loadCompanyTypes', payload: response.data });
    }).catch(err => { console.log(err) });
  }, []);

  function saveNewCompanyHandler() {
    if (!state.company.name || state.company.name == '') {
      setModal({
        show: true,
        title: 'Incomplete company information',
        body: <p>
          No name of the company was given. In case on an artist,
          this corresponds to the normal name
        </p>
      });
      return;
    }

    const companyType = Number(state.company.companyType);
    if (Number.isNaN(companyType) || companyType === 0) {
      setModal({
        show: true,
        title: 'Incomplete company information',
        body: <p>No company type was selected.</p>
      })
      return;
    }

    const data = {
      name: state.company.name,
      artistName: state.company.artistName || null,
      spotifyLink: state.company.spotifyLink || null,
      companyType: companyType
    }

    axios.post('company/', data).then(response => {
      if (response.status === 201) {
        navigate(`/customers/company/${response.data.id}`);
        setModal(INITIAL_MODAL_STATE);
      }
    }).catch(err => {
      setModal({
        show: true,
        title: 'Failed to create the company',
        body: <p>
          The company could not be created. Please ensure that your information
          were correct.
        </p>
      });
    })
  }

  return (
    <div>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
      <h1>New company</h1>
      <p>
        Please enter the following information in order to create
        a new company in the database.
      </p>

      <FloatingLabel className='mb-2' label={'Name of the Artist/other'}>
        <Form.Control
          placeholder='Name'
          value={state.company.name}
          onChange={(e) => dispatch({ type: 'edit', key: 'name', payload: e.target.value })} />
      </FloatingLabel>
      <FloatingLabel className='mb-2' label={'Artist Name'}>
        <Form.Control
          placeholder='Artist Name'
          value={state.company.artistName}
          onChange={(e) => dispatch({ type: 'edit', key: 'artistName', payload: e.target.value })} />
      </FloatingLabel>
      <FloatingLabel className='mb-2' label={'Spotify Link'}>
        <Form.Control
          placeholder='Spotify Link'
          value={state.company.spotifyLink}
          onChange={(e) => dispatch({ type: 'edit', key: 'spotifyLink', payload: e.target.value })} />
      </FloatingLabel>
      <FloatingLabel className='mb-2' label={'Company Type'}>
        <Form.Select value={state.company.companyType} onChange={(e) => dispatch({ type: 'edit', key: 'companyType', payload: e.target.value })} placeholder={'Select a company type'}>
          <option value={0}>Select a company type</option>
          {state.companyTypes && state.companyTypes.map((item, idx) => {
            return <option value={item.id} key={idx}>{item.type}</option>
          })}
        </Form.Select>
      </FloatingLabel>

      <Button className={'w-100'} onClick={saveNewCompanyHandler}>Save Company</Button>
    </div>
  )
}

CompanyNew.propTypes = {

}

export default CompanyNew;
