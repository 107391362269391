import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Button } from 'react-bootstrap';
import AppModal from 'components/Modal/Modal';
import AppUserTable from 'components/AppUserTable/AppUserTable';
import UserTable from 'components/UserTable/UserTable';
import axios from 'utils/axiosInstance';
import CreateUser from 'components/CreateUser/CreateUser';
import TrialUserTable from '../../../components/TrialUserTable/TrialUserTable';

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const UserHome = () => {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  let navigate = useNavigate();

  const openUser = (userId) => {
    navigate(`${userId}`);
  }

  const userClicked = (userId) => {
    setModal({
      show: true,
      title: 'Create new User',
      body: (
        <div>
          <p>
            Do you want to create a new user in the HELGA database for
            this account?
          </p>
          <Button variant={'success'} onClick={() => createUserHandler(userId)}>Create User</Button>
        </div>
      )
    });
  }

  function createUserHandler(userId) {
    axios.post('user/fromfirestore', {
      firebaseUid: userId
    }).then(response => {
      const data = response.data;
      navigate(`${data.id}`);
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'User creation failed',
        body: <p>The user was not able to be created.</p>
      });
    });
  }

  function showCreateUserModal() {
    setModal({
      show: true,
      title: 'Create User',
      body: <CreateUser callbackFunction={createNewUserHandler} />
    });
  }

  function createNewUserHandler(user) {
    axios.post('user', user).then(response => {
      if (response.status === 201) {
        if (response.data?.id !== undefined && response.data.id !== null) {
          navigate(`${response.data.id}`);
        }
      }
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Failed to create user',
        body: <p>
          The user could not be created. Please make sure that the
          given information about the user are correct and that the
          email of the user are not available yet
        </p>
      })
    })
  }

  return (
    <div>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
      <Breadcrumb>
        <Breadcrumb.Item href={'/customers'}>Home</Breadcrumb.Item>
        <Breadcrumb.Item href={'/customers/users'} active={true}>Users</Breadcrumb.Item>
      </Breadcrumb>

      <h1>User Home</h1>
      <p>
        In user home you have the possibility to search for existing users
        and get all information about them by clicking on the respective row
        in the table or to create a new user based on an existing account from
        the app or by just creating an empty user.
      </p>

      <UserTable callbackFunction={openUser} />

      <h2>Trial Users</h2>
      <p>
        This is the list of users that is currently in trial
      </p>
      <TrialUserTable callbackFunction={openUser} />

      <h2>New User</h2>
      <p>
        A new user can be created using the already available information from
        firestore. By clicking on a row in the table, the new user will be created.
      </p>
      <AppUserTable
        link='user/notassigned'
        callbackFunction={userClicked} />
      <h4>Create without firebase</h4>
      <p>
        You can also create a new user without having the information from
        firebase first. Please keep in mind that a user itself is not enough
        to login into HELGA.app
      </p>
      <Button variant={'success'} onClick={showCreateUserModal}>Create User</Button>
    </div>
  )
}

export default UserHome;
