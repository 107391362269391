/**
 * Track catalogue home
 * In here, the company can be searched and will be selected in order
 * to then request the track catalogue.
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import CompanyTable from "components/CompanyTable/CompanyTable";

/**
 * Displays the company table to search for a company for which the track
 * catalogue should be displayed.
 * @returns JSX.Elements for displaying the page for selecting a company
 * in the track catalogue
 */
export default function CatalogueHome() {
    let navigate = useNavigate();

    /**
     * Navigates the user to the track catalogue of a specific company.
     * @param {number} companyId Id of the company
     */
    function companyClicked(companyId) {
        navigate(`company-${companyId}`);
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item href="/customers">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/customers/catalogue/recording" active={true}>Recording</Breadcrumb.Item>
            </Breadcrumb>
            <h1>Tracks Home</h1>
            <p>
                In tracks home you have the possibility to search for
                existing companies and get all tracks of this company
                by clicking on the respective company
            </p>
            <CompanyTable link="company/search" callbackFunction={companyClicked} />
        </div>
    )
}