import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, InputGroup, Form, Button, DropdownButton, Dropdown, Breadcrumb, BreadcrumbItem } from 'react-bootstrap';

import AppModal from '../../../components/Modal/Modal';
import Paging from '../../../components/Paging/Paging';
import { getUser } from '../../../utils/requireAuth';
import axios from '../../../utils/axiosInstance';
import CompanyTable from '../../../components/CompanyTable/CompanyTable';



const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const INITIAL_STATE = {
    notifications: [],
    startIndex: 0,
    pageNumber: 0,
    pageSize: 10,
    totalRecords: 0
}

const styles = {
    alignCenter: {
        textAlign: 'center'
    },
    card: {
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        padding: '12px',
        marginBottom: '1rem'
    },
    inputText: {
        width: '20%',
        minWidth: '100px',
    },
    pointer: {
        cursor: 'pointer'
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET':
            if (action.payload.items) {
                return {
                    ...state,
                    notifications: action.payload.items,
                    pageNumber: action.payload.pageNumber,
                    totalRecords: action.payload.totalCount,
                    startIndex: action.payload.recordNumber
                };
            }
            return INITIAL_STATE;
        case 'paging':
            const maxPageNumber = Math.ceil(state.totalRecords / state.pageSize);
            const pageNumber = Math.min(Math.max(action.payload, 1), maxPageNumber);
            return {
                ...state,
                pageNumber: pageNumber,
                startIndex: (pageNumber - 1) * state.pageSize
            };
        case 'reset':
            return INITIAL_STATE;
        default:
            throw new Error(`Action type ${action.type} is no part of reducer in NotificationsHome.reducer`);
    }
}

const NotificationsHome = () => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [search, setSearch] = useState('');
    const [company, setCompany] = useState(undefined);
    const [solved, setSolved] = useState(undefined);
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const tableRows = useMemo(() => createTableRows(state.notifications), [state.notifications]);
    const user = getUser();
    let navigate = useNavigate();

    useEffect(() => {
        populateNotificationsData();
    }, [search, company, solved, state.pageSize, state.startIndex]);

    function populateNotificationsData() {
        const token = user ? user.token : '';
        axios.get('/notifications/search', {
            params: {
                startIndex: state.startIndex,
                pageSize: state.pageSize,
                search: search,
                companyId: company,
                solved: solved
            },
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'SET', payload: response.data });
            }
        }).catch(err => {
            console.log(err);
            dispatch({ type: 'reset' });
        })
    }

    function createTableRows(data) {
        if (!data || data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={6}>No data available</td>
            </tr>
        }

        return data.map((item, index) => {
            const date = new Date(item.updatedAt).toLocaleString()
            return <tr key={index} style={styles.pointer} onClick={() => navigate(`${item.notificationId}`)}>
                <td>{item.notificationId}</td>
                <td>{item.companyName}</td>
                <td>{item.subject}</td>
                <td>{item.message}</td>
                <td>{item.solved ? 'Yes' : 'No'}</td>
                <td>{date}</td>
            </tr>
        });
    }

    function showSelectCompanyModal() {
        setModal({
            show: true,
            title: 'Select Company',
            body: <div>
                <CompanyTable callbackFunction={selectCompanyHandler} link='/company/search' />
                <Button variant={'secondary'} onClick={resetCompanyHandler}>Reset Company</Button>
            </div>
        })
    }

    function resetCompanyHandler() {
        setCompany(undefined);
        setModal(INITIAL_MODAL_STATE)
    }

    function selectCompanyHandler(companyId) {
        setCompany(companyId);
        setModal(INITIAL_MODAL_STATE);
    }

    return (
        <div>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />

            <Breadcrumb>
                <BreadcrumbItem href={'/customers'}>Home</BreadcrumbItem>
                <BreadcrumbItem href={'/customers/notifications'} active={true}>Notifications</BreadcrumbItem>
            </Breadcrumb>
            <h1>Notifications</h1>

            <div style={styles.card}>
                <InputGroup className={'mb-2'}>
                    <InputGroup.Text style={styles.inputText}>Searchtext</InputGroup.Text>
                    <Form.Control
                        placeholder={'Searchtext...'}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)} />
                </InputGroup>

                <InputGroup className={'mb-2'}>
                    <InputGroup.Text style={styles.inputText}>Company</InputGroup.Text>
                    <Form.Control
                        placeholder={'Select Company...'}
                        value={company === undefined ? 'No company selected' : company}
                        readOnly={true} />
                    <Button variant={'outline-secondary'} onClick={() => showSelectCompanyModal()}>Search</Button>
                </InputGroup>

                <InputGroup className={'mb-2'}>
                    <InputGroup.Text style={styles.inputText}>Solved</InputGroup.Text>
                    <Form.Control
                        placeholder={'Solved flag...'}
                        value={solved === 1 ? 'Yes' : solved === 0 ? 'No' : 'Show all'}
                        readOnly={true} />
                    <DropdownButton variant={'outline-secondary'} title={'Select'}>
                        <Dropdown.Item onClick={() => setSolved(1)}>True</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSolved(0)}>False</Dropdown.Item>
                        <Dropdown.Item onClick={() => setSolved(undefined)}>Show all</Dropdown.Item>
                    </DropdownButton>
                </InputGroup>

                <Table bordered striped hover responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Company</th>
                            <th>Subject</th>
                            <th>Message</th>
                            <th>Solved</th>
                            <th>Updated at</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>
                <Paging currentPage={state.pageNumber} totalCount={state.totalRecords} pageSize={state.pageSize} callbackFunction={(i) => dispatch({ type: 'paging', payload: i })} />
            </div>
        </div>
    )
}

export default NotificationsHome;
