import React, { useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  InputGroup,
  Form,
  Button,
  Breadcrumb
} from 'react-bootstrap';
import axios from 'utils/axiosInstance';
import CompanyTable from 'components/CompanyTable/CompanyTable';
import AppModal from 'components/Modal/Modal';
import WorkRoleSearch from 'components/WorkRoleSearch/WorkRoleSearch';

const styles = {
  center: { textAlign: 'center' },
  fullWidth: { width: '100%' },
  pointer: {
    cursor: 'pointer'
  },
  inputGroupText: {
    width: "15%",
    minWidth: "150px"
  },
}

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const INITIAL_STATE = {
  newSplit: {
    synchronization: '',
    performance: '',
    mechanical: '',
    company: {
      id: '',
      name: '',
      artistName: ''
    },
    role: '',
    roleName: '',
    roleCode: ''
  },
  roles: new Map()
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'loadRoles':
      return { ...state, roles: action.payload }
    case 'setRight':
      return {
        ...state,
        newSplit: { ...state.newSplit, [action.key]: action.payload }
      }
    case 'setCompany':
      return {
        ...state,
        newSplit: {
          ...state.newSplit,
          company: {
            id: action.payload.id,
            name: action.payload.name,
            artistName: action.payload.artistName
          }
        }
      }
    case 'setRole':
      return {
        ...state,
        newSplit: {
          ...state.newSplit,
          role: action.payload.id,
          roleName: action.payload.role,
          roleCode: action.payload.roleCode
        }
      }
    default:
      throw new Error(`Action type ${action.type} is no part of reducer in CompositionSplitNew.jsx`);
  }
}

const NewCompositionSplit = ({ callbackFunction }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);

  const { companyId, compositionId } = useParams();

  let navigate = useNavigate();

  function companyClickedHandler(companyId) {
    axios.get('company', {
      params: {
        companyId: companyId
      }
    }).then(response => {
      dispatch({ type: 'setCompany', payload: response.data });
      setModal(INITIAL_MODAL_STATE);
    }).catch(err => {
      console.log(err);
    });
  }

  function workRoleClickedHandler(role) {
    dispatch({ type: 'setRole', payload: role });
    setModal(INITIAL_MODAL_STATE);
  }

  function addSplitHandler() {
    if (state.newSplit.company.id === '') {
      setModal({
        show: true,
        title: 'Error in Split Data',
        body: <p>Please select a company</p>
      })
      return;
    }

    const keys = ['synchronization', 'performance', 'mechanical']
    for (let i = 0; i < keys.length; i++) {
      if (Number.isNaN(Number(state.newSplit[keys[i]])) || state.newSplit[keys[i]] === '') {
        setModal({
          show: true,
          title: 'Error in Split Data',
          body: <p>The information for the splits need to be complete ({`In ${keys[i]} splits`})</p>
        });
        return;
      }
      if (Number(state.newSplit[keys[i]]) < 0) {
        setModal({
          show: true,
          title: 'Error in Split Data',
          body: <p>Only values bigger or equal than 0 are allowed to enter as a split</p>
        });
        return;
      }
      if (Number(state.newSplit[keys[i]]) > 100) {
        setModal({
          show: true,
          title: 'Error in Split Data',
          body: <p>Only values smaller or equal than 100 are allowed to enter as a split</p>
        });
        return;
      }
    }

    if (!state.newSplit.role || Number.isNaN(Number(state.newSplit.role))) {
      setModal({
        show: true,
        title: 'Error in Split Data',
        body: <p>A valid role needs to be selected</p>
      });
      return;
    }

    const data = {
      workId: compositionId,
      company: state.newSplit.company.id,
      role: state.newSplit.role,
      synchronizationRight: state.newSplit.synchronization,
      performanceRight: state.newSplit.performance,
      mechanicalRight: state.newSplit.mechanical
    }

    axios.post('catalogue/publishing/splits', data).then(response => {
      navigate(`/customers/catalogue/publishing/company-${companyId}/composition-${compositionId}`);
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Failed to add the work split',
        body: <p>The new split for the publishing rights could not be added to the database. Please check if enough remaining splits are available: {err?.response?.data?.message || err.message}</p>
      });
    })
  }

  function showSelectCompanyModal() {
    setModal({
      show: true,
      title: 'Select Shareholder for splits',
      body: <CompanyTable link={'company/search'} callbackFunction={companyClickedHandler} />
    })
  }

  function showSelectRoleModal() {
    setModal({
      show: true,
      title: 'Select Company Role',
      body: <WorkRoleSearch callbackFunction={workRoleClickedHandler} />
    })
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="/customers/catalogue/publishing">Home</Breadcrumb.Item>
        <Breadcrumb.Item href={`/customers/catalogue/publishing/company-${companyId}`}>Publishing Catalogue</Breadcrumb.Item>
        <Breadcrumb.Item href={`/customers/catalogue/publishing/company-${companyId}/composition-${compositionId}`}>Publishing Details</Breadcrumb.Item>
        <Breadcrumb.Item href={`/customers/catalogue/publishing/company-${companyId}/composition-${compositionId}/newsplit`} active={true}>New split</Breadcrumb.Item>
      </Breadcrumb>

      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />

      <h2>New Publishing Split</h2>

      <InputGroup className='mb-2'>
        <InputGroup.Text style={{ ...styles.inputGroupText }}>Company</InputGroup.Text>
        <Form.Control value={state.newSplit.company.id} readOnly={true} placeholder={'Company ID'} />
        <Form.Control value={state.newSplit.company.name} readOnly={true} placeholder={'Company Name'} />
        <Form.Control value={state.newSplit.company.artistName} readOnly={true} placeholder={'Artist Name'} />
        <Button tabIndex={1} variant={'outline-secondary'} onClick={showSelectCompanyModal}>Select</Button>
      </InputGroup>

      <InputGroup className={'mb-2'}>
        <InputGroup.Text style={styles.inputGroupText}>Company Role</InputGroup.Text>
        <Form.Control value={state.newSplit.role} readOnly={true} placeholder={'Company Role'} />
        <Form.Control value={state.newSplit.roleName} readOnly={true} placeholder={'Company Role'} />
        <Form.Control value={state.newSplit.roleCode ? 'Role-Code: ' + state.newSplit.roleCode : ''} readOnly={true} placeholder={'Company Role'} />
        <Button tabIndex={2} variant={'outline-secondary'} onClick={showSelectRoleModal}>Select</Button>
      </InputGroup>

      <InputGroup className='mb-2'>
        <InputGroup.Text style={styles.inputGroupText}>Synchronization</InputGroup.Text>
        <Form.Control tabIndex={3} type='number' placeholder="Synchronization rights..." value={state.newSplit.synchronization} onChange={(e) => dispatch({ type: 'setRight', key: 'synchronization', payload: e.target.value })} />
      </InputGroup>

      <InputGroup className='mb-2'>
        <InputGroup.Text style={styles.inputGroupText}>Performance</InputGroup.Text>
        <Form.Control tabIndex={4} type='number' placeholder="Performance rights..." value={state.newSplit.performance} onChange={(e) => dispatch({ type: 'setRight', key: 'performance', payload: e.target.value })} />
      </InputGroup>

      <InputGroup className='mb-2'>
        <InputGroup.Text style={styles.inputGroupText}>Mechanical</InputGroup.Text>
        <Form.Control tabIndex={5} type='number' placeholder="Mechanical rights..." value={state.newSplit.mechanical} onChange={(e) => dispatch({ type: 'setRight', key: 'mechanical', payload: e.target.value })} />
      </InputGroup>

      <Button tabIndex={6} variant='success' style={styles.fullWidth} onClick={() => addSplitHandler()}>Save new split</Button>
    </div>
  )
}

export default NewCompositionSplit;