import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getUser, ensureToken } from '../utils/requireAuth';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import music from '../assets/images/music.jpg';
import records from '../assets/images/records.jpg';
import usersImage from 'assets/images/users.jpg';
import workImage from 'assets/images/work.jpg';

const styles = {
  link: {
    textDecoration: 'none',
    color: '#05237f',
    cursor: 'pointer',
    fontWeight: '500'
  },
  marginTopSmall: {
    marginTop: '1rem'
  },
  marginTopBig: {
    marginTop: '2rem'
  }
}

const cards = [
  {
    image: music,
    title: 'Company Management',
    text: 'In the company management it is possible to search for available companies, get all the information for it and edit the information about the companies and user. Also in the company management, the mapping between the company itself and the account for the application can be found.',
    link: '/customers/company',
    caption: 'Go to company'
  },
  {
    image: records,
    title: 'Recording Catalogue',
    text: 'In the track catalogue management the track catalogues for companies can be retieved and the track details can be shown and edited. Included is also the split management and information about ISRC and YouTube Asset-ID\'s.',
    link: '/customers/catalogue/recording',
    caption: 'Go to Recordings'
  },
  {
    image: workImage,
    title: 'Publishing Catalogue',
    text: 'In the recording catalogue the musical works of the artists can be retrieved and the work details can be shown and edited. Included are also the writers, sources, splits and assigned recordings.',
    link: '/customers/catalogue/publishing',
    caption: 'Go to Publishings'
  },
  {
    image: usersImage,
    title: 'User Management',
    text: 'You have the possibility to create new users or to edit the existing ones. Also the assignment of companies to a user happens here.',
    link: '/customers/users',
    caption: 'Go to Users'
  }
]

export default function Home() {
  const [user, setUser] = useState(undefined);
  let navigate = useNavigate();

  useEffect(() => {
    const userData = getUser();
    if (userData) {
      setUser(userData);
    } else {
      navigate('/signin');
    }
  }, [])

  useEffect(() => {
    ensureToken();
  }, []);

  return (
    <div>
      <h1 style={styles.marginTopSmall}>Hello, {user ? user.userName : 'user'}!</h1>

      <Row xs={1} md={2} lg={2} xl={3} style={styles.marginTopBig}>
        {cards.map((item, idx) => {
          return (
            <Col className='mb-4' key={idx}>
              <Card style={{ 'height': '100%' }}>
                <Card.Img variant='top' src={item.image} />
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{item.text}</Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Link style={styles.link} to={item.link}>&#x3e; {item.caption}</Link>
                </Card.Footer>
              </Card>
            </Col>
          )
        })
        }
      </Row>
    </div>
  );
}
