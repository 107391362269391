import React, { useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

export default function AddSpotifyLink(props) {
    const [link, setLink] = useState('');
    const [isClickable, setIsClickable] = useState(false);

    /**
     * Changes the value of the state link
     * @param {Event} event
     */
    function changeValueHandler(value) {
        setLink(value);
        setIsClickable(value !== '');
    }

    /**
     * Calls the callback function after entering the spotify link
     */
    function saveClickedHandler() {
        if (isClickable) {
            props.callbackFunction(link);
        }
    }

    return (
        <div>
            <p>
                Please enter the spotify link you want to add to the user
                of the application.
            </p>
            <InputGroup className='mb-2'>
                <InputGroup.Text>Spotify Link</InputGroup.Text>
                <Form.Control
                    placeholder='New Spotify Link...'
                    value={link}
                    onChange={(e) => changeValueHandler(e.target.value)} />
            </InputGroup>
            <Button style={{ 'width': '100%' }} variant={isClickable ? 'success' : 'secondary'} onClick={saveClickedHandler}>Add Spotify Link</Button>
        </div>
    )
}

AddSpotifyLink.propTypes = {
    /**
     * Callback function that gets called whenever the button add spotify link
     * was clicked after entering the link in the input field.
     * */
    callbackFunction: PropTypes.func.isRequired
}