import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import SourceDetails from './SourceDetails';

const SourcesRoutes = [
    {
        path: '',
        element: <Home />
    },
    {
        path: ':sourceId',
        element: <SourceDetails />
    }
]

export default function Sources(props) {
    return (
        <Routes>
            {SourcesRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
            })}
        </Routes>
    )
}
