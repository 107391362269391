/**
 * Checks if login data for the user can be found in the session
 * storage and creates the user object if available
 * @returns {object} Information about the user
 */
export function getUser() {
    let user = undefined;

    // 1- Check if user information can be found in the session storage
    const userData = localStorage.getItem('user');

    // 2- Create the user object and return it if available
    if (userData) {
        user = JSON.parse(userData);
    }

    return user;
}

/**
 * The function checks if the token of the user is still valid
 * or if it can be renewed
 * @param {string} token The JWT token of the user
 * @returns 0 if token is valid, -1 if token is invalid,
 * 1 if token is valid and can be renewed
 */
export const shouldRenewToken = (token) => {
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
        return -1;
    }

    const claims = JSON.parse(atob(tokenParts[1]));
    if (claims && claims.exp) {
        const expiration = claims.exp * 1000;
        const now = Date.now();
        const renewalThreashold = now + 1800000;

        if (renewalThreashold > expiration && now < expiration) {
            return 1;
        } else if (now > expiration) {
            return -1;
        }
        return 0;
    }
    return -1
}

/**
 * The function ensures that a valid or a refreshable token is
 * available in the local storage
 */
export const ensureToken = () => {
    const user = getUser();
    if (!user || !user.token) {
        localStorage.clear();
        window.location.href = '/signin';
    }

    const shouldRenew = shouldRenewToken(user.token);
    if (shouldRenew === -1) {
        localStorage.clear();
        window.location.href = '/signin';
    }
}