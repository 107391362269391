import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';
import { Button, Row, Col } from 'react-bootstrap';
import AppModal from '../Modal/Modal';
import PropTypes from 'prop-types';

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const SourceDangerZone = ({ sourceId }) => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const user = getUser();
    let navigate = useNavigate();

    function deleteSource() {
        axios.delete('source', {
            params: {
                sourceId: sourceId
            }
        }).then(response => {
            if (response.status === 204) {
                setModal(INITIAL_MODAL_STATE);
                navigate('/metadata/source');
            }
        }).catch(err => {
            setModal({
                show: true,
                title: 'Deletion failed',
                body: (
                    <div>
                        <p>
                            The source could not be deleted: {err.message}
                        </p>
                    </div>
                )
            });
        });
    }

    function deleteSourceHandler() {
        setModal({
            show: true,
            title: 'Delete source',
            body: (
                <div>
                    <p>
                        Do you really want to delete the source? This action cannot be undone.
                    </p>
                    <Row>
                        <Col>
                            <Button variant={'secondary'} style={{ width: '100%' }} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button variant={'danger'} style={{ width: '100%' }} onClick={deleteSource}>Delete</Button>
                        </Col>
                    </Row>
                </div>
            )
        })
    }

    return (
        <div className='mb-4'>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <h2>Danger Zone</h2>
            <p>
                When deleting a source it is checked if the source is used anywhere and if not,
                it will be deleted.
            </p>
            <Button variant={'danger'} onClick={() => deleteSourceHandler()}>Delete source</Button>
        </div>
    )
}

SourceDangerZone.propTypes = {
    /**
     * ID of the source
     */
    sourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default SourceDangerZone;
