import React, { useState, useEffect, useMemo } from 'react';
import { InputGroup, Form, Button, Table, Row, Col, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AppModal from '../Modal/Modal';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';
import SourcePayoutDates from './SourcePayoutDates';

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const styles = {
    fullWidth: { width: '100%' },
    alignCenter: { textAlign: 'center' },
    marginTop: { marginTop: '1rem' },
    pointer: { cursor: 'pointer' },
    maxTableHeight: {
        maxHeight: '50vh',
        scroll: 'auto',
        overflowY: 'auto'
    }
}

const SourceRightsModal = ({ callbackFunction }) => {
    const [search, setSearch] = useState('');
    const [rights, setRights] = useState([]);
    const tableRows = useMemo(() => createTableRows(rights), [rights]);

    useEffect(() => {
        populateRightsData(search);
    }, [search]);

    function populateRightsData(searchtext) {
        axios.get('options/rights', {
            params: {
                search: searchtext
            }
        }).then(response => {
            if (response.status === 200) {
                setRights(response.data);
            }
        }).catch(err => {
            console.error(err);
        });
    }

    function createTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={2}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx} onClick={() => callbackFunction(item.id)} style={styles.pointer}>
                <td>{item.id}</td>
                <td>{item.rightName}</td>
            </tr>
        });
    }

    return (
        <div>
            <p>
                You can search and select the right you want to add to
                the source in the following table.
            </p>

            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Search</InputGroup.Text>
                <Form.Control
                    placeholder={'Searchtext...'}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} />
            </InputGroup>

            <div style={styles.maxTableHeight}>
                <Table bordered striped hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

SourceRightsModal.propTypes = {
    /**
     * Function gets called after clicking on a pos in the selection.
     * The function is expected to take the ID of the POS.
     */
    callbackFunction: PropTypes.func.isRequired
}

const SourceRights = ({ sourceId }) => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [rights, setRights] = useState([]);
    const [payoutDates, setPayoutDates] = useState([]);
    const tableRows = useMemo(() => createTableRows(rights), [rights]);
    const payoutDatesComponents = useMemo(() => createPayoutDatesComponents(payoutDates), [payoutDates]);
    const user = getUser();

    useEffect(() => {
        populateRightsData();
    }, []);

    useEffect(() => {
        if (rights.length > 0) {
            populatePayoutDatesData();
        } else {
            setPayoutDates([]);
        }
    }, [rights]);

    function populateRightsData() {
        axios.get('source/rights', {
            params: {
                sourceId: sourceId
            }
        }).then(response => {
            if (response.status === 200) {
                setRights(response.data);
            }
        }).catch(err => {
            console.log(err);
            setRights([]);
        })
    }

    function populatePayoutDatesData() {
        axios.get('source/payoutdates', {
            params: {
                sourceId: sourceId
            }
        }).then(response => {
            if (response.status === 200) {
                setPayoutDates(response.data);
            }
        }).catch(err => {
            console.error(err);
            setPayoutDates([]);
        })
    }

    function createTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={3}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx}>
                <td>{item.rightId}</td>
                <td>{item.right}</td>
                <td><Button size={'sm'} variant={'outline-danger'} onClick={() => deleteSourceRightModal(item.rightId)}>Delete</Button></td>
            </tr>
        })
    }

    function deleteSourceRightModal(rightId) {
        setModal({
            show: true,
            title: 'Delete source right',
            body: <div>
                <p>
                    Do you really want to delete the source right? Please keep in mind that
                    this action cannot be reversed.
                </p>

                <Row>
                    <Col>
                        <Button style={styles.fullWidth} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button style={styles.fullWidth} variant={'danger'} onClick={() => deleteSourceRight(rightId)}>Delete</Button>
                    </Col>
                </Row>
            </div>
        });
    }

    function deleteSourceRight(rightId) {
        axios.delete('source/rights', {
            params: {
                source: sourceId,
                right: rightId
            }
        }).then(response => {
            if (response.status === 204) {
                populateRightsData();
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            console.error(err);
            setModal({
                show: true,
                title: 'Failed to delete source rights',
                body: <p>
                    The source right could not be deleted because of an error: {err?.response?.data?.message || err.message}
                </p>
            })
        })
    }

    function createPayoutDatesComponents(data) {
        if (data.length === 0) {
            return <Alert variant='warning'>
                No information about which rights are covered by the source are available. Please enter this information first
                before you can add and edit payout dates for the individual rights.
            </Alert>
        }

        return data.map((item, idx) => {
            return <SourcePayoutDates key={idx} sourceId={sourceId} {...item} reloadData={populatePayoutDatesData} />
        })
    }

    function addNewRightModal() {
        setModal({
            show: true,
            title: 'Select new Right for the POS',
            body: <SourceRightsModal callbackFunction={addNewRight} />
        });
    }

    function addNewRight(right) {
        if (right === undefined) {
            return;
        }

        const data = {
            source: sourceId,
            right: right
        }
        axios.post('source/rights', data).then(response => {
            if (response.status === 201) {
                populateRightsData();
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            console.error(err);
            setModal({
                show: true,
                title: 'Failed to add the right',
                body: <p>
                    When adding the right to the source an error occured: {err?.response?.data?.message || err.message}
                </p>
            });
        })
    }

    return (
        <div className='mb-4'>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />

            <h2>Source Rights</h2>
            <p>
                All rights that are covered by a source should be specified here.
            </p>

            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>Right ID</th>
                        <th>Right Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>

            <Button variant='success' onClick={addNewRightModal}>New Source Right</Button>

            <h4 style={styles.marginTop}>Rights reporting dates</h4>

            <p>
                HELGA needs the specified reporting dates on which reports and payouts from
                the sources can be expected. This is a crucial part of the revenue protection.
            </p>

            {payoutDatesComponents}
        </div>
    )
}

SourceRights.propTypes = {
    /**
     * ID of the source
     */
    sourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default SourceRights;
