import { Route, Routes } from 'react-router-dom';
import CatalogueHome from "./Home";
import CatalogueTracks from './CatalogueTracks';
import CatalogueTrackDetails from "./CatalogueTrackDetails";


const CatalogueRoutes = [
    {
        path: '',
        element: <CatalogueHome />
    },
    {
        path: 'company-:companyId',
        element: <CatalogueTracks />
    },
    {
        path: 'company-:companyId/track-:trackId',
        element: <CatalogueTrackDetails />
    }
]

export default function Catalogue(props) {
    return (
        <Routes>
            {CatalogueRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
            })}
        </Routes>
    )
}