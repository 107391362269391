import React, { useState, useEffect } from 'react';
import { InputGroup, Form, Button, Col, Row, Breadcrumb } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AppUserTable from 'components/AppUserTable/AppUserTable';
import AppModal from 'components/Modal/Modal';
import axios from 'utils/axiosInstance';

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const UserAccount = () => {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [user, setUser] = useState(undefined);
  const { userId } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    populateUserAccountData(userId);
  }, [userId]);

  function populateUserAccountData(user) {
    axios.get('user/authid', {
      params: {
        userId: user
      }
    }).then(response => {
      if (response.status === 200) {
        setUser(response.data);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  function showRemoveAccountHandler() {
    setModal({
      show: true,
      title: 'Remove account from user',
      body: (
        <div>
          <p>
            Do you really wan't to remove the account from the user? By doing this,
            the user will not be able to access any information using the HELGA.app
            anymore!
          </p>
          <Row>
            <Col>
              <Button className={'w-100'} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancle</Button>
            </Col>
            <Col>
              <Button className={'w-100'} variant={'danger'} onClick={removeAccountHandler}>Remove account</Button>
            </Col>
          </Row>
        </div>
      )
    })
  }

  function removeAccountHandler() {
    axios.delete('user/authid', {
      params: { userId: userId }
    }).then(response => {
      if (response.status === 204) {
        setModal(INITIAL_MODAL_STATE);
        navigate(`/customers/users/${userId}`);
      }
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Failed to remove the account',
        body: <p>The account could not be removed from the user!</p>
      })
    });
  }

  function showSelectUserAccountModal() {
    setModal({
      show: true,
      title: 'Select User Account',
      body: <AppUserTable link={'user/notassigned'} callbackFunction={selectUserAccountHandler} />
    });
  }

  function selectUserAccountHandler(authId) {
    axios.post('user/authId', {
      userId: userId,
      authId: authId
    }).then(response => {
      if (response.status === 201) {
        setModal(INITIAL_MODAL_STATE);
        navigate(`/customers/users/${userId}`);
      }
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Failed to set the user account',
        body: <p>The user account could not be updated!</p>
      });
    });
  }
  console.log(user);
  return (
    <div className={'mb-4'}>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
      <Breadcrumb>
        <Breadcrumb.Item href={'/customers'}>Home</Breadcrumb.Item>
        <Breadcrumb.Item href={'/customers/users'}>Users</Breadcrumb.Item>
        <Breadcrumb.Item href={`/customers/users/${userId}`}>{user?.firstName && user?.lastName && user?.firstName + ' ' + user?.lastName || userId}</Breadcrumb.Item>
        <Breadcrumb.Item href={`/customers/users/${userId}/account`} active={true}>Account</Breadcrumb.Item>
      </Breadcrumb>

      <h1>User Account Selection</h1>
      <p>
        A new HELGA account can be assigned to the user in the following
      </p>
      <InputGroup className={'mb-2'}>
        <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>Auth-ID</InputGroup.Text>
        <Form.Control
          placeholder={'Auth-ID'}
          value={user?.uId || ''}
          readOnly={true} />
      </InputGroup>

      <InputGroup className={'mb-2'}>
        <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>First name</InputGroup.Text>
        <Form.Control
          placeholder={'First name'}
          value={user?.firstName || ''}
          readOnly={true} />
      </InputGroup>

      <InputGroup className={'mb-2'}>
        <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>Last name</InputGroup.Text>
        <Form.Control
          placeholder={'Last name'}
          value={user?.lastName || ''}
          readOnly={true} />
      </InputGroup>

      <InputGroup className={'mb-2'}>
        <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>Artistname</InputGroup.Text>
        <Form.Control
          placeholder={'Artist name'}
          value={user?.artistName || ''}
          readOnly={true} />
      </InputGroup>

      <Row>
        <Col>
          <Button className={'w-100'} variant={'outline-danger'} onClick={showRemoveAccountHandler}>Remove account from user</Button>
        </Col>
        <Col>
          <Button className={'w-100'} variant={'outline-primary'} onClick={showSelectUserAccountModal}>Select different account</Button>
        </Col>
      </Row>
    </div>
  )
}

export default UserAccount;