import React from 'react';

const PageNotExistend = () => {
  return (
    <div>
      <h1>Oh damn, this page doesn't exist yet</h1>
      <p>
        Please come back later
      </p>
    </div>
  )
}

export default PageNotExistend;