import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { InputGroup, Form, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import AppModal from 'components/Modal/Modal';
import PropTypes from 'prop-types';
import axios from 'utils/axiosInstance';
import CompanyTable from 'components/CompanyTable/CompanyTable';

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const INITIAL_STATE = {
  userDetails: {
    id: null,
    name: null,
    authid: null,
    subscription: null,
    subscriptionVersion: null,
    subscriptionName: null,
    subscriptionStart: null,
    subscriptionEnd: null,
    paymentLinkedCompany: null,
    paymentLinkedCompanyName: null,
    email: null,
    isManager: false,
    isSplitUserOnly: false,
    createdAt: null,
    updatedAt: null
  },
}

const ELEMENTS = [
  ['id', { type: 'input', label: 'ID', editable: false }],
  ['name', { type: 'input', label: 'Name', editable: true }],
  ['authid', { type: 'input', label: 'AuthID', editable: false }],
  ['email', { type: 'input', label: 'E-Mail', editable: false }],
  ['paymentLinkedCompany', { type: 'company', label: 'Payment Company ID', editable: false }],
  ['paymentLinkedCompanyName', { type: 'input', label: 'Payment Company', editable: false }],
  ['isManager', { type: 'bool', label: 'Manager', editable: true }],
  ['isSplitUserOnly', { type: 'bool', label: 'Split User', editable: true }],
  ['createdAt', { type: 'dateInput', label: 'Created at', editable: false }],
  ['updatedAt', { type: 'dateInput', label: 'Updated at', editable: false }]
]

const reducer = (state, action) => {
  switch (action.type) {
    case 'load':
      return {
        ...state,
        userDetails: action.payload
      }
    case 'edit':
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          [action.key]: action.payload
        }
      }
    case 'paymentCompany':
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          paymentLinkedCompany: action.payload.id,
          paymentLinkedCompanyName: action.payload.name,
        }
      }
    case 'reset':
      return INITIAL_MODAL_STATE;
    default:
      break;
  }
}

const UserDetails = ({ userId }) => {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  // Create all the components
  const itemSettings = new Map(ELEMENTS);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const components = useMemo(() => createComponents(), [state.userDetails]);

  useEffect(() => {
    populateUserDetailsData(userId);
  }, [userId]);

  const populateUserDetailsData = (userId) => {
    axios.get('user', {
      params: { userId: userId }
    }).then(response => {
      if (response.status === 200) {
        dispatch({ type: 'load', payload: response.data });
      }
    }).catch(err => {
      console.log(err);
      dispatch({ type: 'reset' });
    })
  }

  function openSelectPaymentLinkedCompanyModal() {
    setModal({
      show: true,
      title: 'Select linked payment company',
      body: <CompanyTable link={'company/search'} callbackFunction={companyClickedHandler} />
    });
  }

  const companyClickedHandler = (companyId) => {
    axios.get('company', {
      params: {
        companyId: companyId
      }
    }).then(response => {
      if (response.status === 200) {
        const data = response.data;
        dispatch({ type: 'paymentCompany', payload: data });
        setModal(INITIAL_MODAL_STATE);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  function saveUserDetails() {
    const data = {
      id: state.userDetails.id,
      name: state.userDetails.name,
      paymentLinkedCompany: state.userDetails.paymentLinkedCompany,
      isManager: state.userDetails.isManager,
      isSplitUserOnly: state.userDetails.isSplitUserOnly,
    }

    axios.put('user', data).then(response => {
      if (response.status === 201) {
        populateUserDetailsData(userId);
        setModal({
          show: true,
          title: 'Update successfull',
          body: <p>The user was updated successfully</p>
        })
      }
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Failed to update',
        body: <p>The information about the user could not be updated</p>
      });
    });
  }

  function createComponents() {
    const items = []
    itemSettings.forEach((item, key) => {
      switch (item.type) {
        case 'input':
          items.push(
            <InputGroup key={items.length} className='mb-2'>
              <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>{item.label}</InputGroup.Text>
              <Form.Control
                placeholder={`${item.label}...`}
                value={state.userDetails[key] ? state.userDetails[key] : ''}
                readOnly={!item.editable}
                disabled={!item.editable}
                onChange={(e) => dispatch({ type: 'edit', key: key, payload: e.target.value })} />
            </InputGroup>
          )
          break;
        case 'dateInput':
          items.push(
            <InputGroup key={items.length} className='mb-2'>
              <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>{item.label}</InputGroup.Text>
              <Form.Control
                placeholder={`${item.label}...`}
                value={state.userDetails[key] ? new Date(state.userDetails[key]).toLocaleString() : ''}
                readOnly={!item.editable}
                disabled={!item.editable}
                onChange={(e) => dispatch({ type: 'edit', key: key, payload: e.target.value })} />
            </InputGroup>
          )
          break;
        case 'company':
          items.push(
            <InputGroup key={items.length} className='mb-2'>
              <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>{item.label}</InputGroup.Text>
              <Form.Control
                placeholder={`${item.label}...`}
                value={state.userDetails[key] || 'N/A'}
                readOnly={true}
              />
              <Button variant={'outline-secondary'} onClick={openSelectPaymentLinkedCompanyModal}>Select</Button>
            </InputGroup>
          );
          break;
        case 'bool':
          items.push(
            <InputGroup key={items.length} className={'mb-2'}>
              <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>{item.label}</InputGroup.Text>
              <Form.Control value={state.userDetails[key] ? 'Yes' : 'No'} readOnly={true} />
              <DropdownButton variant={'outline-secondary'} title={'Select'} id={key}>
                <Dropdown.Item onClick={() => dispatch({ type: 'edit', key: key, payload: true })}>Yes</Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch({ type: 'edit', key: key, payload: false })}>No</Dropdown.Item>
              </DropdownButton>
            </InputGroup>
          )
          break;
        case 'subscription':
          break;
        default:
          break;
      }
    });
    return items;
  }

  return (
    <div className={'mb-4 mt-4'}>
      <AppModal size={'xl'} show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />

      {components}

      <Button className={'w-100'} onClick={saveUserDetails} variant={'success'}>Save Updates</Button>
    </div>
  )
}

UserDetails.propTypes = {
  /**
   * The ID of the user
   */
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default UserDetails;
