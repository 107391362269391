import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { Breadcrumb, Table, Badge, Button, Row, Col } from 'react-bootstrap';
import Paging from 'components/Paging/Paging';
import AppModal from 'components/Modal/Modal';
import axios from 'utils/axiosInstance';

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const INITIAL_STATE = {
  collections: [],
  startIndex: 0,
  totalCount: 0,
  pageNumber: 0,
  pageSize: 10
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'load':
      return {
        ...state,
        collections: action.payload.items,
        pageNumber: action.payload.pageNumber,
        startIndex: action.payload.recordNumber,
        totalCount: action.payload.totalCount
      }
    case 'reset':
      return INITIAL_STATE;
    case 'paging':
      const maxPageNumber = Math.ceil(state.totalCount / state.pageSize);
      const pageNumber = Math.min(Math.max(action.payload, 1), maxPageNumber);
      return {
        ...state,
        pageNumber: pageNumber,
        startIndex: (pageNumber - 1) * state.pageSize
      }
    default:
      throw new Error(`Action type ${action.type} is no part of reducer in CollectionManager.jsx`);
  }
}

function formatDateTime(dateString) {
  if (!dateString) {
    return 'N/A';
  }

  const date = new Date(dateString);

  if (isNaN(date)) {
    return `${dateString}`;
  }

  const localeDate = date.toLocaleDateString();
  const localeTime = date.toLocaleTimeString();

  return `${localeDate} ${localeTime}`;
}

const CollectionManager = (props) => {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const tableRows = useMemo(() => mapTableRows(state.collections), [state.collections]);

  useEffect(() => {
    const executePopulateData = () => {
      populateCollectionsData();
    }

    // Initial Request
    executePopulateData();

    // The deletion of the data happens in the background. Check every 5 seconds
    // if anything changed in the data
    const interval = setInterval(executePopulateData, 5000);

    // Delete the interval again
    return () => clearInterval(interval);

  }, [state.startIndex, state.pageSize, state.pageNumber, state.totalCount]);

  /**
   * The method requests all the collection data from the api
   * @returns A list of objects with type  collection managers that
   * have the keys id, collectionDate, isSplitted, isProcessed, createdAt
   * and updatedAt
   */
  function populateCollectionsData() {
    axios.get('collectionmanager', {
      params: {
        startIndex: state.startIndex,
        pageSize: state.pageSize,
      }
    }).then(res => {
        if (res.status === 200)
          dispatch({ type: 'load', payload: res.data });
    }).catch(err => {
      console.log(err);
      dispatch({ type: 'reset' });
    })
  }

  /**
   * Creates a Badge and returns it
   * @param {boolean} value Boolean flag
   * @returns JSX.Element that represents a badge
   */
  function createBadge(value, collectionDeletable) {
    var bg = value ? 'success' : 'warning';
    var text = value ? 'white' : 'dark';

    if (collectionDeletable) {
      bg = 'secondary';
      text = 'dark';
    }

    return <Badge
      bg={bg}
      text={text}>
      {value ? 'Yes' : 'No'}
    </Badge>
  }

  function confirmCollectionDelete(collectionId) {
    setModal({
      show: true,
      title: 'Delete imported Collection',
      body: <div>
        <p>
          Do you really want to delete the imported collection? By this, all data will be
          deleted corresponding to this import. This includes revenue data of artists,
          analytics data, splitted revenue as well as invoices which might already be sent
          to tipalti.
        </p>
        <Row>
          <Col>
            <Button className={'w-100'} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancel</Button>
          </Col>
          <Col>
            <Button className={'w-100'} variant={'danger'} onClick={() => deleteCollectionHandler(collectionId)}>DELETE</Button>
          </Col>
        </Row>
      </div>
    })
  }

  function deleteCollectionHandler(collectionId) {
    axios.delete('collectionmanager', {
      params: { collectionId: collectionId }
    }).then(response => {
      if (response.status === 204) {
        populateCollectionsData();
        setModal({
          show: true,
          title: 'Collection marked to be deleted',
          body: <p>
            The collection was marked as deletable. It will be deleted
            in a background process and should be gone in a few moments.
          </p>
        })
      }
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Failed to mark collection as deletable',
        body: <p>
          The collection is either already deleted or an error occurred.
        </p>
      })
    })
  }

  /**
   * Creates the table rows for the collection manager table
   * @param {Array} data An array of objects for which the table rows
   * will be created
   * @returns JSX.Element
   */
  function mapTableRows(data) {
    if (data.length === 0) {
      return <tr className={'text-center'}>
        <td colSpan={7}>No data available</td>
      </tr>
    }

    return data.map((item, idx) => {
      return <tr key={idx} style={{ textDecoration: item.deleteCollection ? 'line-through' : 'none' }}>
        <td>{item.id}</td>
        <td>{formatDateTime(item.collectionDate)}</td>
        <td className={'text-center'}>{createBadge(item.isSplitted, item.deleteCollection)}</td>
        <td className={'text-center'}>{createBadge(item.isProcessed, item.deleteCollection)}</td>
        <td>{formatDateTime(item.createdAt)}</td>
        <td>{formatDateTime(item.updatedAt)}</td>
        <td className={'text-center'}>
          <Button
            variant={item.deleteCollection ? 'secondary' : 'danger'}
            size={'sm'}
            onClick={() => confirmCollectionDelete(item.id)}
            disabled={item.deleteCollection}
          >
            {item.deleteCollection ? 'DELETING' : 'Delete'}
          </Button>
        </td>
      </tr>
    })
  }

  return (
    <div>
      <AppModal
        {...modal}
        closeHandler={() => setModal({
          show: false,
          title: '',
          body: null
        })}
      />

      <Breadcrumb>
        <Breadcrumb.Item href={'/administration'}>Home</Breadcrumb.Item>
        <Breadcrumb.Item href={'/administration/collectionmanager'} active>Collection Manager</Breadcrumb.Item>
      </Breadcrumb>

      <h1>Collection Manager</h1>
      <p>
        Below are all collections listed which were imported into HELGA.works. You have the possibility
        to see the details of a collection change details and delete them from the system.
      </p>

      <Table bordered striped hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Import Date</th>
            <th className={'text-center'}>Splitted</th>
            <th className={'text-center'}>Processed</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </Table>

      {state.collections && state.totalCount > 10 &&
        <Paging
          currentPage={state.pageNumber}
          totalCount={state.totalCount}
          pageSize={state.pageSize}
          callbackFunction={(i) => dispatch({ type: 'paging', payload: i })}
        />

      }
    </div>
  )
}

export default CollectionManager;


