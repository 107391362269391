import React, { useEffect, useState, useMemo } from 'react';
import { Table, InputGroup, Button, Row, Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AppModal from '../Modal/Modal';
import axios from '../../utils/axiosInstance';

const styles = {
    alignCenter: {
        textAlign: 'center'
    },
    fullWidth: {
        width: '100%'
    },
    maxTableHeight: {
        maxHeight: '50vh',
        scroll: 'auto',
        overflow: 'scroll'
    },
    pointer: {
        cursor: 'pointer'
    }
}

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const SourcePosModal = ({ callbackFunction }) => {
    const [pointOfSales, setPointOfSales] = useState([]);
    const [search, setSearch] = useState('');
    const tableRows = useMemo(() => createTableRows(pointOfSales), [pointOfSales]);

    useEffect(() => {
        populatePosData(search);
    }, [search]);

    function populatePosData(searchText) {
        axios.get('options/pointofsales', {
            params: {
                search: searchText
            }
        }).then(response => {
            if (response.status === 200) {
                setPointOfSales(response.data);
            }
        }).catch(err => {
            console.log(err);
            setPointOfSales([]);
        })
    }

    function createTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={2}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx} onClick={() => callbackFunction(item.id)} style={styles.pointer}>
                <td>{item.id}</td>
                <td>{item.pos}</td>
            </tr>
        })
    }

    return (
        <div>
            <p>
                A new point of sales can be assigned by searching and clicking on it below
            </p>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Search</InputGroup.Text>
                <Form.Control
                    placeholder={'Point of sales searchtext...'}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} />
            </InputGroup>

            <div style={styles.maxTableHeight}>
                <Table bordered striped hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Point of sales</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

const SourcePos = ({ sourceId }) => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [sourcePos, setSourcePos] = useState([]);
    const tableRows = useMemo(() => createTableRows(sourcePos), [sourcePos]);

    useEffect(() => {
        populateSourcePosData();
    }, []);

    function populateSourcePosData() {
        axios.get('/source/pos', {
            params: {
                source: sourceId
            }
        }).then(response => {
            if (response.status === 200) {
                setSourcePos(response.data);
            }
        }).catch(err => {
            console.error(err);
            setSourcePos([]);
        })
    }

    function createTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={4}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{item.posId}</td>
                <td>{item.pos}</td>
                <td><Button size={'sm'} variant='outline-danger' onClick={() => deleteSourcePosModal(item.posId)}>Delete</Button></td>
            </tr>
        })
    }

    function deleteSourcePosModal(pos) {
        setModal({
            show: true,
            title: 'Delete source point of sale',
            body: <div>
                <p>
                    Do you really want to delete the source point of sale? Please keep
                    in mind that you cannot reverse this action
                </p>
                <Row>
                    <Col>
                        <Button style={styles.fullWidth} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Close</Button>
                    </Col>
                    <Col>
                        <Button style={styles.fullWidth} variant={'danger'} onClick={() => deleteSourcePos(pos)}>Delete</Button>
                    </Col>
                </Row>
            </div>
        })
    }

    function deleteSourcePos(pos) {
        axios.delete('source/pos', {
            params: {
                source: sourceId,
                pos: pos
            }
        }).then(response => {
            if (response.status === 204) {
                populateSourcePosData();
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            console.error(err);
            setModal({
                show: true,
                title: 'Failed to delete source point of sale',
                body: <p>
                    When deleting the source point of sale an error occured: {err?.response?.data?.message || err.message}
                </p>
            })
        })
    }

    function openSelectPosModal() {
        setModal({
            show: true,
            title: 'New Source Point of Sales',
            body: <SourcePosModal callbackFunction={addSourcePosHandler} />
        })
    }

    function addSourcePosHandler(pos) {
        const data = {
            source: sourceId,
            pos: pos
        }
        axios.post('source/pos', data).then(response => {
            if (response.status === 201) {
                populateSourcePosData();
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            console.error(err);
            setModal({
                show: true,
                title: 'Failed to add Source POS',
                body: <p>
                    While assigning the Point of Sales to the source an error occured: {err?.response?.data?.message || err.message}
                </p>
            });
        })
    }

    return (
        <div className={'mb-4'}>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />

            <h2>Source Point of Sales</h2>

            <p>
                The point of sales that are covered by a source
            </p>

            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>ID</th>
                        <th>Point of Sales</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>

            <Button variant='success' onClick={openSelectPosModal}>New Source POS</Button>
        </div>
    )
}

SourcePos.propTypes = {
    /**
     * The ID of the source
     */
    sourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default SourcePos;
