import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import AppModal from "../Modal/Modal";
import { getUser } from "../../utils/requireAuth";
import axios from '../../utils/axiosInstance';

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

export default function CompanyDetailsToggleElements(props) {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [verification, setVerification] = useState({ userId: null, verified: false });
    const [active, setActive] = useState({ userId: null, isActive: false });
    const [companyEnabled, setCompanyEnabled] = useState({ companyId: null, isEnabled: false });
    const user = getUser();
    let navigate = useNavigate();

    useEffect(() => {
        // // Get the verification status
        // getVerificationStatus(props.companyId);

        // // Get the active status of the master user
        // getActiveStatus(props.companyId);

        // Get the enable status of the company
        getCompanyEnabledStatus(props.companyId);
    }, [props.companyId]);

    /**
     * Updates the verification status of the user in firestore
     * @param {bool} value Value of the dropdown field
     */
    function verifiedChangedHandler(value) {
        if (verification.userId === '' || verification.userId === null) {
            setModal({
                show: true,
                title: 'Update not possible',
                body: <p>There is no user available for this company. Please set a master user first!</p>
            });
            return;
        }

        axios.post('user/verification', {
            userId: verification.userId,
            verified: value
        }).then(response => {
            setVerification({ ...verification, verified: value });
        }).catch(err => {
            showUpdateFailedModal('The Update of the verification status failed');
        });
    }

    /**
     * Loads the verification status of the master user of the company
     * @param {number} companyId ID of the company for which the verification status should be loaded
     */
    function getVerificationStatus(companyId) {
        axios.get('user/verification', {
            params: {
                companyId: companyId
            }
        }).then(response => {
            setVerification(response.data);
        }).catch(err => {
            setVerification({ userId: '', verified: false });
        });
    }

    /**
     * Updates the activity status of the user in the database
     * @param {bool} value Value of the dropdown field
     */
    function activeStatusChangedHandler(value) {
        axios.post('/user/activestatus', { userId: active.userId, isActive: value }).then(response => {
            setActive({ ...active, isActive: value });
        }).catch(err => {
            console.log(err);
            showUpdateFailedModal('The update of the activity status of the user failed');
        });
    }

    /**
     * Loads the active status of the master user of the company
     * @param {number} companyId ID of the company for which the active status should be loaded
     */
    function getActiveStatus(companyId) {
        axios.get('user/activestatus', {
            params: {
                companyId: companyId
            }
        }).then(response => {
            setActive(response.data);
        }).catch(err => {
            setActive({ userId: null, isActive: false });
        });
    }

    /**
     * Loads the status if the company is enabled or not
     * @param {number} companyId ID of the company for which the enabled status should be loaded
     */
    function getCompanyEnabledStatus(companyId) {
        axios.get('company/enablestatus', {
            params: {
                companyId: companyId
            }
        }).then(response => {
            setCompanyEnabled(response.data);
        }).catch(err => {
            setCompanyEnabled({ companyId: null, isEnabled: false });
        });
    }

    /**
     * Updates the enabled status of the company
     * @param {bool} value Value of the dropdown field
     */
    function updateCompanyEnabledHandler(value) {
        axios.post('company/enablestatus', { companyId: companyEnabled.companyId, isEnabled: value }).then(response => {
            setCompanyEnabled({ ...companyEnabled, isEnabled: value });
        }).catch(err => {
            showUpdateFailedModal('The update of the enabled status of the company failed');
        });
    }

    /**
     * Displays a message to the user
     * @param {string} message Message that should be displayed
     */
    function showUpdateFailedModal(message) {
        setModal({
            show: true,
            title: 'Update failed',
            body: <p>{message}</p>
        });
    }

    return (
        <div>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            {/* <InputGroup className='mb-2'>
                <InputGroup.Text style={{'width': '20%', 'minWidth': '180px'}}>User Verification Status</InputGroup.Text>
                <Form.Control value={verification.verified ? 'Yes' : 'No'} readOnly={true} />
                <DropdownButton variant="outline-secondary" title="Select" disabled={verification.userId === null}>
                    <Dropdown.Item onClick={() => verifiedChangedHandler(true)}>True</Dropdown.Item>
                    <Dropdown.Item onClick={() => verifiedChangedHandler(false)}>False</Dropdown.Item>
                </DropdownButton>
            </InputGroup> */}

            {/* <InputGroup className='mb-2'>
                <InputGroup.Text style={{'width': '20%', 'minWidth': '180px'}}>User Active Status</InputGroup.Text>
                <Form.Control value={active.isActive ? 'Yes' : 'No'} readOnly={true} />
                <DropdownButton variant="outline-secondary" title="Select" disabled={active.userId === null}>
                    <Dropdown.Item onClick={() => activeStatusChangedHandler(true)}>True</Dropdown.Item>
                    <Dropdown.Item onClick={() => activeStatusChangedHandler(false)}>False</Dropdown.Item>
                </DropdownButton>
            </InputGroup> */}

            <InputGroup className='mb-2'>
                <InputGroup.Text style={{ 'width': '20%', 'minWidth': '180px' }}>Company enabled</InputGroup.Text>
                <Form.Control value={companyEnabled.isEnabled ? 'Yes' : 'No'} readOnly={true} />
                <DropdownButton variant="outline-secondary" title="Select" disabled={companyEnabled.companyId === null}>
                    <Dropdown.Item onClick={() => updateCompanyEnabledHandler(true)}>True</Dropdown.Item>
                    <Dropdown.Item onClick={() => updateCompanyEnabledHandler(false)}>False</Dropdown.Item>
                </DropdownButton>
            </InputGroup>
        </div>
    )
}

CompanyDetailsToggleElements.propTypes = {
    /**
     * ID of the company for which the toggle elements should be displayed
     */
    companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}