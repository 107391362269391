import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';
import AppModal from '../Modal/Modal';
import AddSplit from './AddSplit';

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const styles = {
    actionButton: { textAlign: 'center', width: '100px' },
    center: { textAlign: 'center' }
}

export default function TrackSplits(props) {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [splits, setSplits] = useState([]);
    const tableRows = useMemo(() => mapTableRows(splits), [splits]);

    const { trackId } = useParams();

    useEffect(() => {
        populateSplitsData();
    }, []);

    function populateSplitsData() {
        axios.get('catalogue/recording/split', {
            params: {
                trackId: trackId
            }
        }).then(response => {
            setSplits(response.data);
        }).catch(err => {
            console.log(err);
        });
    }

    function deleteSplitHandler(item) {
        if (item.companyId === '' || item.track === '') {
            return;
        }

        axios.delete('catalogue/recording/split', {
            params: {
                trackId: item.track,
                companyId: item.companyId
            }
        }).then(response => {
            populateSplitsData();
        }).catch(err => {
            console.log(err);
            setModal({
                show: true,
                title: 'Split could not be deleted',
                body: <p>The selected split could not be deleted in the database.</p>
            });
        });
    }

    function mapTableRows(data) {
        if (data.length === 0) {
            return <tr>
                <td colSpan={7} style={styles.center}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return (
                <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item.companyId}</td>
                    <td>{item.synchronizationRights}%</td>
                    <td>{item.performanceRights}%</td>
                    <td>{item.reproductionRights}%</td>
                    <td style={styles.actionButton}><Button variant='outline-danger' size='sm' onClick={() => deleteSplitHandler(item)}>Delete</Button></td>
                </tr>
            );
        });
    }

    function closeSplitsModal() {
        setModal(INITIAL_MODAL_STATE);
        populateSplitsData();
    }

    function showSplitsModal() {
        setModal({
            show: true,
            title: 'Add splits for track',
            body: <AddSplit callbackFunction={closeSplitsModal} />
        })
    }

    return (
        <div style={props.style} className={'mb-4'}>
            <AppModal show={modal.show} title={modal.title} body={modal.body} size='xl' closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <div className="w-100">
                <Table striped hover bordered responsive={true}>
                    <thead>
                        <tr>
                            <th>&#8470;</th>
                            <th>Company</th>
                            <th>Synchronization Rights</th>
                            <th>Performance Rights</th>
                            <th>Reproduction Rights</th>
                            <th style={styles.actionButton}>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>
            </div>
            <Button variant='secondary' onClick={showSplitsModal}>Add Splits</Button>
        </div>
    )
}

TrackSplits.propTypes = {
    /**
     * Style attributes for the element TrackSplits
     */
    style: PropTypes.object
}