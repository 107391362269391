import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Row, Col } from 'react-bootstrap';
import AppModal from '../Modal/Modal';
import axios from '../../utils/axiosInstance';
import SourceTable from '../SourceTable/SourceTable';

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const styles = {
    center: { textAlign: 'center' },
    fullWidth: { width: '100%' },
    pointer: {
        cursor: 'pointer'
    }
}

const CompositionSources = ({ compositionId }) => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [sources, setSources] = useState([]);
    const tableRows = useMemo(() => createTableRows(sources), [sources]);

    useEffect(() => {
        populateSourceData(compositionId);
    }, [compositionId]);

    function populateSourceData(composition) {
        axios.get('catalogue/publishing/sources', {
            params: {
                composition: composition
            }
        }).then(response => {
            if (response.status === 200) {
                setSources(response.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    function createTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.center}>
                <td colSpan={4}>No data available</td>
            </tr>
        }

        return data.map((item, key) => {
            return <tr key={key} style={styles.pointer}>
                <td>{item.source}</td>
                <td>{item.sourceName}</td>
                <td>{item.sourceType || 'N/A'}</td>
                <td><Button size={'sm'} variant={'outline-danger'} onClick={() => showDeleteSourceModal(item.source)}>delete</Button></td>
            </tr>
        })
    }

    function showDeleteSourceModal(source) {
        setModal({
            show: true,
            title: 'Delete source from composition',
            body: <div>
                <p>
                    Do you really want to delete the source from the composition? Please keep in mind
                    that this action cannot be reversed
                </p>
                <Row>
                    <Col>
                        <Button variant={'secondary'} style={styles.fullWidth} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button variant={'danger'} style={styles.fullWidth} onClick={() => deleteCompositionSourceHandler(source)}>Delete</Button>
                    </Col>
                </Row>
            </div>
        })
    }

    function deleteCompositionSourceHandler(source) {
        axios.delete('catalogue/publishing/sources', {
            params: {
                composition: compositionId,
                source: source
            }
        }).then(response => {
            if (response.status === 204) {
                populateSourceData(compositionId);
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            setModal({
                show: true,
                title: 'Failed to delete composition source',
                body: (
                    <p>
                        The source could not be deleted from the composition because of an error: {err.message}
                    </p>
                )
            })
        })
    }

    function showAddSourceModal() {
        setModal({
            show: true,
            title: 'Select source',
            body: <SourceTable callbackFunction={sourceClickedHandler} />
        })
    }

    function sourceClickedHandler(source) {
        if (source === null || source === undefined || source === '') {
            return;
        }

        const data = {
            composition: compositionId,
            source: source
        }
        axios.post('catalogue/publishing/sources', data).then(repsonse => {
            if (repsonse.status === 201) {
                populateSourceData(compositionId);
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            console.log(err);
            setModal({
                show: true,
                title: 'Failed to add source',
                body: (
                    <p>
                        The source could not be added to the composition because of an error:
                        {err.message}
                    </p>
                )
            })
        })
    }

    return (
        <div className={'mb-4'}>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <h5>Work Sources</h5>
            <p>
                The sources of a composition is the party that delivers reports and
                payments to the artist. In the following sources can be added or removed.
            </p>
            <Table bordered striped hover responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Source Name</th>
                        <th>Source Type</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
            <Button variant={'success'} onClick={() => showAddSourceModal()}>Add Source</Button>
        </div>
    )
}

CompositionSources.propTypes = {
    /**
     * ID of the composition
     */
    compositionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default CompositionSources;
