import Administration from "pages/Administration/Administration";
import Home from "./components/Home";
import SignIn from "./pages/Authentication/SignIn";
import SignUp from "./pages/Authentication/SignUp";
import Customers from "./pages/Customers/Customers";
import Metadata from "./pages/Metadata/Metadata";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/signin',
    element: <SignIn />
  },
  {
    path: '/signup',
    element: <SignUp />
  },
  {
    path: '/customers/*',
    element: <Customers />
  },
  {
    path: '/metadata/*',
    element: <Metadata />
  },
  {
    path: '/administration/*',
    element: <Administration />
  }
];

export default AppRoutes;
