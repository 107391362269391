import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CompanyTable from '../CompanyTable/CompanyTable';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';
import DropdownButton from 'react-bootstrap/esm/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

/**
 * Style attributes used in this component
 */
const styles = {
    inputGroupText: {
        width: "20%",
        minWidth: "175px"
    },
    fullWidth: {
        width: '100%'
    }
}

/**
 * Function creates the JSX elements for displaying the selected
 * company to the user
 * @param {object} props Properties of the function
 * @returns JSX elements
 */
function SelectedCompany(props) {

    // If user has no company selected, just display an alert
    if (props.company === undefined) {
        return <Alert variant='warning'>Please select a company in the table above!</Alert>
    }

    return (
        <div>
            <InputGroup className='mb-2'>
                <InputGroup.Text style={styles.inputGroupText}>Company ID</InputGroup.Text>
                <Form.Control value={props.company.id} readOnly={true} />
            </InputGroup>

            <InputGroup className='mb-2'>
                <InputGroup.Text style={styles.inputGroupText}>Company Name</InputGroup.Text>
                <Form.Control value={props.company.name} readOnly={true} />
            </InputGroup>

            <InputGroup className='mb-2'>
                <InputGroup.Text style={styles.inputGroupText}>Artist Name</InputGroup.Text>
                <Form.Control value={props.company.artistName} readOnly={true} />
            </InputGroup>
        </div>
    )
}

/**
 * Function will create the JSX elements for displaying the input fields the user
 * will use to enter the split information.
 * @param {object} props Properties of the function RightsInput
 * @returns JSX elements
 */
function RightsInput(props) {
    const [remaining, setRemaining] = useState(undefined);

    const { trackId } = useParams();
    const user = getUser();

    let navigate = useNavigate();

    /**
     * When the function was loaded, the information for remaining
     * splits will be loaded. This are the shares of the song, others
     * can use for creating splits.
     */
    useEffect(() => {
        populateRemainingSplits();
    }, []);

    /**
     * Fetches the information about the remaining splits from
     * the server.
     */
    function populateRemainingSplits() {
        axios.get('catalogue/recording/split/remaining', {
            params: { trackId: trackId },
        }).then(response => {
            setRemaining(response.data);
        }).catch(err => {
            console.log(err);
            setRemaining(undefined);
        });
    }

    return (
        <div style={props.style}>
            {
                remaining !== undefined ?
                    <Alert variant='info'>
                        <p>Maximal amout for splits are:</p>
                        <ul>
                            <li>Synchronization rights: {remaining.synchronizationRights}%</li>
                            <li>Performance rights: {remaining.performanceRights}%</li>
                            <li>Reproduction rights: {remaining.reproductionRights}%</li>
                        </ul>
                    </Alert>
                    : null
            }

            <h5>Rights allocation</h5>
            <InputGroup className='mb-2'>
                <InputGroup.Text style={styles.inputGroupText}>Synchronization rights</InputGroup.Text>
                <Form.Control type='number' placeholder="Synchronization rights..." value={props.rights.synchronization} onChange={(e) => props.setRights({ ...props.rights, synchronization: e.target.value })} />
                <DropdownButton title="Select">
                    <Dropdown.Item onClick={() => props.setRights({ ...props.rights, synchronization: 25 })}>25%</Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setRights({ ...props.rights, synchronization: 33.33 })}>33.33%</Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setRights({ ...props.rights, synchronization: 50 })}>50%</Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setRights({ ...props.rights, synchronization: remaining.synchronizationRights })} disabled={remaining === undefined}>Remaining</Dropdown.Item>
                </DropdownButton>
            </InputGroup>
            <InputGroup className='mb-2'>
                <InputGroup.Text style={styles.inputGroupText}>Performance rights</InputGroup.Text>
                <Form.Control type='number' placeholder="Performance rights..." value={props.rights.performance} onChange={(e) => props.setRights({ ...props.rights, performance: e.target.value })} />
                <DropdownButton title="Select">
                    <Dropdown.Item onClick={() => props.setRights({ ...props.rights, performance: 25 })}>25%</Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setRights({ ...props.rights, performance: 33.33 })}>33.33%</Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setRights({ ...props.rights, performance: 50 })}>50%</Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setRights({ ...props.rights, performance: remaining.performanceRights })} disabled={remaining === undefined}>Remaining</Dropdown.Item>
                </DropdownButton>
            </InputGroup>
            <InputGroup className='mb-2'>
                <InputGroup.Text style={styles.inputGroupText}>Reproduction rights</InputGroup.Text>
                <Form.Control type='number' placeholder="Reproduciton rights..." value={props.rights.reproduction} onChange={(e) => props.setRights({ ...props.rights, reproduction: e.target.value })} />
                <DropdownButton title="Select">
                    <Dropdown.Item onClick={() => props.setRights({ ...props.rights, reproduction: 25 })}>25%</Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setRights({ ...props.rights, reproduction: 33.33 })}>33.33%</Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setRights({ ...props.rights, reproduction: 50 })}>50%</Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setRights({ ...props.rights, reproduction: remaining.reproductionRights })} disabled={remaining === undefined}>Remaining</Dropdown.Item>
                </DropdownButton>
            </InputGroup>
        </div>
    )
}

/**
 * Function for creating the JSX elements that will display the modal
 * for new splits to the user.
 */
export default function AddSplit(props) {
    const [company, setCompany] = useState(undefined);
    const [message, setMessage] = useState(undefined);
    const [rights, setRights] = useState({ synchronization: '', performance: '', reproduction: '' });
    const user = getUser();
    let navigate = useNavigate();

    let { trackId } = useParams();

    /**
     * Requests the information for the company and saves it in the state
     * @param {string} companyId ID of the selected company
     */
    function companyClickedHandler(companyId) {
        axios.get('company', {
            params: {
                companyId: companyId
            }
        }).then(response => {
            setCompany(response.data);
        }).catch(err => {
            setCompany(undefined);
            console.log(err);
        });
    }

    /**
     * Function saves the information of the splits that the user entered
     * to the database.
     */
    function saveSplitHandler() {
        if (company === undefined) {
            setMessage('Before a split can be saved, a company must be specified!');
            return;
        }

        for (const [key, value] of Object.entries(rights)) {
            if (Number.isNaN(Number(value)) || value === '') {
                setMessage('The values for the splits must be filled completely!');
                return;
            }
        }

        // Reset user error
        setMessage(undefined);

        const data = {
            track: trackId,
            companyId: company.id,
            synchronizationRights: rights.synchronization,
            performanceRights: rights.performance,
            reproductionRights: rights.reproduction
        }

        axios.post('catalogue/recording/split', data).then(repsonse => {
            props.callbackFunction();
        }).catch(err => {
            setMessage('The new split for the recording rights could not be added to the database. Please check if enough remaining splits are available!');
            console.log(err);
        });
    }

    return (
        <div style={props.style}>
            <h2>Search for a company</h2>
            <CompanyTable link='company/search' callbackFunction={companyClickedHandler} />

            <h2>Selected Company</h2>
            <SelectedCompany company={company} />

            <h2>Rights</h2>
            <RightsInput rights={rights} setRights={setRights} />

            <h2>Save the split</h2>
            <p>
                When saving the split, the entered data will be sent
                to the server, where the new split will be created.
            </p>
            {message ? <Alert variant='danger'>{message}</Alert> : null}
            <Button variant='success' style={styles.fullWidth} onClick={saveSplitHandler}>Save new split</Button>
        </div>
    )
}

AddSplit.propTypes = {
    /**
     * Style attributes for the AddSplit component
     */
    style: PropTypes.object
}