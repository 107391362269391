import React, { useState, useEffect, useReducer, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import AppModal from '../Modal/Modal';
import CompanyTable from '../CompanyTable/CompanyTable';
import axios from '../../utils/axiosInstance';
import PropTypes from 'prop-types';

/**
 * Initial state of the modal.
 */
const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

/**
 * Initial state of the component
 */
const INITIAL_STATE = {
  trackDetails: {
    track: null,
    subTitle: '',
    splitsConfirmed: false,
    songstatsNum: '',
    displayArtists: '',
    title: '',
    releaseDate: '',
    payingParty: '',
    payingPartyName: '',
    createdAt: '',
    updatedAt: ''
  }
}

/**
 * Elements specify the input fields that are shown to the user.
 */
const ELEMENTS = [
  ['track', { type: 'input', label: 'Track', editable: false }],
  ['title', { type: 'input', label: 'Title', editable: true }],
  ['subTitle', { type: 'input', label: 'Subtitle', editable: true }],
  ['displayArtists', { type: 'input', label: 'Artists', editable: true }],
  ['releaseDate', { type: 'input', label: 'Release Date', editable: false }],
  ['durationMs', { type: 'number', label: 'Duration [ms]', editable: true }],
  ['explicit', { type: 'bool', label: 'Explicit', editable: true }],
  ['splitsConfirmed', { type: 'bool', label: 'Splits confirmed', editable: true }],
  ['payingParty', { type: 'payingParty', label: 'Paying Party', editable: false }],
  ['payingPartyName', { type: 'input', label: 'Paying Party', editable: false }],
  ['artwork', { type: 'input', label: 'Artwork', editable: true }],
  ['songstats', { type: 'input', label: 'Songstats', editable: false }],
  ['createdAt', { type: 'time', label: 'Created at', editable: false }],
  ['updatedAt', { type: 'time', label: 'Updated at', editable: false }]
]


/**
 * Modifies the current state of the component and returns the new state.
 * @param {object} state Current state of the component
 * @param {object} action Action that specifies how the current state should be modified
 * @returns New state of the component.
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'load':
      return { ...state, trackDetails: action.payload }
    case 'reset':
      return INITIAL_STATE;
    case 'updateTrackDetails':
      return { ...state, trackDetails: { ...state.trackDetails, [action.key]: action.payload } }
    default:
      throw new Error(`Action type ${action.type} is no part of reducer in trackdetails.jsx`);
  }
}

/**
 * Function creates the JSX elements for displaying the track details
 * to the user. With this elements, the user can then change the values
 * for the specific fields.
 * @returns JSX.Elements for displaying the track details
 */
export default function TrackDetails(props) {
  const itemSettings = new Map(ELEMENTS);
  const { trackId } = useParams();
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const elements = useMemo(() => createComponents(), [state.trackDetails]);

  /**
   * Gets executed every time the ID of the track changes
   */
  useEffect(() => {
    populateTrackDetailsData();
  }, [trackId]);

  /**
   * Function loads the track details from the database
   */
  function populateTrackDetailsData() {
    axios.get('catalogue/recording/detail', {
      params: {
        track: trackId
      }
    }).then(response => {
      dispatch({ type: 'load', payload: response.data });
    }).catch(err => {
      console.log(err);
      dispatch({ type: 'reset' });
    });
  }

  /**
   * Shows the modal for selecting the paying party for the recording rights.
   */
  function showAppModalPayingPartyRecording() {
    setModal({
      show: true,
      title: 'Select paying party for recording rights',
      body: <CompanyTable link='company/search' callbackFunction={payingPartyRecordingRightsClickedHandler} />
    });
  }

  /**
   * Changes the paying party for the recording rights to the
   * company that was selected in the modal
   * @param {number} companyId ID of the company
   */
  function payingPartyRecordingRightsClickedHandler(companyId) {
    axios.get('company', {
      params: {
        companyId: companyId
      }
    }).then(response => {
      dispatch({ type: 'updateTrackDetails', key: 'payingParty', payload: response.data.id });
      dispatch({ type: 'updateTrackDetails', key: 'payingPartyName', payload: response.data.name });
      setModal(INITIAL_MODAL_STATE);
    }).catch(err => {
      console.log(err);
    });
  }

  /**
   * Maps the input fields for the track details to the data from the backend
   * @returns JSX.Elements for displaying the input fields for the track details
   */
  function createComponents() {
    const items = [];
    itemSettings.forEach((item, key) => {
      switch (item.type) {
        case 'input':
          items.push(
            <InputGroup key={items.length} className='mb-2'>
              <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>{item.label}</InputGroup.Text>
              <Form.Control
                placeholder={item.label + '...'}
                aria-label={item.label}
                value={state.trackDetails[key] ? state.trackDetails[key] : ''}
                readOnly={!item.editable}
                onChange={(e) => dispatch({ type: 'updateTrackDetails', key: key, payload: e.target.value })}
              />
            </InputGroup>
          );
          break;
        case 'bool':
          items.push(
            <InputGroup key={items.length} className='mb-2'>
              <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>{item.label}</InputGroup.Text>
              <Form.Control value={state.trackDetails[key] ? 'Yes' : 'No'} readOnly={true} />
              <DropdownButton variant='outline-secondary' title='Select' id={key} disabled={!item.editable}>
                <Dropdown.Item onClick={() => dispatch({ type: 'updateTrackDetails', key: key, payload: true })}>True</Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch({ type: 'updateTrackDetails', key: key, payload: false })}>False</Dropdown.Item>
              </DropdownButton>
            </InputGroup>
          );
          break;
        case 'payingParty':
          items.push(
            <InputGroup key={items.length} className='mb-2'>
              <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>{item.label}</InputGroup.Text>
              <Form.Control
                placeholder={item.label + '...'}
                aria-label={item.label}
                value={state.trackDetails[key] ? state.trackDetails[key] : ''}
                readOnly={!item.editable}
                onChange={(e) => dispatch({ type: 'updateTrackDetails', key: key, payload: e.target.value })}
              />
              <Button variant='outline-secondary' onClick={showAppModalPayingPartyRecording}>Select</Button>
            </InputGroup>
          );
          break;
        case 'time':
          items.push(
            <InputGroup key={items.length} className={'mb-2'}>
              <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>{item.label}</InputGroup.Text>
              <Form.Control
                placeholder={item.label + '...'}
                value={state.trackDetails[key] ? new Date(state.trackDetails[key]).toLocaleString() : ''}
                readOnly={!item.editable}
                onChange={(e) => dispatch({ type: 'updateTrackDetails', key: key, payload: e.target.value })}
              />
            </InputGroup>
          )
          break;
        case 'number':
          items.push(
            <InputGroup key={items.length} className='mb-2'>
              <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>{item.label}</InputGroup.Text>
              <Form.Control
                type='number'
                placeholder={item.label + '...'}
                aria-label={item.label}
                value={state.trackDetails[key] ? state.trackDetails[key] : ''}
                readOnly={!item.editable}
                onChange={(e) => dispatch({ type: 'updateTrackDetails', key: key, payload: e.target.value })}
              />
            </InputGroup>
          );
          break;
        default:
          throw new Error(`Item type ${item.type} is no part of switch in function TrackDetails.createComponents`);
      }
    })
    return items;
  }

  /**
   * Saves the changes in the track details to the database.
   */
  function saveChangesHandler() {
    const data = {
      track: state.trackDetails.track,
      displayArtists: state.trackDetails.displayArtists,
      title: state.trackDetails.title,
      subTitle: state.trackDetails.subTitle || null,
      payingParty: state.trackDetails.payingParty,
      artwork: state.trackDetails.artwork || null,
      splitsConfirmed: state.trackDetails.splitsConfirmed,
      explicit: state.trackDetails.explicit,
      durationMs: state.trackDetails.durationMs || null
    }

    axios.put('catalogue/recording/detail', data).then(response => {
      dispatch({ type: 'load', payload: response.data });
      setModal({
        show: true,
        title: 'Update successfull',
        body: <p>The information of the track were successfully updated.</p>
      });
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <div style={props.style} className={'mb-4'}>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} size='xl' />
      {elements}
      <Button variant='success' style={{ 'width': '100%' }} onClick={saveChangesHandler}>Save</Button>
    </div>
  )
}

TrackDetails.propTypes = {
  /**
   * Style attributes for the track details
   */
  style: PropTypes.object
}