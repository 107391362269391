import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FloatingLabel, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import AppModal from "../../components/Modal/Modal";
import { getUser } from "../../utils/requireAuth";
import axios from '../../utils/axiosInstance';
import { useEffect } from "react";
import './Authentication.css';

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

export default function SignIn() {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    let navigate = useNavigate();
    const user = getUser();

    useEffect(() => {

        if (user) {
            navigate('/');
        }
    }, [user]);

    function signInHandler(e) {
        e.preventDefault();
        const data = { username: username, password: password }
        axios.post('account/signin', data).then(response => {
            localStorage.setItem('user', JSON.stringify(response.data));
            navigate('/');
        }).catch(err => {
            setModal({
                show: true,
                title: 'Signin failed',
                body: (
                    <p>
                        The signin into the AdminUI failed. Please try again.
                    </p>
                )
            });
            console.log(err);
        });
    }

    return (
        <div>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <div className="AuthenticationCard">
                <div className="Center">
                    <h1>Login</h1>
                </div>
                <Form>
                    <FloatingLabel className={'mb-2'} label={'Username'}>
                        <Form.Control value={username} onChange={(e) => setUsername(e.target.value)} placeholder={'Username...'} />
                    </FloatingLabel>
                    <FloatingLabel className={'mb-2'} label={'Password'}>
                        <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} placeholder={'Password'} type={'password'} />
                    </FloatingLabel>
                    <Button className='ButtonWidth100' variant='success' type='submit' onClick={signInHandler}>Sign in</Button>
                </Form>
                <div className={'mt-2'}>
                    <Link to='/signup' className={'AuthenticationSwitchLink'}>Don't have an account yet?</Link>
                </div>
            </div>
        </div>
    )
}