import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import helga from '../assets/images/helga-bg.svg';
import './Layout.css';

const styles = {
  background: {
    // backgroundImage: `url(${helga})`,
    height: '100vh',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }
}

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavMenu />
        <Container className='LayoutPaddingTop'>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
