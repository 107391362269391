/**
 * Composition catalogue home
 * In here, the company can be searched and will be selected in order
 * to then request the composition catalogue.
 */
import React from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import CompanyTable from "components/CompanyTable/CompanyTable";

/**
 * Displays the company table to search for a company for which the composition
 * catalogue should be displayed.
 * @returns JSX.Elements for displaying the page for selecting a company
 * in the composition catalogue
 */
export default function PublishingHome() {
    let navigate = useNavigate();

    /**
     * Navigates the user to the track catalogue of a specific company.
     * @param {number} companyId Id of the company
     */
    function companyClicked(companyId) {
        navigate(`company-${companyId}`);
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item href="/customers">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/customers/catalogue/publishing" active={true}>Publishing</Breadcrumb.Item>
            </Breadcrumb>
            <h1>Publishing Home</h1>
            <p>
                In publishing home you have the possibility to search for
                existing companies and get all compositions of this company
                by clicking on the respective company
            </p>
            <CompanyTable link="company/search" callbackFunction={companyClicked} />
        </div>
    )
}