import React, { useState, useEffect } from 'react';
import { Button, InputGroup, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'utils/axiosInstance';
import AppModal from 'components/Modal/Modal';

/**
 * Initial state of the modal
 * */
const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

/**
 * Style attributes for the page
 * */
const styles = {
  UserEmailGroupTextWidth: {
    width: "20%",
    minWidth: "150px"
  },
  width100: {
    width: "100%"
  }
}

export default function UserEmail({ userId }) {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [userData, setUserData] = useState({ userId: null, email: null });

  /**
   * Gets executed when the page gets loaded. Fetches the information
   * for the user from firebase
   */
  useEffect(() => {
    populateUserEmailData(userId);
  }, [userId]);


  /**
   * Loads the data for the mail from firebase
   * @param {number} user
   */
  function populateUserEmailData(user) {
    axios.get('user/mail', {
      params: { userId: user },
    }).then(response => {
      setUserData(response.data);
    }).catch(err => {
      setUserData({ userId: null, email: null });
    });
  }

  /**
   * Changes the email for the user in the firebase authentication service
   * */
  function updateUserEmailHandler() {
    if (userData.userId === null || userData.email === null || userData.email.length === 0) {
      return;
    }

    const data = {
      userId: userId,
      email: userData.email
    }

    axios.post('user/mail/change', data).then(response => {
      setUserData(response.data);
      setModal({
        show: true,
        title: 'Update successfull',
        body: <p>The Update was successfull. The email of the user was updated.</p>
      });
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Update failed',
        body: <p>The update of the email of the user could not be executed successfully.</p>
      });
    });
  }

  return (
    <div className={'mb-4'}>
      <p>
        The user e-mail is saved within the firebase authentication service and can be updated
        in this field. Please note that changes in this mail immediately affect the login of the user.
      </p>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
      <InputGroup className='mb-2'>
        <InputGroup.Text style={styles.UserEmailGroupTextWidth}>User Mail</InputGroup.Text>
        <Form.Control disabled={userData.userId === null || userData.email === null} placeholder="User mail..." value={userData.email || ''} onChange={(e) => setUserData({ ...userData, email: e.target.value })} />
      </InputGroup>
      <Button
        variant={userData.userId !== null && userData.email !== null ? 'success' : 'secondary'}
        className={'w-100'}
        onClick={updateUserEmailHandler}>Update User Mail</Button>
    </div>
  )
}


UserEmail.propTypes = {
  /**
   * ID of the user for which the user mail should be loaded
   */
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}