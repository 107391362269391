import React, { useState, useEffect, useMemo } from 'react';
import { Table, InputGroup, Button, Form, Breadcrumb } from 'react-bootstrap';

import AppModal from '../Modal/Modal';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';


const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const styles = {
    alignCenter: {
        textAlign: 'center'
    }
}

const StatusEditModal = ({ statusId, callbackFunction }) => {
    const [status, setStatus] = useState('');
    const user = getUser();

    useEffect(() => {
        requeststatus();
    }, []);

    function requeststatus() {
        const token = user ? user.token : '';
        axios.get(`/notifications/status/${statusId}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                const data = response.data;
                setStatus(data?.status || '');
            }
        }).catch(err => {
            console.warn(err);
            setStatus('');
        })
    }

    function setStatusHandler(value) {
        if (value.length <= 50) {
            setStatus(value);
        }
    }

    return (
        <div>
            <p>
                The status can be renamed
            </p>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Status</InputGroup.Text>
                <Form.Control
                    placeholder={'Status...'}
                    value={status}
                    onChange={(e) => setStatusHandler(e.target.value)} />
            </InputGroup>

            <Button variant={'success'} onClick={() => callbackFunction({ statusId, status })}>Update changes</Button>
        </div>
    )
}

const NotificationStatus = () => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [search, setSearch] = useState('');
    const [statuses, setStatuses] = useState([]);
    const tableRows = useMemo(() => createTableRows(statuses), [statuses])

    const user = getUser();

    useEffect(() => {
        populateStatusData();
    }, [search]);

    function populateStatusData() {
        const token = user ? user.token : '';
        axios.get('/notifications/status', {
            params: {
                search: search
            },
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                setStatuses(response.data);
            }
        }).catch(err => {
            console.warn(err);
            setStatuses([]);
        })
    }

    function showEditModal(statusId) {
        setModal({
            show: true,
            title: 'Edit call to status',
            body: <StatusEditModal statusId={statusId} callbackFunction={updateStatusHandler} />
        });
    }

    function updateStatusHandler({ statusId, status }) {
        const token = user ? user.token : '';
        const data = {
            id: statusId,
            status: status
        }
        axios.put('notifications/status', data, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                setModal(INITIAL_MODAL_STATE);
                populateStatusData();
            }
        }).catch(err => {
            console.warn(err);
            setModal({
                show: true,
                title: 'Failed to update status',
                body: <p>
                    The update of the status failed because of an error: {err?.response?.data?.message || err.message}
                </p>
            });
        })
    }

    function setSearchHandler(value) {
        if (value.length <= 50) {
            setSearch(value);
        }
    }

    function createStatus() {
        const token = user ? user.token : '';
        const data = {
            status: search
        }
        axios.post('/notifications/status', data, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 201) {
                setSearch('');
            }
        }).catch(err => {
            console.warn(err);
            setModal({
                show: true,
                title: 'Failed to create status',
                body: <p>
                    The status could not be created because of an error: {err?.response?.data?.message || err.message}
                </p>
            })
        })
    }

    function createTableRows(data) {
        if (!data || data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={3}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx}>
                <td>{item.id}</td>
                <td>{item.status}</td>
                <td>
                    <Button size={'sm'} variant={'primary'} onClick={() => showEditModal(item.id)}>Edit</Button>
                </td>
            </tr>
        })
    }

    return (
        <div>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <Breadcrumb>
                <Breadcrumb.Item href={'/metadata'}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={'/metadata/notificationstatus'} active={true}>Notification Status</Breadcrumb.Item>
            </Breadcrumb>
            <h3>Status</h3>
            <p>
                This tells the user the current situation of the alert. Are we working on it or
                is the problem already solved?
            </p>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Searchtext</InputGroup.Text>
                <Form.Control
                    placeholder={'Name of the status'}
                    value={search}
                    onChange={(e) => setSearchHandler(e.target.value)} />
                <Button variant={search.length === 0 ? 'secondary' : 'success'} onClick={createStatus}>New</Button>
            </InputGroup>
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
        </div>
    )
}

export default NotificationStatus;
