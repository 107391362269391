import React from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Breadcrumb } from "react-bootstrap";
import CompanyTable from "../../../components/CompanyTable/CompanyTable";

export default function Home() {
    let navigate = useNavigate();

    /**
     * Redirects the user to the page where the details for the company can
     * be displayed.
     * @param {number} companyId Id of the company that was clicked in the table
     */
    function companyClicked(companyId) {
        navigate(`${companyId}`);
    }

    function openNewCompanyModal() {
        navigate('new');
    }

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item href={'/customers'}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={'/customers/company'} active={true}>Company</Breadcrumb.Item>
            </Breadcrumb>
            <h1>Company Home</h1>
            <p>
                In company home you have the possibility to search for
                existing companies and get all the information about it
                by clicking on the respective row in the table.
            </p>
            <CompanyTable
                link="company/search"
                callbackFunction={companyClicked} />

            <h2>New company</h2>
            <p>
                Nothing found? You can also create a new company
            </p>
            <Button variant={'primary'} onClick={openNewCompanyModal}>Create new</Button>
        </div>
    );
}
