import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Col, Row, Breadcrumb } from 'react-bootstrap';
import AppModal from 'components/Modal/Modal';
import UserDetails from 'components/UserDetails/UserDetails';
import axios from 'utils/axiosInstance';
import UserCompanies from 'components/UserCompanies/UserCompanies';
import FirestoreSpotifyLinks from 'components/FirestoreSpotifyLinks/FirestoreSpotifyLinks';
import UserDetailsToggleElements from 'components/UserDetailsToggleElements/UserDetailsToggleElements';
import UserEmail from 'components/UserEmail/UserEmail';

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const Details = () => {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [user, setUser] = useState(undefined);

  const { userId } = useParams();

  let navigate = useNavigate();

  useEffect(() => {
    axios.get('user', {
      params: { userId: userId }
    }).then(response => {
      setUser(response.data);
    }).catch(err => {
      console.log(err);
    })
  }, [userId]);

  const showDeleteUserModal = () => {
    setModal({
      show: true,
      title: `Delete user${user && ` ${user.name}`}`,
      body: (
        <div>
          <p>
            Do you really want to delete the user? After deleting the user, all
            information will be gone permanently.
          </p>
          <Row>
            <Col>
              <Button className={'w-100'} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancle</Button>
            </Col>
            <Col>
              <Button className={'w-100'} variant={'danger'} onClick={() => deleteUserHandler(userId)}>Delete</Button>
            </Col>
          </Row>
        </div>
      )
    })
  }

  function deleteUserHandler(userId) {
    axios.delete('user', {
      params: { userId: userId }
    }).then(response => {
      if (response.status === 204) {
        setModal(INITIAL_MODAL_STATE);
        navigate('/customers/users');
      }
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Failed to delete the user',
        body: <p>The user could not be deleted. Please make sure that all information are already removed.</p>
      });
    });
  }

  return (
    <div>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />

      <Breadcrumb>
        <Breadcrumb.Item href={'/customers'}>Home</Breadcrumb.Item>
        <Breadcrumb.Item href={'/customers/users'}>Users</Breadcrumb.Item>
        <Breadcrumb.Item href={`/customers/users/${userId}`} active={true}>{user?.name || userId}</Breadcrumb.Item>
      </Breadcrumb>

      <h1>Details for user {user ? user.name || user.id : ''}</h1>
      <UserDetails userId={userId} />

      <h2>User account</h2>
      <p>
        In user account, the mapping between the user in the HELGA database and the
        user in the authentication system of HELGA can be done.
      </p>
      <Button className={'mb-4'} variant={'primary'} onClick={() => navigate('account')}>Edit User Account</Button>

      <h2>User Email</h2>
      <UserEmail userId={userId} />

      <h2>User Companies</h2>
      <UserCompanies userId={userId} />

      <h2>Spotify Links</h2>
      <FirestoreSpotifyLinks userId={userId} />

      <hr />
      <h2>Danger Zone</h2>
      <UserDetailsToggleElements userId={userId} />

      <h3>Delete User</h3>
      <p>
        The user can be deleted when no company assignments exist. When the user
        of an verified account will be deleted, the verification status will be
        set to not verified
      </p>
      <Button variant={'danger'} onClick={showDeleteUserModal}>Delete</Button>
    </div>
  )
}

export default Details;
