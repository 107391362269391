import React, { useEffect, useState, useMemo } from 'react';
import { Form, InputGroup, Button, Table, Row, Col, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AppModal from '../Modal/Modal';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';

const styles = {
    fullWidth: { width: '100%' },
    alignCenter: { textAlign: 'center' },
    pointer: { cursor: 'pointer' },
    marginTop: { marginTop: '1rem' },
    maxHeight: {
        maxHeight: '60vh',
        overflowY: 'auto',
        scrollY: 'auto'
    }
}

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}


const SourceSystemModal = ({ callbackFunction }) => {
    const [systemSearch, setSystemSearch] = useState('');
    const [rightSearch, setRightSearch] = useState('');
    const [systems, setSystems] = useState([]);
    const [rights, setRights] = useState([]);

    const [selectedSystem, setSelectedSystem] = useState(undefined);
    const [selectedRight, setSelectedRight] = useState(undefined);

    const systemTableRows = useMemo(() => createSystemTableRows(systems), [systems]);
    const rightTableRows = useMemo(() => createRightTableRows(rights), [rights]);

    const user = getUser();

    useEffect(() => {
        populateSystemsData();
    }, [systemSearch]);

    useEffect(() => {
        populateRightsData();
    }, [rightSearch]);

    function populateSystemsData() {
        const token = user ? user.token : '';
        axios.get('source/system', {
            params: {
                search: systemSearch
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 200) {
                setSystems(response.data);
            }
        }).catch(err => {
            console.error(err);
            setSystems([]);
        });
    }

    function populateRightsData() {
        const token = user ? user.token : '';
        axios.get('options/rightclassification', {
            params: {
                search: rightSearch
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(repsonse => {
            if (repsonse.status === 200) {
                setRights(repsonse.data);
            }
        }).catch(err => {
            console.error(err);
            setRights([]);
        })
    }

    function addSystem() {
        if (systemSearch.length === 0) {
            return;
        }

        const token = user ? user.token : '';
        const data = {
            system: systemSearch
        }
        axios.post('source/system/create', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 201) {
                populateSystemsData();
                setSelectedSystem(response.data);
            }
        }).catch(err => {
            console.error(err);
        });
    }

    function createSystemTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={3}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx} onClick={() => setSelectedSystem(item)} style={styles.pointer}>
                <td>{item.id}</td>
                <td>{item.system}</td>
            </tr>
        });
    }

    function createRightTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={2}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx} onClick={() => setSelectedRight(item)} style={styles.pointer}>
                <td>{item.id}</td>
                <td>{item.right}</td>
            </tr>
        })
    }

    function saveNewSourceSystem() {
        if (selectedSystem === undefined || selectedRight === undefined) {
            return;
        }

        const data = {
            system: selectedSystem.id,
            right: selectedRight.id
        }
        callbackFunction(data);
    }

    return (
        <div style={styles.maxHeight}>
            <h5>Select used system by source</h5>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Search System</InputGroup.Text>
                <Form.Control
                    placeholder={'Search for system...'}
                    value={systemSearch}
                    onChange={(e) => setSystemSearch(e.target.value)} />
                <Button variant={systemSearch.length > 0 ? 'success' : 'secondary'} onClick={() => addSystem()}>Add as new system</Button>
            </InputGroup>
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {systemTableRows}
                </tbody>
            </Table>

            {
                selectedSystem &&
                <Alert variant='primary'>
                    <span><strong>Selected System</strong></span>
                    <ul>
                        <li>System-ID: {selectedSystem.id}</li>
                        <li>System-Name: {selectedSystem.system}</li>
                    </ul>
                </Alert>
            }

            <h5>Select covering right</h5>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Search Right</InputGroup.Text>
                <Form.Control
                    placeholder={'Search for right...'}
                    value={rightSearch}
                    onChange={(e) => setRightSearch(e.target.value)} />
            </InputGroup>
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {rightTableRows}
                </tbody>
            </Table>

            {
                selectedRight &&
                <Alert variant='primary'>
                    <span><strong>Selected Right</strong></span>
                    <ul>
                        <li>Right-ID: {selectedRight.id}</li>
                        <li>Right-Name: {selectedRight.right}</li>
                    </ul>
                </Alert>
            }

            <Button variant={selectedRight !== undefined && selectedSystem !== undefined ? 'success' : 'secondary'} onClick={saveNewSourceSystem}>Save new source system</Button>
        </div>
    )
}

SourceSystemModal.propTypes = {
    /**
     * Function that gets called when user clicks on add system to source.
     * It is expected that the function takes the ID of the system as an input
     */
    callbackFunction: PropTypes.func.isRequired
}

const SourceSystem = ({ sourceId }) => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [systems, setSystems] = useState([]);
    const tableRows = useMemo(() => createTableRows(systems), [systems]);
    const user = getUser();

    useEffect(() => {
        populateSystemsData();
    }, []);

    function populateSystemsData() {
        const token = user ? user.token : '';
        axios.get(`source/system/${sourceId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 200) {
                setSystems(response.data);
            }
        }).catch(err => {
            console.error(err);
            setSystems([]);
        })
    }

    function deleteSystemModal({ source, right, system }) {
        setModal({
            show: true,
            title: 'Delete used system by source',
            body: (
                <div>
                    <p>
                        Do you really want to delete the used system by the source?
                        Please keep in mind that this action cannot be reversed.
                    </p>
                    <Row>
                        <Col>
                            <Button style={styles.fullWidth} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button style={styles.fullWidth} variant={'danger'} onClick={() => deleteSystem({ source, right, system })}>Delete</Button>
                        </Col>
                    </Row>
                </div>
            )
        })
    }

    function deleteSystem({ source, right, system }) {
        const token = user ? user.token : '';
        axios.delete('source/system', {
            params: {
                source: source,
                right: right,
                system: system
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 204) {
                populateSystemsData();
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            populateSystemsData();
            setModal({
                show: true,
                title: 'Failed to delete system',
                body: <p>
                    The used system by source could not be deleted because of an error: {err?.response?.data?.message || err.message}
                </p>
            });
        })
    }

    function createTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={3}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx}>
                <td>{item.rightName}</td>
                <td>{item.systemName}</td>
                <td><Button size={'sm'} variant={'outline-danger'} onClick={() => deleteSystemModal(item)}>Delete</Button></td>
            </tr>
        })
    }

    function addSourceSystemModal() {
        setModal({
            show: true,
            title: 'Select used system',
            body: <SourceSystemModal callbackFunction={addSourceSystemHandler} />
        })
    }

    function addSourceSystemHandler({ system, right }) {
        if (system === undefined || right === undefined) {
            return;
        }

        const token = user ? user.token : '';
        const data = {
            source: sourceId,
            system: system,
            right: right
        }

        axios.post('source/system/assign', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 201) {
                populateSystemsData();
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            console.error(err);
            setModal({
                show: true,
                title: 'Failed to assign the system',
                body: <p>
                    The system could not successfully be assigned to the source because of an error: {err?.response?.data?.message || err.message}
                </p>
            })
        })

    }

    return (
        <div className='mb-4'>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />

            <h2>Used system in the source</h2>
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>Right</th>
                        <th>System</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
            <Button variant={'success'} onClick={() => addSourceSystemModal()}>New Source System</Button>
        </div>
    )
}

SourceSystem.propTypes = {
    /**
     * ID of the source
     */
    sourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default SourceSystem;
