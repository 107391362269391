import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { InputGroup, Form, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Paging from '../Paging/Paging';
import axios from '../../utils/axiosInstance';

const INITIAL_STATE = {
    tracks: [],
    startIndex: 0,
    totalCount: 0,
    pageNumber: 0,
    pageSize: 10
}

const styles = {
    pointer: { cursor: 'pointer' },
    alignCenter: { textAlign: 'center' }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'load':
            return {
                ...state,
                tracks: action.payload.items,
                pageNumber: action.payload.pageNumber,
                startIndex: action.payload.recordNumber,
                totalCount: action.payload.totalCount
            }
        case 'reset':
            return INITIAL_STATE
        case 'pageNumber':
            const maxPageNumber = Math.ceil(state.totalCount / state.pageSize);
            const pageNumber = Math.min(Math.max(action.payload, 1), maxPageNumber);
            return {
                ...state,
                pageNumber: pageNumber,
                startIndex: (pageNumber - 1) * state.pageSize
            }
        default:
            new Error(`Action type ${action.type} is no part of reducer in TrackTable.jsx`);
    }
}

export default function TrackTable(props) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [search, setSearch] = useState('');
    const tableRows = useMemo(() => createTableRows(state.tracks), [state.tracks]);

    useEffect(() => {
        populateTracksData();
    }, [state.pageNumber, state.pageSize, state.startIndex, search]);

    function populateTracksData() {
        // Check if company id is given
        if (props.company === null) {
            return
        }

        axios.get('catalogue/recording/search/all', {
            params: {
                startIndex: state.startIndex,
                pageSize: state.pageSize,
                search: search
            }
        }).then(response => {
            dispatch({ type: 'load', payload: response.data });
        }).catch(err => {
            console.log(err);
            dispatch({ type: 'reset' });
        })
    }

    function createTableRows(data) {
        if (data.length === 0) {
            return (
                <tr style={{ ...styles.pointer, ...styles.alignCenter }}>
                    <td colSpan={2}>No data available</td>
                </tr>
            )
        }

        return data.map((item, idx) => {
            return (
                <tr style={styles.pointer} key={idx} onClick={() => props.callbackFunction(item.track)}>
                    <td>{item.companyName}</td>
                    <td>{item.track}</td>
                    <td>{item.title}</td>
                </tr>
            )
        })
    }

    if (props.company === null || props.company === 0) {
        return (
            <>
                <h3>Please select a company first</h3>
                <p>
                    To display the tracks, a company must be selected. This company
                    needs to be selected for the payee.
                </p>
            </>
        )
    }

    return (
        <>
            <InputGroup className='mb-2'>
                <InputGroup.Text>Searchtext</InputGroup.Text>
                <Form.Control
                    placeholder='Searchtext'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} />
            </InputGroup>
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>Company</th>
                        <th>ID</th>
                        <th>Title</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
            <Paging currentPage={state.pageNumber} totalCount={state.totalCount} pageSize={state.pageSize} callbackFunction={(i) => dispatch({ type: 'pageNumber', payload: i })} />
        </>
    )
}

TrackTable.propTypes = {
    /**
     * Callback function that gets called after the track was clicked
     */
    callbackFunction: PropTypes.func.isRequired
}