import React, { useState, useEffect } from 'react';
import { FloatingLabel, Form, Button, Card, Alert, Breadcrumb, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ensureToken } from 'utils/requireAuth';

export default function PageHome({ elements, siteHeading }) {
  const [items, setItems] = useState(elements);
  const [search, setSearch] = useState('');

  useEffect(() => {
    ensureToken();
  }, []);

  useEffect(() => {
    const itemList = [];
    elements.forEach(element => {
      if (element.title.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
        itemList.push(element);
      }
    });
    setItems(itemList);
  }, [search]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Home</Breadcrumb.Item>
      </Breadcrumb>
      <h1>{siteHeading ? siteHeading : 'Home'}</h1>
      <FloatingLabel
        label={"🔍Searchtext"}
        className={'mb-3'}>
        <Form.Control type="text" placeholder="Searchtext" value={search} onChange={(e) => setSearch(e.target.value)} />
      </FloatingLabel>
      <hr />
      <Row className='mb-4'>
        {!!items &&
          items.map((item, idx) => {
            return (
              <Col key={idx} xs={12} sm={12} md={12} lg={6} className='mb-2'>
                <Card className={'h-100'}>
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>{item.message}</Card.Text>
                    <Button href={item.link} variant='primary'>Go to {item.title.toLowerCase()}</Button>
                  </Card.Body>
                </Card>
              </Col>
            )
          })
        }
      </Row>

      {items.length === 0 &&
        <Alert variant={'secondary'}>
          <Alert.Heading>What are you searching for?</Alert.Heading>
          <p>
            The element you were looking for was not found.
          </p>
        </Alert>
      }
    </>
  )
}

PageHome.propTypes = {
  /**
   * Elements is an array of individual objects consisting of the keys title, message and link.
   */
  elements: PropTypes.array.isRequired,
  /**
   * The site heading is the heading of the page home
   */
  siteHeading: PropTypes.string
}