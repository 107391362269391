import React, { useState } from 'react';
import UserCompaniesTable from "../UserCompaniesTable/UserCompaniesTable";
import AppModal from 'components/Modal/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import axios from 'utils/axiosInstance';

/**
 * Initial state of the modal
 */
const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

/**
 * Creates the JSX elements for managing the assigned companies to the user
 * @param {object} props Properties for the component
 * @returns JSX Elements for displaying the user companies to the user
 */
export default function UserCompanies({ userId }) {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  /**
   * Function makes a request at the backend for deleting an assignment of a company to the user
   * @param {number} assignedCompanyId ID of the assigned company that needs to be deleted
   * @returns True if the company was successfull deleted
   */
  async function deleteCompanyAssignment(assignedCompanyId) {
    try {
      await axios.delete('user/companies', {
        params: {
          userId: userId,
          companyid: assignedCompanyId
        }
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /**
   * Function makes a request at the backend for assigning a company to the user
   * @param {number} assignedCompanyId ID of the company that needs to be assigned
   * @returns True if the company was successfull assigned
   */
  async function addCompanyAssignments(assignedCompanyId) {
    const data = {
      userId: userId,
      companyId: assignedCompanyId
    }
    try {
      await axios.post('user/companies', data)
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /**
   * Shows the Modal for searching and assigning companies to the user
   */
  function showCompanySearchModal() {
    setModal({
      show: true,
      title: 'New company assignments',
      body: (
        <UserCompaniesTable
          userId={userId}
          actionType='add'
          callbackFunction={addCompanyAssignments}
          link='user/companies/notassigned'
          searchEnabled={true}
        />
      )
    });
  }

  return (
    <div className={'mb-4'}>
      <p>
        The associated user companies define which information will be shown when the user
        enters the application
      </p>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
      <UserCompaniesTable
        userId={userId}
        actionType='delete'
        callbackFunction={deleteCompanyAssignment}
        link='user/companies'
        searchEnabled={false}
        reloadTrigger={modal.show === false}
      />
      <Button variant='primary' onClick={showCompanySearchModal}>New Company Assignment</Button>
    </div>
  )
}

UserCompanies.propTypes = {
  /**
   * ID of the company for which the company users will be managed.
   */
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}