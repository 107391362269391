/* eslint-disable array-callback-return */
import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { Breadcrumb, Card, Alert, Badge, Form, FloatingLabel, Button, Stack } from 'react-bootstrap';
import AppModal from '../../../components/Modal/Modal';
import axios from 'utils/axiosInstance';


const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const INITIAL_STATE = {
  scheduledTasks: [],
  taskOptions: [],
  newTask: {
    taskId: 0,
    date: '',
    time: '',
    executedOnce: 1,
    confirmAutomaticScheduling: 0,
  }
}

const DATE_PATTERN = /^\d{4}-\d{2}-\d{2}$/;
const TIME_PATTERN = /^\d{2}:\d{2}$/;

const reducer = (state, action) => {
  switch (action.type) {
    case 'loadTasks':
      return { ...state, taskOptions: action.payload }
    case 'resetTasks':
      return { ...state, taskOptions: [] }
    case 'loadScheduledTasks':
      return { ...state, scheduledTasks: action.payload }
    case 'resetScheduledTasks':
      return { ...state, scheduledTasks: [] }
    case 'updateNewTask':
      return {
        ...state,
        newTask: {
          ...state.newTask,
          [action.key]: action.payload
        }
      }
    case 'resetNewTask':
      return {
        ...state,
        newTask: INITIAL_STATE.newTask
      }
    default:
      throw new Error(`Action type ${action.type} is no part of reducer in HelgaServiceTasks.jsx`);
  }
}

function getLocaleStringFromUTC(datetimeString) {
  const utcDate = new Date(datetimeString + 'Z');
  const localDateTimeString = utcDate.toLocaleString();
  return localDateTimeString;
}

const HelgaServiceTasks = () => {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const tasks = useMemo(() => createTaskCards(state.scheduledTasks), [state.scheduledTasks]);

  useEffect(() => {
    // Populate the availabel tasks
    populateHelgaServiceTaskData();
  }, []);

  useEffect(() => {
    /**
     * Calls the populate data function to request the
     * upcoming HELGA.service tasks from the api.
     */
    const executePopulateData = () => {
      populateHelgaServiceScheduledTasksData();
    }

    executePopulateData();

    // Refresh the HELGA.service tasks every 10 seconds
    const interval = setInterval(executePopulateData, 10000);

    // Reset interval again
    return () => clearInterval(interval);
  }, []);

  function populateHelgaServiceScheduledTasksData() {
    axios.get('/helgaservice/tasks/scheduled')
      .then(response => {
        if (response.status === 200)
          dispatch({ type: 'loadScheduledTasks', payload: response.data });
      }).catch(err => {
        console.log(err);
        dispatch({ type: 'resetScheduledTasks' })
      });
  }

  function populateHelgaServiceTaskData() {
    axios.get('/helgaservice/tasks')
      .then(response => {
        if (response.status === 200)
          dispatch({ type: 'loadTasks', payload: response.data });
      }).catch(err => {
        console.log(err);
        dispatch({ type: 'resetTasks' });
      });
  }

  function createTaskCards(data) {
    if (data.length === 0) {
      return <Alert variant={'secondary'}>
        Currently there are no tasks available. Come back later or create your first task.
      </Alert>
    }

    return data.map((item, idx) => {
      return <Card key={idx} className={'mb-2'}>
        <Card.Body>
        <Card.Title>{item.task}</Card.Title>
          <Card.Text>{item.taskDescription}</Card.Text>
        </Card.Body>
        <Card.Footer>
          <Stack direction={'horizontal'} gap={2}>
            <Badge bg={'dark'}>{getLocaleStringFromUTC(item.targetStartDate)}</Badge>
            <Badge bg={'dark'}>{item.executedOnce ? 'One time task' : 'Repetitive Task'}</Badge>
          </Stack>
        </Card.Footer>
      </Card>
    })
  }

  function createTaskClickedHandler() {
    if (state.newTask.taskId === 0) {
      setModal({
        show: true,
        title: 'Incomplete Values',
        body: <p>You have to select one of the {state.taskOptions.length} available task which you want to create.</p>
      });
      return false;
    }

    const isDateValid = DATE_PATTERN.test(state.newTask.date);
    if (!isDateValid) {
      setModal({ show: true, title: 'Incomplete Values', body: <p>Select a date to proceed</p> });
      return false;
    }

    const isTimeValid = TIME_PATTERN.test(state.newTask.time);
    if (!isTimeValid) {
      setModal({ show: true, title: 'Incomplete Values', body: <p>Select a time to proceed</p>});
      return false;
    }

    const dateTimeStr = `${state.newTask.date}T${state.newTask.time}:00`;
    const localDateTime = new Date(dateTimeStr);

    if (state.newTask.executedOnce === 0 && state.newTask.confirmAutomaticScheduling === 0) {
      setModal({
        show: true,
        title: 'Incomplete Values',
        body: <p>
          You have to confirm that you really want to activate the automatic task
          scheduling which continuously repeats executing the selected Task
        </p>
      });

      return false;
    }

    const data = {
      taskId: state.newTask.taskId,
      targetStartDate: localDateTime,
      executedOnce: Boolean(state.newTask.executedOnce)
    }

    axios.post('/helgaservice/tasks/scheduled', data)
      .then(response => {
        if (response.status === 200) {
          dispatch({ type: 'resetNewTask' });
          populateHelgaServiceScheduledTasksData();
          setModal({
            show: true,
            title: 'Successfully created the task',
            body: <p>
              The task task was created successfully and will be executed as soon
              as the target start date was reached. Until then, you will find your
              task under the scheduled tasks on this page.
            </p>
          });
        }
      }).catch(err => {
        setModal({
          show: true,
          title: 'Failed to create the task',
          body: <p>
            It seems as if the task was not be able to be created due to an error: {err.message}
          </p>
        });
        return;
      })
  }

  return (
    <div>
      <AppModal
        {...modal}
        closeHandler={() => setModal({
          show: false,
          title: '',
          body: null
        })}
      />

      <Breadcrumb>
        <Breadcrumb.Item href={'/administration'}>Home</Breadcrumb.Item>
        <Breadcrumb.Item href={'/administration/helgaservicetasks'} active>HELGA.service Tasks</Breadcrumb.Item>
      </Breadcrumb>

      <h1>HELGA.service Tasks</h1>
      <p>
        On this site all tasks can found which are scheduled for HELGA.service.
        In addition it is possible schedule own tasks which will then be executed
        as soon as the target starting date is reached.
      </p>

      <h4>Schedule a new Task</h4>
      <Form>
        <FloatingLabel label="Task of Execution" className={'mb-2'}>
          <Form.Select value={state.newTask.taskId} onChange={(e) => dispatch({
            type: 'updateNewTask',
            key: 'taskId',
            payload: Number(e.target.value)
          })}>
            <option key={0}>Select the Task for Execution</option>
            {state.taskOptions.map((item, idx) => {
              return <option key={idx + 1} value={item.id}>{item.task}</option>
            })}
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel label="Date of Execution" className={'mb-2'}>
          <Form.Control type="date" value={state.newTask.date} onChange={(e) => dispatch({
            type: 'updateNewTask',
            key: 'date',
            payload: e.target.value
          })} />
        </FloatingLabel>
        <FloatingLabel label={'Time of Execution'} className={'mb-2'}>
          <Form.Control type="time" value={state.newTask.time} onChange={(e) => dispatch({
            type: 'updateNewTask',
            key: 'time',
            payload: e.target.value
          })} />
        </FloatingLabel>
        <FloatingLabel label={'Repetitive Task Scheduling'} className={'mb-2'}>
          <Form.Select value={state.newTask.executedOnce} onChange={(e) => dispatch({
            type: 'updateNewTask',
            key: 'executedOnce',
            payload: Number(e.target.value)
          })}>
            <option value={1}>No, execute only once</option>
            <option value={0}>Yes, activate repetitive task scheduling</option>
          </Form.Select>
        </FloatingLabel>
        {state.newTask.executedOnce === 0 &&
          <Form.Check
            label="Yes, I want to automatically reschedule the task in the predefined time interval every time the task was executed."
            checked={state.newTask.confirmAutomaticScheduling}
            className={'mb-2'}
            onChange={() => dispatch({
              type: 'updateNewTask',
              key: 'confirmAutomaticScheduling',
              payload: !state.newTask.confirmAutomaticScheduling
            })}
          />  
        }
        <Button variant={'success'} onClick={createTaskClickedHandler}>Create Task</Button>
      </Form>

      <hr />

      <h4>Scheduled Tasks</h4>
      {tasks}

    </div>
  )
}

export default HelgaServiceTasks;
