import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button, Row, Col } from 'react-bootstrap';
import AppModal from 'components/Modal/Modal';
import axios from 'utils/axiosInstance';
import PropTypes from 'prop-types';
import TrackTable from 'components/TrackTable/TrackTable';

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const styles = {
  center: { textAlign: 'center' },
  fullWidth: { width: '100%' },
  pointer: { cursor: 'pointer' }
}

const WorkTracks = ({ workId }) => {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [workTracks, setWorkTracks] = useState([]);
  const tableRows = useMemo(() => createTableRows(workTracks), [workTracks]);

  /**
   * Request the assigned work tracks
   */
  useEffect(() => {
    populateWorkTracksData(workId);
  }, [workId]);

  /**
   * Fetch the work track assignments from the database
   * @param {number} work The ID of the work
   */
  function populateWorkTracksData(work) {
    axios.get('catalogue/publishing/tracks', {
      params: {
        workId: work
      }
    }).then(response => {
      setWorkTracks(response.data);
    }).catch(err => {
      console.log(err);
    })
  }

  function createTableRows(data) {
    if (data.length === 0) {
      return <tr style={styles.center}>
        <td colSpan={3}>No data available</td>
      </tr>
    }

    return data.map((item, idx) => {
      return <tr key={idx}>
        <td>{item.track}</td>
        <td>{item.title}</td>
        <td><Button size={'sm'} variant={'outline-danger'} onClick={() => showDeleteWorkTrackModal(item.track)}>Delete</Button></td>
      </tr>
    })
  }

  function showDeleteWorkTrackModal(trackId) {
    setModal({
      show: true,
      title: 'Delete work track assignment',
      body: (
        <div>
          <p>
            Do you really want to delete the work track assignment?
          </p>
          <Row>
            <Col>
              <Button style={{ 'width': '100%' }} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancle</Button>
            </Col>
            <Col>
              <Button style={{ 'width': '100%' }} variant={'danger'} onClick={() => deleteWorkTrackHandler(trackId)}>Delete</Button>
            </Col>
          </Row>
        </div>
      )
    })
  }

  function deleteWorkTrackHandler(trackId) {
    axios.delete('catalogue/publishing/tracks', {
      params: {
        workId: workId,
        track: trackId
      }
    }).then(response => {
      if (response.status === 204) {
        populateWorkTracksData(workId);
        setModal(INITIAL_MODAL_STATE);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  function showAddWorkTrackModal() {
    setModal({
      show: true,
      title: 'Select track to map to the work',
      body: <TrackTable callbackFunction={trackClickedHandler} />
    })
  }

  function trackClickedHandler(trackId) {
    axios.post('catalogue/publishing/tracks', {
      work: workId,
      track: trackId
    }).then(response => {
      if (response.status === 201) {
        setModal(INITIAL_MODAL_STATE);
        populateWorkTracksData(workId);
      }
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Track could not be assigned',
        body: <p>The track could not be assigned to this work due to an error</p>
      });
    });
  }

  return (
    <div className="mb-4">
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
      <h5>Work Tracks</h5>
      <p>
        The assignment of tracks to a work allows the processing of publishing
        information and revenue data.
      </p>
      <Table bordered striped hover responsive>
        <thead>
          <tr>
            <th>Track</th>
            <th>Title</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </Table>
      <Button variant={'success'} onClick={showAddWorkTrackModal}>Add Track</Button>
    </div>
  )
}

WorkTracks.propTypes = {
  /**
   * The ID of the work
   */
  workId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default WorkTracks;