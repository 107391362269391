import React, { useEffect, useState } from 'react';
import { InputGroup, Button, Form } from 'react-bootstrap';
import axios from '../../utils/axiosInstance';
import AppModal from '../Modal/Modal';
import SourceTypeModal from './SourceTypeModal';

const INITIAL_STATE = {
    id: '',
    name: '',
    sourceType: '',
    sourceTypeId: ''
}

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const styles = {
    alignCenter: {
        textAlign: 'center'
    },
    descriptionWidth: {
        minWidth: '100px',
        width: '20%'
    },
    fullWidth: {
        width: '100%'
    }
}


const SourceInformation = ({ sourceId }) => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [sourceInformation, setSourceInformation] = useState(INITIAL_STATE);

    useEffect(() => {
        requestSourceInformation();
    }, []);

    function requestSourceInformation() {
        axios.get('source', {
            params: {
                sourceId: sourceId
            }
        }).then(response => {
            if (response.status === 200) {
                const data = response.data;
                setSourceInformation({
                    id: data.id,
                    name: data.name || '',
                    sourceType: data.sourceType || '',
                    sourceTypeId: data.sourceTypeId || null
                });
            }
        }).catch(err => {
            console.log(err);
        })
    }

    function setSourceType(sourceType) {
        if (sourceType === undefined || sourceType === null) {
            return;
        }

        setSourceInformation({
            ...sourceInformation,
            sourceType: sourceType.type,
            sourceTypeId: Number(sourceType.id)
        });
        setModal(INITIAL_MODAL_STATE);
    }

    function openSourceTypesModal() {
        setModal({
            show: true,
            title: 'Select Type of the Source',
            body: <SourceTypeModal callbackFunction={setSourceType} />
        })
    }

    function updateSourceInformationHandler() {
        const data = {
            id: sourceInformation.id,
            name: sourceInformation.name,
            sourceType: sourceInformation.sourceTypeId
        }

        axios.put('source', data).then(response => {
            if (response.status === 200) {
                setModal({
                    show: true,
                    title: 'Update successfull',
                    body: (
                        <p>
                            The Information for the source were successfull updated
                        </p>
                    )
                })
            }
        }).catch(err => {
            setModal({
                show: true,
                title: 'Update failed',
                body: (
                    <p>
                        The update of the source information failed: {err.message}
                    </p>
                )
            })
        })
    }

    return (
        <div className={'mb-4 mt-2'}>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} size={'xl'} />
            <p>
                In the general information about the source, the name as well as the type of the source can be edited.
            </p>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text style={styles.descriptionWidth}>ID</InputGroup.Text>
                <Form.Control
                    placeholder={'ID'}
                    value={sourceInformation.id}
                    readOnly={true} />
            </InputGroup>

            <InputGroup className={'mb-2'}>
                <InputGroup.Text style={styles.descriptionWidth}>Name</InputGroup.Text>
                <Form.Control
                    placeholder={'Name of the source'}
                    value={sourceInformation.name}
                    onChange={(e) => setSourceInformation({ ...sourceInformation, name: e.target.value })} />
            </InputGroup>

            <InputGroup className={'mb-2'}>
                <InputGroup.Text style={styles.descriptionWidth}>Type</InputGroup.Text>
                <Form.Control
                    placeholder={'Type of the source'}
                    value={sourceInformation.sourceType}
                    readOnly={true} />
                <Button variant='outline-secondary' onClick={openSourceTypesModal}>Search</Button>
            </InputGroup>

            <Button variant={'success'} onClick={() => updateSourceInformationHandler()} style={styles.fullWidth}>Save</Button>
        </div>
    )
}

export default SourceInformation;
