import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import axios from 'utils/axiosInstance';
import AppModal from 'components/Modal/Modal';

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const UserDetailsToggleElements = ({ userId }) => {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [verification, setVerification] = useState({ userId: null, verified: false });
  const [active, setActive] = useState({ userId: null, isActive: false });

  useEffect(() => {
    getVerificationStatus(userId);
  }, [userId]);

  useEffect(() => {
    getActiveStatus(userId);
  }, [userId]);

  /**
   * Loads the verification status of the user
   */
  function getVerificationStatus(user) {
    axios.get('user/verification', {
      params: {
        userId: user
      }
    }).then(response => {
      setVerification(response.data);
    }).catch(err => {
      setVerification({ userId: '', verified: false });
    });
  }

  /**
   * Loads the active status of the user
   * @param {number} user ID of the user for which the active status should be loaded
   */
  function getActiveStatus(user) {
    axios.get('user/activestatus', {
      params: {
        userId: userId
      }
    }).then(response => {
      setActive(response.data);
    }).catch(err => {
      setActive({ userId: null, isActive: false });
    });
  }

  /**
   * Updates the user verified status in Firestore
   * @param {boolean} value Flag if user is verified
   */
  function verifiedChangedHandler(value) {
    axios.post('user/verification', {
      userId: verification.userId,
      verified: value
    }).then(response => {
      setVerification({ ...verification, verified: value });
    }).catch(err => {
      setModal({
        show: true,
        title: 'Update failed',
        body: <p>The update of the verification status failed</p>
      });
    });
  }

  /**
   * Updates the user active status in the database
   * @param {boolean} value Flag if user is active
   */
  function activeChangedHandler(value) {
    axios.post('user/activestatus', {
      userId: active.userId,
      isActive: value
    }).then(response => {
      setActive({ ...active, isActive: value });
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Update failed',
        body: <p>The update of the activity status of the user failed</p>
      })
    })
  }

  return (
    <div className={'mb-4'}>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
      <InputGroup className={'mb-2'}>
        <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>Verification</InputGroup.Text>
        <Form.Control
          disabled={verification.userId === ''}
          placeholder={'Verification...'}
          value={verification.verified ? 'Yes' : 'No'}
          readOnly={true} />
        <DropdownButton variant={'outline-secondary'} title={'Select'} disabled={verification.userId === null || verification.userId === ''}>
          <Dropdown.Item onClick={() => verifiedChangedHandler(true)}>Yes</Dropdown.Item>
          <Dropdown.Item onClick={() => verifiedChangedHandler(false)}>No</Dropdown.Item>
        </DropdownButton>
      </InputGroup>

      <InputGroup className={'mb-2'}>
        <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>Active Status</InputGroup.Text>
        <Form.Control
          disabled={active.userId === null || active.userId === ''}
          placeholder={'User Active Status...'}
          value={active.isActive ? 'Yes' : 'No'}
          readOnly={true} />
        <DropdownButton variant={'outline-secondary'} title={'Select'} disabled={active.userId === null || active.userId === ''}>
          <Dropdown.Item onClick={() => activeChangedHandler(true)}>Yes</Dropdown.Item>
          <Dropdown.Item onClick={() => activeChangedHandler(false)}>No</Dropdown.Item>
        </DropdownButton>
      </InputGroup>
    </div>
  )
}

UserDetailsToggleElements.propTypes = {
  /**
   * The ID of the user
   */
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default UserDetailsToggleElements;