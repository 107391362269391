import React, { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types';

/**
 * Function creates the JSX elements for paging
 * @param {object} props Properties for the component
 * @returns JSX.Elements that represent the paging elements
 */
export default function Paging (props) {
    const [items, setItems] = useState([]);
    const maxPageNumber = Math.ceil(props.totalCount / props.pageSize);

    /**
     * Function gets called every time the user changes
     * the pagenumber of a table
     */
    useEffect(() => {
        const pages = [];

        // First item
        pages.push(<Pagination.Item key={pages.length} active={props.currentPage == 1} onClick={() => props.callbackFunction(1)}>{1}</Pagination.Item>)
        
        // Ellipsis if too many pages
        if (props.currentPage > 4) {
            pages.push(<Pagination.Ellipsis key={pages.length} />);
        }

        // Pages that are shown to the user
        const startPageNumber = Math.max(props.currentPage - 2, 2);
        for (let i = startPageNumber; i < Math.min(startPageNumber + 5, maxPageNumber); i++) {
            pages.push(<Pagination.Item key={pages.length} active={props.currentPage === i} onClick={() => props.callbackFunction(i)}>{i}</Pagination.Item>)
        }

        // Ellipsis of too many pages are left
        if (Math.min(startPageNumber + 5, maxPageNumber - 1) < maxPageNumber - 1) {
            pages.push(<Pagination.Ellipsis key={pages.length} />);
        }

        // Last item if available
        if (maxPageNumber > 1) {
            pages.push(<Pagination.Item key={pages.length} active={props.currentPage === maxPageNumber} onClick={() => props.callbackFunction(maxPageNumber)}>{maxPageNumber}</Pagination.Item>);
        }

        // Set items
        setItems(pages);
    }, [props]);


    return (
        <Pagination>
            <Pagination.First onClick={() => props.callbackFunction(1)}/>
            <Pagination.Prev onClick={() => props.callbackFunction(props.currentPage - 1)}/>
            {items}
            <Pagination.Next onClick={() => props.callbackFunction(props.currentPage + 1)}/>
            <Pagination.Last onClick={() => props.callbackFunction(maxPageNumber)}/>
        </Pagination>
    )
}

Paging.propTypes = {
    /**
     * Current page number the user has selected
     */
    currentPage: PropTypes.number.isRequired,
    /**
     * Total number of entries that can be found
     */
    totalCount: PropTypes.number.isRequired,
    /**
     * Selected page size by the user
     */
    pageSize: PropTypes.number.isRequired,
    /**
     * Callbackfunction for changing the page
     */
    callbackFunction: PropTypes.func.isRequired
}
