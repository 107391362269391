import React, { useMemo } from 'react';
import { Table, Button, Stack } from 'react-bootstrap';
import PropTypes from 'prop-types';

const styles = {
    center: { textAlign: 'center' },
    pointer: { cursor: 'pointer' },
    fullWidth: { width: '100%' },
}

const SourceContacts = ({ contactPeople, deleteContactPerson, editContactPerson }) => {
    const tableRows = useMemo(() => createTableRows(contactPeople), [contactPeople]);

    /**
     * Creates the table for the contact people.
     * @param {Array} data Array with contact people
     */
    function createTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.center}>
                <td colSpan={6}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx}>
                <td>{ item.contactId }</td>
                <td>{ item.sourceName }</td>
                <td>{ item.firstName }</td>
                <td>{ item.lastName }</td>
                <td>{ item.mail }</td>
                <td>
                    <Stack direction={'horizontal'} gap={2}>
                        <Button size={'sm'} variant={'success'} onClick={() => editContactPerson(item.contactId)}>Edit</Button>
                        <Button size={'sm'} variant={'outline-danger'} onClick={() => deleteContactPerson(item.contactId)}>Delete</Button>
                    </Stack>
                </td>
            </tr>
        })
    }

    return (
        <div>
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Source Name</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Mail</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
        </div>
    )
}

SourceContacts.propTypes = {
    /**
     * Array with all contact people of the source for the company.
     */
    contactPeople: PropTypes.array.isRequired,
    /**
     * Function that deletes a contact person from the database.
     */
    deleteContactPerson: PropTypes.func.isRequired,
    /**
     * Function that opens the modal for editing the contact details.
     */
    editContactPerson: PropTypes.func.isRequired,
}

export default SourceContacts;
