import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import AppModal from "../Modal/Modal";
import AddIsrc from "./AddIsrc";
import { getUser } from "../../utils/requireAuth";
import axios from '../../utils/axiosInstance';


/**
 * Initial state of the modal
 */
const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}


/**
 * Initial state of the component
 */
const INITIAL_STATE = {
  isrc: []
}

/**
 * Function modifies the current state according to the specified values
 * @param {object} state Current state of the component
 * @param {object} action Action specifies how the state of the component should be modified
 * @returns New state of the component
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'load':
      return { ...state, isrc: action.payload }
    case 'addIsrc':
      return { ...state, isrc: [...state.isrc, action.payload] }
    default:
      throw new Error(`Action type ${action.type} is no part of reducer in Isrc.jsx`);
  }
}


/**
 * Creates the fields for displaying the isrcs to the user
 * @returns JSX.Elements for displaying the ISRCs to the user
 */
export default function Isrc() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const elements = useMemo(() => mapTableRows(state.isrc), [state.isrc]);

  const { trackId } = useParams();

  /**
   * Loads the available isrc data when the component get loaded
   */
  useEffect(() => {
    populateIsrcData();
  }, []);

  /**
   * Fetches the isrc data for the track from the backend
   */
  function populateIsrcData() {
    axios.get('catalogue/recording/isrc', {
      params: { trackId: trackId },
    }).then(response => {
      dispatch({ type: 'load', payload: response.data });
    }).catch(err => {
      console.log(err);
    });
  }

  /**
   * Posts a new isrc to the database.
   * @param {string} isrc ISRC that needs to be added
   */
  function addIsrcHandler(isrc) {
    if (trackId === '' || isrc === '') {
      setModal({
        show: true,
        title: 'ISRC cannot be added',
        body: <p>The ISRC can only be added if a track and an ISRC is given</p>
      });
      return;
    }

    const data = {
      track: trackId,
      isrc: isrc
    }
    axios.post('/catalogue/recording/isrc', data).then(response => {
      dispatch({ type: 'addIsrc', payload: response.data });
      setModal(INITIAL_MODAL_STATE);
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Failed to add ISRC',
        body: (
          <p>
            The ISRC could not be added. Please make sure that the entered
            ISRC is not already used by this or another recording. Error: {err?.response?.data?.message || err.message}
          </p>
        )
      })
    });
  }

  /**
   * Sends a delete request to the backend that deletes the isrc
   * for the selected track from the database.
   * @param {object} isrcItem Isrc Item - Row from the table
   */
  function deleteIsrcHandler(isrcItem) {
    if (isrcItem.track === '' || isrcItem.isrc === '') {
      return;
    }

    axios.delete('catalogue/recording/isrc', {
      params: {
        trackId: isrcItem.track,
        isrc: isrcItem.isrc
      }
    }).then(response => {
      populateIsrcData();
    }).catch(err => {
      console.log(err);
    });
  }

  /**
   * Shows the modal for adding an ISRC
   */
  function showAddIsrcModalHandler() {
    setModal({
      show: true,
      title: 'Add ISRC for track ' + trackId.toString(),
      body: <AddIsrc callbackFunction={addIsrcHandler} />
    })
  }

  /**
   * Maps the isrc data to the table rows for the table
   * @param {array} data Array that represents all ISRCs that need to be displayed
   * @returns JSX.Elements representing the table rows
   */
  function mapTableRows(data) {
    if (data.length === 0) {
      return <tr key={0}>
        <td colSpan={4} style={{ 'textAlign': 'center' }}>No data available</td>
      </tr>
    }

    return data.map((item, idx) => {
      return (
        <tr key={idx}>
          <td>{idx + 1}</td>
          <td>{item.track}</td>
          <td>{item.isrc}</td>
          <td style={{ 'textAlign': 'center' }}><Button variant='outline-danger' size='sm' onClick={() => deleteIsrcHandler(item)}>Delete</Button></td>
        </tr>
      );
    });
  }

  return (
    <div>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
      <div className="w-100">
        <Table bordered striped hover responsive={true}>
          <thead>
            <tr>
              <th style={{ 'width': '50px' }}>&#8470;</th>
              <th>Track</th>
              <th>ISRC</th>
              <th style={{ 'textAlign': 'center', 'width': '100px' }}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {elements}
          </tbody>
        </Table>
      </div>
      <Button variant="secondary" onClick={showAddIsrcModalHandler}>Add ISRC</Button>
    </div>
  )
}