import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Breadcrumb } from 'react-bootstrap';
import CompanyDetails from 'components/CompanyDetails/CompanyDetails';
import CompanySources from 'components/CompanySources/CompanySources';
import CompanyDetailsToggleElements from 'components/CompanyDetailsToggleElements/CompanyDetailsToggleElements';
import axios from 'utils/axiosInstance';
import './Details.css';


/**
 * Creates the elements for the page company/details
 * @returns JSX.Element of the companyDetails page
 */
export default function Details() {
    const [company, setCompany] = useState(undefined);
    const { companyId } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        axios.get('company', {
            params: { companyId: companyId }
        }).then(response => {
            if (response.status === 200) {
                setCompany(response.data);
            }
        }).catch(err => {
            setCompany(undefined);
        })
    }, [companyId]);

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item href={'/customers'}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={'/customers/company'}>Company</Breadcrumb.Item>
                <Breadcrumb.Item href={`/customers/company/${companyId}`} active={true}>{(company && company.name) || companyId}</Breadcrumb.Item>
            </Breadcrumb>

            <h2 className='CompanyDetailsPaddingTop'>Company Details</h2>
            <p>
                The following fields represent the details of the company that can be edited.
                After editing, changes will only be saved if the update button will be clicked.
            </p>
            <CompanyDetails companyId={companyId} />

            <h2 className='CompanyDetailsPaddingTop'>Company Sources</h2>
            <p>
                The sources where the company receives reports or payments.
            </p>
            <CompanySources companyId={companyId} />

            <h2 className='CompanyDetailsPaddingTop'>Danger Zone</h2>
            <p>
                In the danger zone, critical settings for the company as well as the master
                user of the company can be set. Changes in the fields will directly affect
                the data in the database.
            </p>
            <CompanyDetailsToggleElements companyId={companyId} />

            <h2 className='CompanyDetailsPaddingTop'>Track Catalogue</h2>
            <Button variant='outline-secondary' onClick={() => navigate(`/customers/catalogue/recording/company-${companyId}`)}>Go to Track Catalogue</Button>
        </div>
    )
}
