import React, { useState } from 'react';
import { FloatingLabel, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CreateUser = ({ callbackFunction }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const createUserHandler = () => {
    if (name.length === 0 || email.length === 0) {
      return;
    }

    const data = {
      name: name,
      email: email
    }

    // Call the function that creates the user
    callbackFunction(data);
  }

  return (
    <div className={'mb-4'}>
      <FloatingLabel className={'mb-2'} label={'Name'}>
        <Form.Control
          placeholder='Name'
          value={name}
          onChange={(e) => setName(e.target.value)} />
      </FloatingLabel>

      <FloatingLabel className={'mb-2'} label={'E-Mail'}>
        <Form.Control
          placeholder={'E-Mail...'}
          value={email}
          onChange={(e) => setEmail(e.target.value)} />
      </FloatingLabel>

      <Button
        className={'w-100'}
        onClick={createUserHandler}
        variant={name.length > 0 && email.length > 0 ? 'success' : 'secondary'}>Create User</Button>
    </div>
  )
}

CreateUser.propTypes = {
  /**
   * The callback function that gets called when the user clicks on create
   * user. It is expected to take the user with the keys name and email as an input
   */
  callbackFunction: PropTypes.func.isRequired
}

export default CreateUser;