import React, {useState} from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

export default function AddIsrc(props) {
    const [isrc, setIsrc] = useState('');

    return (
        <div>
            <p>
                By entering the value for an isrc and pressing the button
                add isrc, the new value gets saved to the database.
            </p>
            <InputGroup>
                <InputGroup.Text>ISRC</InputGroup.Text>
                <Form.Control
                    placeholder='ISRC...'
                    aria-label='ISRC'
                    value={isrc}
                    onChange={(e) => setIsrc(e.target.value)} />
            </InputGroup>
            <Button
                variant={isrc.length > 0 ? 'success' : 'secondary'}
                style={{'width': '100%', 'marginTop': '1rem'}}
                onClick={() => props.callbackFunction(isrc)}>Add Isrc</Button>
        </div>
    )
}


AddIsrc.propTypes = {
    /**
     * Callback function that gets called when ever the user
     * clicks on add isrc to the database.
     * This function must take on argument as input value that represents
     * the entered isrc from the user.
     */
    callbackFunction: PropTypes.func.isRequired
}