import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, InputGroup, Form, Table, Button } from 'react-bootstrap';
import Paging from "components/Paging/Paging";
import AppModal from 'components/Modal/Modal';
import axios from 'utils/axiosInstance';
import TrackExportSelection from "components/TrackExportSelection/TrackExportSelection";

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const INITIAL_STATE = {
  tracks: [],
  startIndex: 0,
  totalCount: 0,
  pageNumber: 0,
  pageSize: 15,
}

const styles = {
  paddingTop1: { paddingTop: '1rem' },
  pointer: { cursor: 'pointer' },
  center: { textAlign: 'center' },
  small: { width: '100px' },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  marginRight: { marginRight: '1rem' },
  card: {
    backgroundColor: 'white',
    borderRadius: '12px',
    padding: '12px',
    boxShadow: '5px 10px 18px rgba(0, 0, 0, 0.1)'
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'load':
      return {
        ...state,
        tracks: action.payload.items,
        pageNumber: action.payload.pageNumber,
        startIndex: action.payload.recordNumber,
        totalCount: action.payload.totalCount
      }
    case 'reset':
      return INITIAL_STATE;
    case 'pageNumber':
      const maxPageNumber = Math.ceil(state.totalCount / state.pageSize);
      const pageNumber = Math.min(Math.max(action.payload, 1), maxPageNumber);
      return {
        ...state,
        pageNumber: pageNumber,
        startIndex: (pageNumber - 1) * state.pageSize
      }
    default:
      new Error(`Action type ${action.type} is no part of reducer in cataloguetracks.jsx`);
  }
}

/**
 * 
 * @returns JSX.Elements for displaying the track catalogue of the company
 */
export default function CatalogueTracks() {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [search, setSearch] = useState('');
  const [company, setCompany] = useState(undefined);
  const { companyId } = useParams();
  const tableRows = useMemo(() => createTableRows(state.tracks), [state.tracks]);
  let navigate = useNavigate();

  /**
   * Loads the company details when loading the page
   */
  useEffect(() => {
    getCompanyDetails();
  }, []);

  /**
   * Loads the track catalogue every time the user searches for a track
   * or changes the page
   */
  useEffect(() => {
    populateTracksData();
  }, [companyId, search, state.startIndex, state.pageSize, state.pageNumber, state.totalCount]);

  /**
   * Requests the company details for displaying the name of the
   * company on the page
   */
  function getCompanyDetails() {
    axios.get('company', {
      params: { companyid: companyId }
    }).then(response => {
      setCompany(response.data);
    }).catch(err => {
      console.log(err);
    });
  }

  /**
   * Loads the track catalogue for the company from the database
   */
  function populateTracksData() {
    axios.get('catalogue/recording/search', {
      params: {
        companyId: companyId,
        startIndex: state.startIndex,
        pageSize: state.pageSize,
        search: search
      }
    }).then(response => {
      dispatch({ type: 'load', payload: response.data });
    }).catch(err => {
      console.log(err);
      dispatch({ type: 'reset' });
    })
  }

  /**
   * Maps the data for the track catalogue to the rows in the table
   * @param {array} data Array of the tracks that need to be shown to the user
   * @returns JSX.Elements representing the rows of the table
   */
  function createTableRows(data) {
    return data.map((item, idx) => {
      return (
        <tr style={styles.pointer} key={idx} onClick={() => navigate(`track-${item.track}`)}>
          <td>{item.track}</td>
          <td>{item.title}</td>
          <td>{item.artists}</td>
          <td style={styles.center}>{!item.attentionNeeded ? 'Yes' : 'No'}</td>
        </tr>
      )
    });
  }

  /**
   * Requests the track catalogue of the artist and exports it as csv file.
   */
  function exportTrackCatalogue() {
    axios.get('catalogue/export', {
      params: {
        companyId: companyId
      },
      responseType: 'blob'
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      if (company != null) {
        let companyName = company.name;
        while (companyName.includes(" ")) {
          companyName = companyName.replace(" ", "_");
        }
        link.download = 'Trackcatalogue_' + companyName + ".csv";
      } else {
        link.download = 'Trackcatalogue.csv';
      }
      link.click();
    }).catch(err => {
      console.log(err);
    })
  }

  function exportSelectedTracks() {
    setModal({
      show: true,
      title: "Select tracks for export",
      body: <TrackExportSelection companyId={companyId} />
    });
  }

  return (
    <div>
      <AppModal size="xl" show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
      <Breadcrumb>
        <Breadcrumb.Item href="/customers">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/customers/catalogue/recording">Recording</Breadcrumb.Item>
        <Breadcrumb.Item href={`/customers/catalogue/recording/company-${companyId}`} active={true}>Catalogue</Breadcrumb.Item>
      </Breadcrumb>
      <h1>Track catalogue {company ? 'for ' + company.name : ''}</h1>
      <div style={styles.paddingTop1}>
        <InputGroup className='mb-2'>
          <InputGroup.Text>Searchtext</InputGroup.Text>
          <Form.Control
            placeholder="Searchtext"
            aria-label="Searchtext"
            value={search}
            onChange={(e) => setSearch(e.target.value)} />
        </InputGroup>
        <div className="w-100">
          <Table bordered striped hover responsive={true}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Artists</th>
                <th style={{ ...styles.center, ...styles.small }}>Attention</th>
              </tr>
            </thead>
            <tbody>
              {tableRows}
            </tbody>
          </Table>
        </div>
        <Paging currentPage={state.pageNumber} totalCount={state.totalCount} pageSize={state.pageSize} callbackFunction={(i) => dispatch({ type: 'pageNumber', payload: i })} />
      </div>
      <h2 style={styles.paddingTop1}>Track catalogue export</h2>
      <p>
        For exporting the track catalogue as a csv file there are multiple options available.
      </p>
      <div style={styles.flex}>
        <Button style={styles.marginRight} variant="secondary" onClick={exportTrackCatalogue}>Export complete Track Catalogue</Button>
        <Button variant="secondary" onClick={exportSelectedTracks}>Select tracks for export</Button>
      </div>
    </div>
  )
}
