import React, { useState, useMemo, useEffect, useReducer } from 'react';
import { Button, InputGroup, Form, Table, Row, Col, Breadcrumb } from 'react-bootstrap';

import AppModal from '../../../components/Modal/Modal';
import Paging from '../../../components/Paging/Paging';
import { getUser } from '../../../utils/requireAuth';
import axios from '../../../utils/axiosInstance';

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const INITIAL_STATE = {
    pointOfSales: [],
    startIndex: 0,
    pageNumber: 0,
    pageSize: 10,
    totalRecords: 0
}

const styles = {
    alignCenter: {
        textAlign: 'center'
    },
    card: {
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '12px'
    },
    spaceBetween: {
        display: 'flex',
        columnGap: '1rem'
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET':
            if (action.payload.items) {
                return {
                    ...state,
                    pointOfSales: action.payload.items,
                    pageNumber: action.payload.pageNumber,
                    totalRecords: action.payload.totalCount,
                    startIndex: action.payload.recordNumber
                };
            }
            return INITIAL_STATE;
        case 'paging':
            const maxPageNumber = Math.ceil(state.totalRecords / state.pageSize);
            const pageNumber = Math.min(Math.max(action.payload, 1), maxPageNumber);
            return {
                ...state,
                pageNumber: pageNumber,
                startIndex: (pageNumber - 1) * state.pageSize
            };
        case 'reset':
            return INITIAL_STATE;
        default:
            throw new Error(`Action type ${action.type} is no part of reducer in PointOfSales.jsx`)
    }
}

const EditPointOfSales = ({ posId, callbackFunction }) => {
    const [name, setName] = useState('');
    const user = getUser();

    useEffect(() => {
        getPointOfSale();
    }, []);

    function getPointOfSale() {
        const token = user ? user.token : '';
        axios.get(`/pos/${posId}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                const pos = response.data;
                if (pos && pos.pos !== undefined) {
                    setName(pos.pos);
                }
            }
        }).catch(err => {
            console.warn(err);
        });
    }

    return (
        <div>
            <p>
                The displayed name of the point of sale can be changed
            </p>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Point of Sale</InputGroup.Text>
                <Form.Control
                    placeholder={'Point of sale...'}
                    value={name}
                    onChange={(e) => setName(e.target.value)} />
            </InputGroup>
            <Button variant={name.length > 0 ? 'success' : 'secondary'} onClick={() => callbackFunction({ name, posId })}>Update POS</Button>
        </div>
    )
}

const PointOfSales = () => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [search, setSearch] = useState('');

    const tableRows = useMemo(() => createTableRows(state.pointOfSales), [state.pointOfSales]);

    const user = getUser();

    useEffect(() => {
        populatePointOfSalesData();
    }, [search, state.startIndex, state.pageSize]);

    function populatePointOfSalesData() {
        const token = user ? user.token : '';
        axios.get('/pos', {
            params: {
                startIndex: state.startIndex,
                pageSize: state.pageSize,
                search: search
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 200) {
                dispatch({ type: 'SET', payload: response.data });
            }
        }).catch(err => {
            console.warn(err);
            dispatch({ type: 'reset' });
        })
    }

    function createTableRows(data) {
        if (!data || data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={3}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx}>
                <td>{item.id}</td>
                <td>{item.pos}</td>
                <td style={styles.spaceBetween}>
                    <Button variant={'primary'} onClick={() => showUpdatePosModal(item.id)} size={'sm'}>Edit</Button>
                    <Button variant={'outline-danger'} onClick={() => showDeletePosModal(item.id)} size={'sm'}>Delete</Button>
                </td>
            </tr>
        })
    }

    function showUpdatePosModal(posId) {
        setModal({
            show: true,
            title: 'Update Point of Sale',
            body: <EditPointOfSales posId={posId} callbackFunction={updatePointOfSale} />
        });
    }

    function updatePointOfSale({ name, posId }) {
        const token = user ? user.token : '';
        const data = {
            id: posId,
            pos: name
        }
        axios.put('/pos', data, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                setModal({
                    show: true,
                    title: 'Successfull',
                    body: <p>
                        The name of the source was updated successfully.
                    </p>
                });
                populatePointOfSalesData();
            }
        }).catch(err => {
            console.warn(err);
            setModal({
                show: true,
                title: 'Failed to updated',
                body: <p>
                    The update of the point of sale failed because of an error: {err?.response?.data?.message || err.message}
                </p>
            });
        });
    }

    /**
     * Creates a new point of sales in the database
     */
    function createNewPointOfSalesHandler() {
        const token = user ? user.token : '';
        const data = {
            pos: search
        }

        axios.post('/pos', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 201) {
                setModal({
                    show: true,
                    title: 'Successfull',
                    body: <p>
                        The new source {search} was successfull added to the database.
                    </p>
                })
                setSearch('');
                populatePointOfSalesData();
            }
        }).catch(err => {
            console.warn(err);
            setModal({
                show: true,
                title: 'Creation failed',
                body: <p>
                    The creation of the new point of sale failed because of an error: {err?.response?.data?.message || err.message}
                </p>
            })
        })
    }

    function deletePosHandler(posId) {
        const token = user ? user.token : '';
        axios.delete('/pos', {
            params: {
                posId: posId
            },
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 204) {
                setSearch('');
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            console.warn(err);
            setModal({
                show: true,
                title: 'Failed to delete',
                body: <p>
                    The point of sale could not be deleted because of an error: {err?.response?.data?.message || err.message}
                </p>
            })
        })
    }

    function showDeletePosModal(posId) {
        setModal({
            show: true,
            title: 'Delete Point of Sale',
            body: <div>
                <p>
                    Do you really want to delete the point of sale? Please keep
                    in mind that this action cannot be reversed.
                </p>
                <Row>
                    <Col style={{ width: '50%' }}>
                        <Button style={{ width: '100%' }} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancel</Button>
                    </Col>
                    <Col style={{ width: '50%' }}>
                        <Button style={{ width: '100%' }} variant={'danger'} onClick={() => deletePosHandler(posId)}>Delete</Button>
                    </Col>
                </Row>
            </div>
        })
    }

    return (
        <div>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <Breadcrumb>
                <Breadcrumb.Item href={'/metadata'}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={'/metadata/pointofsales'} active={true}>Point of Sales</Breadcrumb.Item>
            </Breadcrumb>
            <h1>Point Of Sales</h1>
            <div style={styles.card}>
                <InputGroup className={'mb-2'}>
                    <InputGroup.Text>Searchfield</InputGroup.Text>
                    <Form.Control
                        placeholder={'Search...'}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)} />
                    <Button variant={search ? 'success' : 'secondary'} onClick={createNewPointOfSalesHandler}>New</Button>
                </InputGroup>
                <Table bordered striped hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Point of Sale</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>
                <Paging currentPage={state.pageNumber} totalCount={state.totalRecords} pageSize={state.pageSize} callbackFunction={(i) => dispatch({ type: 'paging', payload: i })} />
            </div>
        </div>
    )
}


export default PointOfSales;