import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { Table } from 'react-bootstrap';

import axios from 'utils/axiosInstance';
import PropTypes from 'prop-types';

const INITIAL_DATA_STATE = {
  notificationType: null,
  NotificationTypeName: '',
  items: []
}

const MissingTrackPayload = ({ items }) => {
  const tracks = useMemo(() => createTableRows(items), [items]);

  function createTableRows(data) {
    if (data.length === 0) {
      return <tr className={'text-center'}>
        <td colSpan={5}>No data available</td>
      </tr>
    }

    return data.map((item, idx) => {
      return <tr key={idx}>
        <td>{item.track}</td>
        <td><a href={`/customers/catalogue/recording/company-${item.company}/track-${item.track}`}>{item.title}</a></td>
        <td>{item.company}</td>
        <td>{item.companyName}</td>
        <td>{item.artists}</td>
      </tr>
    })
  }

  return (
    <div className={'mt-4'}>
      <h3>Notification Payload: Missing Track</h3>
      <p>
        The <strong>Missing Track</strong> alert was created because no data
        for the following tracks were found in the royalty data of the HELGA.database.
        You will come to the track by clicking the link.
      </p>
      <Table striped hover={true} bordered responsive>
        <thead>
          <tr>
            <th>Track</th>
            <th>Title</th>
            <th>Company</th>
            <th>Company Name</th>
            <th>Artists</th>
          </tr>
        </thead>
        <tbody>
          {tracks}
        </tbody>
      </Table>
    </div>
  )
}

const MissingTrackPeriodPayload = ({ items }) => {
  const tracks = useMemo(() => createTableRows(items), [items]);

  function createTableRows(data) {
    if (data.length === 0) {
      return <tr className={'text-center'}>
        <td colSpan={5}>No data available</td>
      </tr>
    }

    return data.map((item, idx) => {
      return <tr key={idx}>
        <td>{item.track}</td>
        <td><a href={`/customers/catalogue/recording/company-${item.company}/track-${item.track}`}>{item.title}</a></td>
        <td>{item.company}</td>
        <td>{item.companyName}</td>
        <td>{item.artists}</td>
      </tr>
    })
  }

  return (
    <div className={'mt-4'}>
      <h3>Notification Payload: Missing Track Period</h3>
      <p>
        The <strong>Missing Track Period</strong> alert was created because no data
        for the following tracks were found in the given period. You can look into
        the details of the recording by following the link in the table.
      </p>
      <Table striped hover={true} bordered responsive>
        <thead>
          <tr>
            <th>Track</th>
            <th>Title</th>
            <th>Company</th>
            <th>Company Name</th>
            <th>Artists</th>
          </tr>
        </thead>
        <tbody>
          {tracks}
        </tbody>
      </Table>
    </div>
  )
}

const MissingTrackRightPayload = ({ items }) => {
  const rows = useMemo(() => createTableRows(items), [items]);

  function createTableRows(data) {
    if (data === undefined || data.length === 0) {
      return <tr className={'text-center'}>
        <td colSpan={2}>No data available</td>
      </tr>
    }

    return data.map((item, idx) => {
      return <tr key={idx}>
        <td>{item.id}</td>
        <td>{item.rightName || 'N/A'}</td>
      </tr>
    })
  }

  return (
    <div className={'mt-4'}>
      <h3>Notification Payload: Missing Track Right</h3>
      <p>
        The <strong>Missing Track Right</strong> alert was created because no data
        for the given right was found for the track.
      </p>
      <Table bordered striped hover={true} responsive>
        <thead>
          <tr>
            <th>Right ID</th>
            <th>Right Name</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </div>
  )
}

const NotificationPayload = ({ notificationId }) => {
  const [data, setData] = useState(INITIAL_DATA_STATE);

  useEffect(() => {
    populateNotificationData(notificationId);
  }, [notificationId]);

  function populateNotificationData(notification) {
    axios.get('notifications/payload', {
      params: {
        notificationId: notification
      }
    }).then(response => {
      if (response.status === 200) {
        setData(response.data);
      }
    }).catch(err => {
      console.log(err);
      setData(INITIAL_DATA_STATE);
    });
  }

  switch (data.notificationType) {
    case null:
      return (
        <div>
          <h1>Notification Payload not available</h1>
          <p>
            The payload for the notification is either not available
            or cannot be displayed.
          </p>
        </div>
      )
    case 1:
      return <MissingTrackPayload items={data.items} />
    case 2:
      return <MissingTrackPeriodPayload items={data.items} />
    case 4:
      return <MissingTrackRightPayload items={data.items} />
    default:
      return null;
  }
}

NotificationPayload.propTypes = {
  /**
   * The ID of the notification
   */
  notificationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default NotificationPayload;
