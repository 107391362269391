import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PageHome from 'components/PageHome/PageHome';
import CollectionManager from './CollectionManager/CollectionManager';
import HelgaServiceTasks from './HelgaServiceTasks/HelgaServiceTasks';

const elements = [
  {
    title: 'Collection Manager',
    message: 'In the collection manager imported collection files are listed. This files represent the imported royalty statements of the artists. Also within the collection manager, collections can be deleted.',
    link: 'administration/collectionmanager'
  },
  {
    title: 'HELGA.service Tasks',
    message: "In the HELGA.service tasks an overview about all scheduled tasks of HELGA.service can be seen. Also, new tasks can be scheduled for an upcoming target starting date.",
    link: 'administration/helgaservicetasks'
  }
]

const AdministrationRoutes = [
  {
    path: '',
    element: <PageHome elements={elements} siteHeading={'Administration Home'} />
  },
  {
    path: 'collectionmanager',
    element: <CollectionManager />
  },
  {
    path: 'helgaservicetasks',
    element: <HelgaServiceTasks />
  }
]

export default function Administration(props) {
  return (
    <Routes>
      {AdministrationRoutes.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />
      })}
    </Routes>
  )
}
