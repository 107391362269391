import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { Table, Form, FloatingLabel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'utils/axiosInstance';
import './AppUserTable.css';

/**
 * Initial state of the component containing all relevant parameters
 */
const INITIAL_STATE = {
    users: []
}

/**
 * Reducer function for managing the state of the component
 * @param {object} state Current state of the component
 * @param {object} action Action that contains the information how the
 * current state should be manipulated
 * @returns {object} New state of the component
 */
const reducer = (state, action) => {
    switch (action.type) {
        case "loadUsers":
            return { ...state, users: action.payload };
        default:
            throw new Error(`Action type ${action.type} is no part of reducer in AppUserTable`);
    }
}

export default function AppUserTable(props) {
    const [search, setSearch] = useState('');
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const appUserRows = useMemo(() => createTableRows(state.users), [state.users]);

    /**
     * Function will execute when the page is loaded as well as
     * any time the user enters a new search term.
     */
    useEffect(() => {
        populateAppUserData(props.link);
    }, [search]);

    /**
     * Fetches the app users from the backend
     * @param {string} link Link from which the app users will be fetched
     */
    function populateAppUserData(link) {
        axios.get(link, {
            params: { searchParam: search }
        }).then(response => {
            dispatch({ type: 'loadUsers', payload: response.data });
        }).catch(err => {
            console.log(err);
        })
    }

    /**
     * Maps the user array into an array of JSX elements that will be displayed in the table
     * @param {array} users Array of appUsers that were requested from the backend
     * @returns Array of JSX elements representing the rows of the table
     */
    function createTableRows(users) {
        return users.map((user, idx) => {
            return (
                <tr key={idx} onClick={() => props.callbackFunction(user.uId)}>
                    <td>{user.artistName}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.verified ? 'Yes' : 'No'}</td>
                </tr>
            );
        });
    }

    return (
        <div className="mb-4">
            <FloatingLabel className={'mb-2'} label={'🔍 Searchtext'}>
                <Form.Control
                    placeholder="Searchtext"
                    aria-label="Searchtext"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} />
            </FloatingLabel>
            <div className='w-100'>
                <Table bordered striped hover responsive={true}>
                    <thead>
                        <tr>
                            <th>AristName</th>
                            <th>FirstName</th>
                            <th>LastName</th>
                            <th>Verification status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appUserRows}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

AppUserTable.propTypes = {
    /**
     * Link from which the app users will be fetched
     */
    link: PropTypes.string.isRequired,
    /**
     * Callback function that will be executed when the user is clicking
     * on a row inside of the table. The function will take the uid of the user
     * as an input parameter
     */
    callbackFunction: PropTypes.func.isRequired
}
