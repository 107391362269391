import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Button, Row, Col } from 'react-bootstrap';
import AppModal from '../Modal/Modal';
import axios from 'utils/axiosInstance';

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const styles = {
    center: { textAlign: 'center' },
    fullWidth: { width: '100%' },
    pointer: {
        cursor: 'pointer'
    },
    inputGroupText: {
        width: "20%",
        minWidth: "175px"
    },
}

const CompositionSplits = () => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [splits, setSplits] = useState([]);
    const tableRows = useMemo(() => createTableRows(splits), [splits]);
    const { compositionId, companyId } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        populateCompositionSplitData(compositionId);
    }, [compositionId]);

    function populateCompositionSplitData(composition) {
        axios.get('catalogue/publishing/splits', {
            params: {
                workId: composition
            }
        }).then(response => {
            if (response.status === 200) {
                setSplits(response.data);
            }
        }).catch(err => {
            console.log(err);
            setSplits([]);
        })
    }

    function createTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.center}>
                <td colSpan={5}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx} style={styles.pointer}>
                <td>{item.companyName}</td>
                <td>{item.role}</td>
                <td>{item.synchronizationRights}%</td>
                <td>{item.performanceRights}%</td>
                <td>{item.mechanicalRights}%</td>
                <td><Button size={'sm'} variant={'outline-danger'} onClick={() => showDeleteSplitModal(item.company)}>delete</Button></td>
            </tr>
        })
    }

    function showDeleteSplitModal(company) {
        setModal({
            show: true,
            title: 'Delete Split',
            body: (
                <div>
                    <p>
                        Do you really want to delete the split from the composition? Please keep
                        in mind, that this action cannot be reversed.
                    </p>
                    <Row>
                        <Col>
                            <Button variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)} style={styles.fullWidth}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button variant={'danger'} onClick={() => deleteSplitHandler(company)} style={styles.fullWidth}>Delete</Button>
                        </Col>
                    </Row>
                </div>
            )
        });
    }

    function deleteSplitHandler(company) {
        axios.delete('catalogue/publishing/splits', {
            params: {
                company: company,
                workId: compositionId
            }
        }).then(response => {
            if (response.status === 204) {
                populateCompositionSplitData(compositionId);
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            console.log(err);
            setModal({
                show: true,
                title: 'Failed to delete',
                body: <p>
                    The split could not be deleted because of an error: {err.message}
                </p>
            });
        });
    }

    return (
        <div className={'mb-4'}>
            <AppModal size={'xl'} show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <h5>Work Splits</h5>
            <p>
                The splits of the composition describe which party owns how much of the composition. In the
                following, splits can be added or removed from the composition.
            </p>
            <div className="w-100">
                <Table bordered striped hover responsive={true}>
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Role</th>
                            <th>Synchronization</th>
                            <th>Performance</th>
                            <th>Mechanical</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>
            </div>
            <Button variant={'success'} onClick={() => navigate('newsplit')}>Add Splits</Button>
        </div>
    )
}

export default CompositionSplits;
