import { Route, Routes } from 'react-router-dom';
import PageHome from '../../components/PageHome/PageHome';
import Sources from './Sources/Sources';
import PointOfSales from 'pages/Metadata/PointOfSales/PointOfSales';
import { NotificationActions, NotificationImpact, NotificationStatus } from 'components/Notifications';

const elements = [
  {
    title: 'Sources',
    message: 'In the sources management you can add, edit or delete sources from HELGA. A source is always a party that delivers information to HELGA for different point of sales and rights',
    link: '/metadata/source'
  },
  {
    title: 'Point of Sales',
    message: 'In the point of sales management, new point of sales can be created and existing ones can be modified or deleted. Further functionality will follow soon.',
    link: '/metadata/pointofsales'
  },
  {
    title: 'Notification Actions',
    message: 'Actions are things the user or HELGA needs to do in order to resolve a notification. In this page, you can add or edit the existing actions that are shown to the user',
    link: '/metadata/notificationactions'
  },
  {
    title: 'Notification Status',
    message: 'In the notification status page you can add and edit status information for notifications. Create all the necessary status tags to let the user know how far the progress is.',
    link: '/metadata/notificationstatus'
  },
  {
    title: 'Notification Impact',
    message: 'Alerts mostly mean a negative information to the user. But how much? Add different impacts so that the user knows exactly how bad an alert is.',
    link: '/metadata/notificationimpact'
  }
]

const MetaDataRoutes = [
  {
    path: '',
    element: <PageHome elements={elements} siteHeading={'Metadata Home'} />
  },
  {
    path: 'source/*',
    element: <Sources />
  },
  {
    path: 'pointofsales/*',
    element: <PointOfSales />,
  },
  {
    path: 'notificationactions',
    element: <NotificationActions />
  },
  {
    path: 'notificationstatus',
    element: <NotificationStatus />
  },
  {
    path: 'notificationimpact',
    element: <NotificationImpact />
  }
]

export default function Metadata() {
  return (
    <Routes>
      {MetaDataRoutes.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} element={element} {...rest} />
      })}
    </Routes>
  )
}
