import { Route, Routes } from 'react-router-dom';
import UserHome from "./Home";
import Details from "./Details";
import CompanyNew from 'components/CompanyNew/CompanyNew';

const CompanyRoutes = [
    {
        path: '',
        element: <UserHome />
    },
    {
        path: 'new',
        element: <CompanyNew />
    },
    {
        path: ':companyId',
        element: <Details />
    }
]

export default function User(props) {
    return (
        <Routes>
            {CompanyRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
            })}
        </Routes>
    )
}
