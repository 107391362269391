import React from "react";
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

/**
 * Function for creating the Modal.
 * @param {object} props Input properties for the Modal
 * @returns JSX.Element that represents the Modal
 */
export default function AppModal(props) {
    // Set the default values for the properties
    const {
        backdrop = true,
        keyboard = true,
        showFooter = true,
        closeButton = true,
        size = 'lg',
        fullscreen = false
    } = props

    return (
        <Modal
            backdrop={backdrop}
            keyboard={keyboard}
            show={props.show}
            onHide={props.closeHandler}
            size={size}
            fullscreen={fullscreen}
        >
            <Modal.Header closeButton={closeButton}>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>{props.body}</Modal.Body>
            {showFooter &&
                <Modal.Footer>
                    <Button variant="primary" onClick={props.closeHandler}>Close</Button>
                </Modal.Footer>
            }
        </Modal>
    )
}

AppModal.propTypes = {
    /**
     * Title of the Modal
     */
    title: PropTypes.string.isRequired,
    /**
     * Text or JSX.Elements that will be displayed in the Modal
     */
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    /**
     * Boolean value that indicates if the modal
     * is currently displayed to the user
     */
    show: PropTypes.bool.isRequired,
    /**
     * Callback function for closing the Modal
     */
    closeHandler: PropTypes.func.isRequired,
    /**
     * Specifies the size of the modal
     */
    size: PropTypes.oneOf(['sm', 'lg', 'xl']),
    /**
     * Displays the modal in fullscreen
     */
    fullscreen: PropTypes.bool,
    /**
     * Include a backdrop component. Specify 'static' for a backdrop that
     * doesn't trigger an "onHide" when clicked.
     */
    backdrop: PropTypes.oneOf(['static', true, false]),
    /**
     * Close the modal when escape key is pressed
     */
    keyboad: PropTypes.oneOf([true, false]),
    /**
     * Show the modal footer which contains a button for closing the modal
     */
    showFooter: PropTypes.oneOf([true, false]),
    /**
     * Display a button for closing the modal in the top right
     */
    closeButton: PropTypes.oneOf([true, false]),
}