import React, { useState, useEffect, useReducer, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import CountrySearch from '../CountrySearch/CountrySearch';
import AppModal from '../Modal/Modal';
import PropTypes from 'prop-types';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';
import './CompanyDetails.css';
import CompanyTypeSearch from '../CompanyTypeSearch/CompanyTypeSearch';
import CompanyTable from '../CompanyTable/CompanyTable';

/**
 * Initial state of the AppModal
 */
const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

/**
 * Initial state of the component
 */
const INITIAL_STATE = {
    companyDetails: {
        id: null,
        name: '',
        artistName: '',
        email: '',
        country: null,
        spotify: '',
        avatar: '',
        songstats: '',
        payoutTargetCompany: null,
        payoutTargetCompanyName: '',
        companyType: null,
        companyTypeName: '',
        isCustomer: null,
        isCatalogueImport: null,
        isPayout: null,
        isRevenueProtection: null,
    },
    countries: new Map(),
    companyTypes: new Map(),
}

const ELEMENTS = [
    ['id', { type: 'input', label: 'ID', editable: false }],
    ['name', { type: 'input', label: 'Name', editable: true }],
    ['artistName', { type: 'input', label: 'Artist name', editable: true }],
    ['email', { type: 'input', label: 'E-Mail', editable: true }],
    ['country', { type: 'country', label: 'Country', editable: true, options: 'countries' }],
    ['avatar', { type: 'input', label: 'Avatar', editable: true }],
    ['spotify', { type: 'input', label: 'Spotify', editable: true }],
    ['songstats', { type: 'input', label: 'Songstats', editable: true }],
    ['payoutTargetCompany', { type: 'payoutCompany', label: 'Payout Company', editable: false }],
    ['payoutTargetCompanyName', { type: 'input', label: 'Payout Company', editable: false }],
    ['companyTypeName', { type: 'companyType', label: 'Company Type', editable: false }],
    ['isCustomer', { type: 'bool', label: 'Customer', editable: true }],
    ['isCatalogueImport', { type: 'bool', label: 'Import Catalogue', editable: true }],
    ['isPayout', { type: 'bool', label: 'Payout', editable: true }],
    ['isRevenueProtection', { type: 'bool', label: 'Revenue protection', editable: true }],
    ['catalogueUpdatedAt', { type: 'input', label: 'Last Catalogue Update', editable: false }]
]

/**
 * Modifies the current state of the component and returns the new one
 * @param {object} state Current state of the component
 * @param {object} action Object with type and payload that specifies how the state should be modified
 * @returns New state of the component
 */
const reducer = (state, action) => {
    switch (action.type) {
        case 'companyDetails':
            for (const [key, value] of Object.entries(action.payload)) {
                // if (value === null) {
                //     action.payload[key] = '';
                // }
            }
            return { ...state, companyDetails: action.payload }
        case 'countries':
            return { ...state, countries: action.payload }
        case 'companyTypes':
            return { ...state, companyTypes: action.payload }
        case 'updateCompanyDetails':
            return { ...state, companyDetails: { ...state.companyDetails, [action.key]: action.payload } }
        case 'setBool':
            return { ...state, companyDetails: { ...state.companyDetails, [action.key]: action.payload } }
        case 'setCountry':
            return { ...state, companyDetails: { ...state.companyDetails, country: action.payload } }
        case 'setCompanyType':
            return { ...state, companyDetails: { ...state.companyDetails, companyType: action.payload } }
        case 'setPayoutTargetCompany':
            return {
                ...state,
                companyDetails: {
                    ...state.companyDetails,
                    payoutTargetCompany: action.payload.id,
                    payoutTargetCompanyName: action.payload.name
                }
            };
        default:
            throw new Error(`Action type ${action.type} is no part of reducer in CompanyDetails`);
    }
}

/**
 * Creates the elements for displaying the company details to the user.
 * @param {object} props Properties for the component CompanyDetails
 * @returns JSX.Element that represents the input fields for the company details
 */
export default function CompanyDetails(props) {
    const itemSettings = new Map(ELEMENTS);
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const detailsComponents = useMemo(() => createComponents(), [state.companyDetails, state.countries]);
    const user = getUser();

    /**
     * Requests the company details whenever the companyId changes
     */
    useEffect(() => {
        if (props.companyId) {
            populateCompanyDetails(props.companyId);
        }
    }, [props.companyId]);

    /**
     * Requests the countries from the database
     */
    useEffect(() => {
        populateCountries();
    }, []);

    /**
     * Request the company types from the database
     */
    useEffect(() => {
        populateCompanyTypes();
    }, []);

    /**
     * Fetches the data for the company details from the api and sets the state
     * @param {number} companyId Id of the company for which the information should be fetched
     */
    function populateCompanyDetails(companyId) {
        const token = user ? user.token : '';
        axios.get('company', {
            params: { companyId: companyId },
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            dispatch({ type: 'companyDetails', payload: response.data });
        }).catch(err => {
            console.log(err);
        });
    }

    /**
     * Fetches the data for the company types from the api and sets the state
     */
    function populateCompanyTypes() {
        const token = user ? user.token : '';
        axios.get('options/companytypes', {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            const data = new Map(response.data.map(item => [item.id, item]));
            dispatch({ type: 'companyTypes', payload: data });
        }).catch(err => {
            console.log(err);
            dispatch({ type: 'companyTypes', payload: new Map() });
        })
    }

    /**
     * Fetches the data for the countries from the api and sets the state
     */
    function populateCountries() {
        const token = user ? user.token : '';
        axios.get('options/country', {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            dispatch({ type: 'countries', payload: new Map(response.data.map(item => [item.id, item])) });
        }).catch(err => {
            dispatch({ type: 'countries', payload: new Map() });
            console.log(err);
        });
    }

    /**
     * Function shows the modal for searching for a country
     */
    function selectCountryHandler() {
        setModal({
            show: true,
            title: "Country Search",
            body: <CountrySearch countryCallback={setCountryHandler} />
        });
    }

    /**
     * Function sets the selected country to the state of the component
     * @param {number} countryId ID of the country that the user selected
     */
    function setCountryHandler(countryId) {
        dispatch({ type: 'setCountry', payload: countryId });
        setModal(INITIAL_MODAL_STATE);
    }

    function selectCompanyTypeHandler() {
        setModal({
            show: true,
            title: 'Company Type Search',
            body: <CompanyTypeSearch callback={setCompanyTypeHandler} />
        })
    }

    function setCompanyTypeHandler(companyTypeId) {
        dispatch({ type: 'setCompanyType', payload: companyTypeId });
        setModal(INITIAL_MODAL_STATE);
    }

    function showPayoutPartySelector() {
        setModal({
            show: true,
            title: 'Select target payout company',
            body: <CompanyTable link='company/search' callbackFunction={setPayoutCompany} />
        });
    }

    function setPayoutCompany(companyId) {
        const token = user ? user.token : '';
        axios.get('company', {
            params: {
                companyId: companyId
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            dispatch({ type: 'setPayoutTargetCompany', payload: response.data });
            setModal(INITIAL_MODAL_STATE);
        }).catch(err => {
            console.log(err);
        });
    }

    /**
     * Saves the changes in the company details to the database
     */
    function saveChangesHandler() {
        const token = user ? user.token : '';
        const companyDetails = { ...state.companyDetails }
        if (companyDetails.country === '') {
            companyDetails.country = null;
        }
        axios.put('company', companyDetails, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            setModal({
                show: true,
                title: "Update successfull",
                body: <p>The company {state.companyDetails.name} was updated successfully!</p>
            });
        }).catch(err => {
            setModal({
                show: true,
                title: "Update failed",
                body: <p>The company {state.companyDetails.name} could not be updated!</p>
            });
            console.log(err);
        });
    }

    /**
     * Function maps the company details to the input fields that are shown to the user
     * @returns JSX.Elements that represent the input fields for the company details
     */
    function createComponents() {
        const items = [];
        itemSettings.forEach((item, key) => {
            switch (item.type) {
                case 'input':
                    items.push(
                        <InputGroup key={items.length} className="mb-2">
                            <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }} id="basic-addon2">{item.label}</InputGroup.Text>
                            <Form.Control
                                placeholder={item.label + '...'}
                                aria-label={item.label}
                                aria-describedby="basic-addon2"
                                value={state.companyDetails[key] ? state.companyDetails[key] : ''}
                                readOnly={!item.editable}
                                onChange={(e) => dispatch({ type: 'updateCompanyDetails', key: key, payload: e.target.value })}
                            />
                        </InputGroup>
                    );
                    break;
                case 'bool':
                    items.push(
                        <InputGroup key={items.length} className="mb-2">
                            <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }} id="basic-addon2">{item.label}</InputGroup.Text>
                            <Form.Control value={state.companyDetails[key] ? 'Yes' : 'No'} readOnly={true} />
                            <DropdownButton variant="outline-secondary" title="Select" id={key}>
                                <Dropdown.Item onClick={(e) => dispatch({ type: 'setBool', key: key, payload: true })}>True</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => dispatch({ type: 'setBool', key: key, payload: false })} >False</Dropdown.Item>
                            </DropdownButton>
                        </InputGroup>
                    );
                    break;
                case 'country':
                    let country = state.countries.get(state.companyDetails.country);
                    if (country) {
                        country = country.countryName;
                    } else {
                        country = "No country available";
                    }
                    items.push(
                        <InputGroup key={items.length} className='mb-2'>
                            <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }} id='basic-addon2'>{item.label}</InputGroup.Text>
                            <Form.Control value={country} readOnly={true} />
                            <Button variant='outline-secondary' onClick={selectCountryHandler}>Select</Button>
                        </InputGroup>
                    );
                    break;
                case 'companyType':
                    let companyType = state.companyTypes.get(state.companyDetails.companyType);
                    if (companyType) {
                        companyType = companyType.type;
                    } else {
                        companyType = 'No company type available';
                    }

                    items.push(
                        <InputGroup key={items.length} className={'mb-2'}>
                            <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>{item.label}</InputGroup.Text>
                            <Form.Control value={companyType} readOnly={true} />
                            <Button variant={'outline-secondary'} onClick={selectCompanyTypeHandler}>Select</Button>
                        </InputGroup>
                    );
                    break;
                case 'payoutCompany':
                    items.push(
                        <InputGroup key={items.length} className={'mb-2'}>
                            <InputGroup.Text style={{ 'width': '20%', 'minWidth': '120px' }}>{item.label}</InputGroup.Text>
                            <Form.Control
                                placeholder={'...'}
                                value={state.companyDetails[key] ? state.companyDetails[key] : 'N/A'}
                                readOnly={true}
                            />
                            <Button variant={'outline-secondary'} onClick={showPayoutPartySelector}>Select</Button>
                        </InputGroup>
                    )
                    break;
                default:
                    throw new Error(`Element type ${item.type} is not available in function CompanyDetails.createComponents`);
            }
        });
        return items;
    }

    return (
        <div>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            {detailsComponents}
            <Button variant='success' style={{ 'width': '100%' }} onClick={saveChangesHandler}>Update company</Button>
        </div>
    )
}

CompanyDetails.propTypes = {
    /**
     * ID of the company for which the details should be loaded
     */
    companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}