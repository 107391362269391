import { Route, Routes } from 'react-router-dom';
import { NotificationDetails } from 'components/Notifications';
import NotificationsHome from './NotificationsHome';

const NotificationRoutes = [
    {
        path: '',
        element: <NotificationsHome />
    },
    {
        path: ':notificationId',
        element: <NotificationDetails />
    }
];

const Notifications = () => {
    return (
        <Routes>
            {NotificationRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />
            })}
        </Routes>
    )
}

export default Notifications;
