import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Row, Col } from 'react-bootstrap';
import CompanyTable from '../CompanyTable/CompanyTable';
import AppModal from '../Modal/Modal';
import axios from '../../utils/axiosInstance';

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const styles = {
  center: { textAlign: 'center' },
  fullWidth: { width: '100%' },
  pointer: {
    cursor: 'pointer'
  }
}

const CompositionWriters = ({ compositionId }) => {
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [writers, setWriters] = useState([]);
  const tableRows = useMemo(() => createTableRows(writers), [writers]);

  useEffect(() => {
    populateWriterInformation(compositionId);
  }, [compositionId]);

  /**
   * Requests the information about the writers from the api
   * @param {number} composition ID of the composition
   */
  function populateWriterInformation(composition) {
    axios.get('catalogue/publishing/writers', {
      params: {
        workId: composition
      }
    }).then(response => {
      if (response.status === 200) {
        setWriters(response.data);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  /**
   * Creates the table rows for the composition writers table
   * @param {Array} data Composition writers
   * @returns JSX.Elements of the table rows
   */
  function createTableRows(data) {
    if (data.length === 0) {
      return <tr style={styles.center}>
        <td colSpan={4}>No data available</td>
      </tr>
    }

    return data.map((item, idx) => {
      return <tr key={idx} style={styles.pointer}>
        <td>{item.company}</td>
        <td>{item.artistName}</td>
        <td>{item.name}</td>
        <td><Button size={'sm'} variant={'outline-danger'} onClick={() => showDeleteWriterModal(item.company)}>delete</Button></td>
      </tr>
    })
  }

  /**
   * Asks the user if the composition writer really should be deleted
   * @param {number} company ID of the company
   */
  function showDeleteWriterModal(company) {
    setModal({
      show: true,
      title: 'Delete Composition Writer',
      body: (
        <div>
          <p>
            Do you really want to delete the writer of this composition? Please
            keep in mind that this action can't be reversed
          </p>
          <Row>
            <Col>
              <Button style={styles.fullWidth} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancel</Button>
            </Col>
            <Col>
              <Button style={styles.fullWidth} variant={'danger'} onClick={() => deleteWriterHandler(company)}>Delete</Button>
            </Col>
          </Row>
        </div>
      )
    });
  }

  /**
   * Sends a delete request to the api for deleting an composition writer
   * @param {number} company ID of the writer
   */
  function deleteWriterHandler(company) {
    axios.delete('catalogue/publishing/writers', {
      params: {
        company: company,
        workId: compositionId
      }
    }).then(response => {
      if (response.status === 204) {
        populateWriterInformation(compositionId);
        setModal(INITIAL_MODAL_STATE);
      }
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Failed to delete writer',
        body: (
          <p>
            The writer could not be deleted because of an error:
            {err.message}
          </p>
        )
      });
    })
  }

  /**
   * Shows the modal for adding a composition writer
   */
  function showAddWriterModal() {
    setModal({
      show: true,
      title: 'Search for writer',
      body: <CompanyTable
        callbackFunction={addWriterHandler}
        link='company/search'
      />
    })
  }

  /**
   * Sends a post request to the api for creating a new composition writer
   * @param {number} company ID of the writer
   */
  function addWriterHandler(company) {
    const data = {
      workId: compositionId,
      company: company
    }
    axios.post('catalogue/publishing/writers', data).then(response => {
      if (response.status === 201) {
        populateWriterInformation(compositionId);
        setModal(INITIAL_MODAL_STATE);
      }
    }).catch(err => {
      setModal({
        show: true,
        title: 'Adding writer failed',
        body: (
          <p>
            The writer could not be added to the composition because of an error:
            {err.message}
          </p>
        )
      })
    })
  }

  return (
    <div className='mb-4'>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
      <h5>Work Writers</h5>
      <p>
        In the following, the writers of a composition can be managed. It is possible
        to add or to remove them.
      </p>
      <Table bordered striped hover responsive={true}>
        <thead>
          <tr>
            <td>Company</td>
            <td>Artist name</td>
            <td>Name</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </Table>
      <Button variant={'success'} onClick={showAddWriterModal}>Add Writer</Button>
    </div>
  )
}

CompositionWriters.propTypes = {
  /**
   * ID of the composition
   */
  compositionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default CompositionWriters;
