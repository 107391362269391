import React, { useState, useMemo } from 'react';
import { InputGroup, Form, Button, Table, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AppModal from '../Modal/Modal';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const styles = {
    fullWidth: { width: '100%' },
    alignCenter: { textAlign: 'center' },
    marginTop: { marginTop: '1rem'}
}

const SourcePayoutDateModal = ({ callbackFunction }) => {
    const [date, setDate] = useState('');

    return (
        <div>
            <p>
                Add the date when the source will report to the artists
            </p>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Reporting Date</InputGroup.Text>
                <Form.Control
                    placeholder={'Reporting date...'}
                    type={'date'}
                    value={date}
                    onChange={(e) => setDate(e.target.value)} />
            </InputGroup>
            <Button variant={date ? 'success' : 'secondary'} onClick={() => callbackFunction(date)}>Add reporting date</Button>
        </div>
    )
}

const SourcePayoutDates = ({ sourceId, rightId, right, payoutDates, reloadData }) => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const tableRows = useMemo(() => createTableRows(payoutDates), [payoutDates]);
    const user = getUser();

    function deletePayoutDateModal(date) {
        setModal({
            show: true,
            title: 'Delete reporting date from source',
            body: <div>
                <p>
                    Do you really want to delete the reporting date from the source?
                    Please keep in mind that this action cannot be reversed.
                </p>
                <Row>
                    <Col>
                        <Button style={styles.fullWidth} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button style={styles.fullWidth} variant={'danger'} onClick={() => deletePayoutDate(date)}>Delete</Button>
                    </Col>
                </Row>
            </div>
        })
    }

    function deletePayoutDate(date) {
        const token = user ? user.token : '';
        axios.delete('source/payoutdates', {
            params: {
                source: sourceId,
                right: rightId,
                date: date
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 204) {
                reloadData();
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            console.error(err);
            reloadData();
            setModal({
                show: true,
                title: 'Failed to delete reporting date',
                body: <p>
                    The reporting date could not be deleted from the source because
                    of an error: {err?.response?.data?.message || err.message}
                </p>
            });
        });
    }

    function addPayoutDate(date) {
        if (date === undefined || date === '') {
            return;
        }

        const token = user ? user.token : '';
        const data = {
            source: sourceId,
            right: rightId,
            date: date
        }
        axios.post('source/payoutdates', data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status === 201) {
                reloadData();
                setModal(INITIAL_MODAL_STATE);
            }
        }).catch(err => {
            console.error(err);
            setModal({
                show: true,
                title: 'Failed to add reporting date',
                body: <p>
                    The payout date could not be added to the source because of
                    an error: {err?.response?.data?.message || err.message}
                </p>
            })
        })
    }

    function createTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={3}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            var date = item.date;
            try {
                date = new Date(date).toLocaleDateString();
            } catch (error) {
                date = item.date;
            }
            return <tr key={idx}>
                <td>{ idx + 1 }</td>
                <td>{ date }</td>
                <td><Button size={'sm'} variant={'outline-danger'} onClick={() => deletePayoutDateModal(item.date)}>Delete</Button></td>
            </tr>
        })
    }

    function showAddPayoutDateModal() {
        setModal({
            show: true,
            title: 'Add new reporting date',
            body: <SourcePayoutDateModal callbackFunction={addPayoutDate} />
        })
    }

    return (
        <div style={styles.marginTop}>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <h5>{right}</h5>
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
            <Button variant={'success'} onClick={() => showAddPayoutDateModal()}>Add Reporting Date</Button>
        </div>
    )
}

SourcePayoutDates.propTypes = {
    /**
     * ID of the source
     */
    sourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    /**
     * ID of the right
     */
    rightId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /**
     * Name or description of the right
     */
    right: PropTypes.string.isRequired,
    /**
     * Array with payout dates
     */
    payoutDates: PropTypes.array.isRequired,
    /**
     * Callback function for reloading the data
     */
    reloadData: PropTypes.func.isRequired
}

export default SourcePayoutDates;
