import React, { useState, useEffect, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import AppModal from "../../components/Modal/Modal";
import { getUser } from "../../utils/requireAuth";
import axios from '../../utils/axiosInstance';
import './Authentication.css';
import { FloatingLabel } from "react-bootstrap";

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const INITIAL_STATE = {
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    password2: ''
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'setValue':
            return { ...state, [action.key]: action.payload }
        default:
            throw new Error(`Action type ${action.type} is no part of reducer in SignUp.jsx`);
    }
}

export default function SignUp() {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const user = getUser();
    let navigate = useNavigate();

    /**
     * Bring user back to homepage if it is available
     */
    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [user]);

    /**
     * Checks if the passwords are available and the same
     * @returns {bool} True if the passwords are available
     */
    function checkPassword() {
        return state.password !== '' && state.password === state.password2;
    }

    /**
     * Checks if the values for username, firstname, lastname and email
     * are complete.
     * @returns {bool} True if all values are complete
     */
    function dataComplete() {
        if (state.userName === '') { return false; }
        if (state.firstName === '') { return false; }
        if (state.lastName === '') { return false; }
        if (state.email === '') { return false; }
        return true;
    }

    /**
     * Function handles the signup of the user.
     * @param {object} e Event from the form
     */
    function signUpHandler(e) {
        e.preventDefault();

        if (!dataComplete()) {
            setModal({
                show: true,
                title: 'Userdata incomplete',
                body: <p>The entered data for the sign up is incomplete</p>
            });
            return;
        }

        if (!checkPassword()) {
            setModal({
                show: true,
                title: 'Password invalid',
                body: <p>The passwords are empty or not the same</p>
            });
            return;
        }

        axios.post('account/signup', state).then(response => {
            navigate('/signin');
        }).catch(err => {
            displayErrorsFromResponse(err.response.data);
        });
    }

    /**
     * Function displays the error messages from the backend
     * to the user.
     * @param {object} responseData Response data from the signup
     */
    function displayErrorsFromResponse(responseData) {
        const errors = [];
        let iterator = {};

        if (responseData.errors) {
            iterator = responseData.errors;
        } else {
            iterator = responseData;
        }

        for (const [_, value] of Object.entries(responseData)) {
            errors.push(<li>{value[0]}</li>);
        }

        setModal({
            show: true,
            title: 'Sign Up failed',
            body: (
                <div>
                    <p>The signup failed because of the following errors:</p>
                    <ul>{errors}</ul>
                </div>
            )
        });
    }

    return (
        <div>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <div className="AuthenticationCard">
                <div className="Center">
                    <h1>Sign Up</h1>
                </div>
                <Form>
                    <FloatingLabel className={'mb-2'} label={'Username'}>
                        <Form.Control value={state.userName} onChange={(e) => dispatch({ type: 'setValue', key: 'userName', payload: e.target.value })} placeholder={'Username...'} />
                    </FloatingLabel>

                    <FloatingLabel className={'mb-2'} label={'First name'}>
                        <Form.Control value={state.firstName} onChange={(e) => dispatch({ type: 'setValue', key: 'firstName', payload: e.target.value })} placeholder={'First name...'} />
                    </FloatingLabel>

                    <FloatingLabel className={'mb-2'} label={'Last name'}>
                        <Form.Control value={state.lastName} onChange={(e) => dispatch({ type: 'setValue', key: 'lastName', payload: e.target.value })} placeholder={'Last name...'} />
                    </FloatingLabel>

                    <FloatingLabel className={'mb-2'} label={'E-Mail'}>
                        <Form.Control value={state.email} onChange={(e) => dispatch({ type: 'setValue', key: 'email', payload: e.target.value })} placeholder={'E-Mail...'} />
                    </FloatingLabel>

                    <FloatingLabel className={'mb-2'} label={'Password'}>
                        <Form.Control value={state.password} onChange={(e) => dispatch({ type: 'setValue', key: 'password', payload: e.target.value })} placeholder={'Password...'} />
                    </FloatingLabel>

                    <FloatingLabel className={'mb-2'} label={'Confirm password'}>
                        <Form.Control value={state.password2} onChange={(e) => dispatch({ type: 'setValue', key: 'password2', payload: e.target.value })} placeholder={'Confirm password...'} />
                    </FloatingLabel>

                    <Button className="ButtonWidth100" variant='success' type='submit' onClick={signUpHandler}>Sign Up</Button>
                </Form>
                <div className={'mt-2'}>
                    <Link to='/signin' className="AuthenticationSwitchLink">Back to login</Link>
                </div>
            </div>
        </div>
    )
}