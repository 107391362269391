import { Route, Routes } from 'react-router-dom';
import PublishingHome from "./Home";
import Compositions from "./Publishings";
import CompositionDetail from "./PublishingDetail";
import NewCompositionSplit from 'components/CompositionDetails/CompositionSplitNew';

const CatalogueRoutes = [
    {
        path: '',
        element: <PublishingHome />
    },
    {
        path: 'company-:companyId',
        element: <Compositions />
    },
    {
        path: 'company-:companyId/composition-:compositionId',
        element: <CompositionDetail />
    },
    {
        path: 'company-:companyId/composition-:compositionId/newsplit',
        element: <NewCompositionSplit />
    }
]


export default function PublishingCatalogue(props) {
    return (
        <Routes>
            {CatalogueRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
            })}
        </Routes>
    )
}