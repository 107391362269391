import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../utils/axiosInstance';
import { getUser } from '../../utils/requireAuth';
import { Table, InputGroup, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const styles = {
    alignCenter: {
        textAlign: 'center'
    }
}

const SourceTypeModal = ({
    callbackFunction
}) => {
    const [sourceTypes, setSourceTypes] = useState([]);
    const [search, setSearch] = useState('');
    const tableRows = useMemo(() => mapTableRows(sourceTypes), [sourceTypes]);
    const navigate = useNavigate();
    const user = getUser();

    useEffect(() => {
        populateSourceTypesData(search);
    }, [search]);

    function populateSourceTypesData(sourceType) {
        axios.get('source/types', {
            params: {
                search: sourceType
            }
        }).then(response => {
            if (response.status === 200) {
                setSourceTypes(response.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    function createSourceTypeHandler() {
        if (search === '' || search === null) {
            return;
        }

        const data = {
            type: search
        }
        axios.post('source/types', data).then(response => {
            if (response.status === 201) {
                callbackFunction(response.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    function mapTableRows(data) {
        if (data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={2}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx} onClick={() => callbackFunction(item)}>
                <td>{item.id}</td>
                <td>{item.type}</td>
            </tr>
        })
    }

    return (
        <div>
            <p>
                You can search for a source type or create one by entering the type
            </p>

            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Search</InputGroup.Text>
                <Form.Control
                    placeholder="Search or create a new source type"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} />
                <Button variant={search ? 'success' : 'secondary'} onClick={createSourceTypeHandler}>New</Button>
            </InputGroup>

            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
        </div>
    )
}

SourceTypeModal.propTypes = {
    callbackFunction: PropTypes.func.isRequired
}

export default SourceTypeModal;
