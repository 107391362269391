import React, { useState, useEffect, useMemo } from 'react';
import { Table, InputGroup, Button, Form, Breadcrumb } from 'react-bootstrap';

import AppModal from '../Modal/Modal';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';


const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const styles = {
    alignCenter: {
        textAlign: 'center'
    },
    card: {
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '12px'
    }
}

const CallToActionEditModal = ({ actionId, callbackFunction }) => {
    const [action, setAction] = useState('');
    const user = getUser();

    useEffect(() => {
        requestAction();
    }, []);

    function requestAction() {
        const token = user ? user.token : '';
        axios.get(`/notifications/calltoaction/${actionId}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                const data = response.data;
                setAction(data?.callToAction || '');
            }
        }).catch(err => {
            console.warn(err);
            setAction('');
        })
    }

    function setActionHandler(value) {
        if (value.length <= 50) {
            setAction(value);
        }
    }

    return (
        <div>
            <p>
                The action can be renamed
            </p>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Action</InputGroup.Text>
                <Form.Control
                    placeholder={'Action...'}
                    value={action}
                    onChange={(e) => setActionHandler(e.target.value)} />
            </InputGroup>

            <Button variant={'success'} onClick={() => callbackFunction({ actionId, action })}>Update changes</Button>
        </div>
    )
}

const NotificationActions = () => {
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const [search, setSearch] = useState('');
    const [actions, setActions] = useState([]);
    const tableRows = useMemo(() => createTableRows(actions), [actions])

    const user = getUser();

    useEffect(() => {
        populateCallToActionData();
    }, [search]);

    function populateCallToActionData() {
        const token = user ? user.token : '';
        axios.get('/notifications/calltoaction', {
            params: {
                search: search
            },
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                setActions(response.data);
            }
        }).catch(err => {
            console.warn(err);
            setActions([]);
        })
    }

    function showEditModal(actionId) {
        setModal({
            show: true,
            title: 'Edit call to action',
            body: <CallToActionEditModal actionId={actionId} callbackFunction={updateActionHandler} />
        });
    }

    function updateActionHandler({ actionId, action }) {
        const token = user ? user.token : '';
        const data = {
            id: actionId,
            callToAction: action
        }
        axios.put('notifications/calltoaction', data, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                setModal(INITIAL_MODAL_STATE);
                populateCallToActionData();
            }
        }).catch(err => {
            console.warn(err);
            setModal({
                show: true,
                title: 'Failed to update action',
                body: <p>
                    The update of the action failed because of an error: {err?.response?.data?.message || err.message}
                </p>
            });
        })
    }

    function setSearchHandler(value) {
        if (value.length <= 50) {
            setSearch(value);
        }
    }

    function createCallToAction() {
        const token = user ? user.token : '';
        const data = {
            callToAction: search
        }
        axios.post('/notifications/calltoaction', data, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 201) {
                setSearch('');
            }
        }).catch(err => {
            console.warn(err);
            setModal({
                show: true,
                title: 'Failed to create action',
                body: <p>
                    The action could not be created because of an error: {err?.response?.data?.message || err.message}
                </p>
            })
        })
    }

    function createTableRows(data) {
        if (!data || data.length === 0) {
            return <tr style={styles.alignCenter}>
                <td colSpan={3}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr key={idx}>
                <td>{item.id}</td>
                <td>{item.callToAction}</td>
                <td>
                    <Button size={'sm'} variant={'primary'} onClick={() => showEditModal(item.id)}>Edit</Button>
                </td>
            </tr>
        })
    }

    return (
        <div>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <Breadcrumb>
                <Breadcrumb.Item href={'/metadata'}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={'/metadata/notificationactions'} active={true}>Notification Actions</Breadcrumb.Item>
            </Breadcrumb>
            <h3>Actions</h3>
            <p>
                Actions are used to tell the user how to deal with the alert he or she is facing.
                Is it something that they should do or do they only need to wait because HELGA works?
            </p>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Searchtext</InputGroup.Text>
                <Form.Control
                    placeholder={'Call to action'}
                    value={search}
                    onChange={(e) => setSearchHandler(e.target.value)} />
                <Button variant={search.length === 0 ? 'secondary' : 'success'} onClick={createCallToAction}>New</Button>
            </InputGroup>
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Call to action</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </Table>
        </div>
    )
}

export default NotificationActions;
