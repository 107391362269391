import { Route, Routes } from 'react-router-dom';
import Company from './Company/Company';
import User from './User/User';
import PageHome from '../../components/PageHome/PageHome';
import Notifications from './Notifications/Notifications';
import RecordingCatalogue from './Catalogue/Recording/Catalogue';
import PublishingCatalogue from './Catalogue/Publishing/Catalogue';
import PageNotExistend from 'components/404/404';

const elements = [
    {
        title: 'Subscriptions',
        message: 'In the subscriptions all the information about the contract that is necessary for accessing the HELGA system can be managed. This includes the product version, available features but also the start and end date of the subscription and the amount of licences. Please be aware that this feature doesn\'t exist yet but will be added soon.',
        link: 'customers/subscription'
    },
    {
        title: 'Users',
        message: 'The user management is the central point where the mapping between the account of HELGA authentication system and all other HELGA systems happens. Also here the companies that will be displayed when the user logs into HELGA.app can be managed.',
        link: 'customers/users'
    },
    {
        title: 'Companies',
        message: 'In the company management it is possible to search for available companies, get all the information for it and edit the information about the companies and user. Also in the company management, the mapping between the company itself and the account for the application can be found.',
        link: 'customers/company'
    },
    {
        title: 'Recording Catalogue',
        message: 'In the track catalogue management the track catalogues for companies can be retieved and the track details can be shown and edited. Included is also the split management and information about ISRC',
        link: 'customers/catalogue/recording'
    },
    {
        title: 'Publishing Catalogue',
        message: 'In the publishing catalogue management the publishing catalogues for companies can be retieved and the work details can be shown and edited. Included is also the split management and further meta data',
        link: 'customers/catalogue/publishing'
    },
    {
        title: 'Alert cards',
        message: 'In the alert cards the notifications that are shown to the user can be viewed and edited. You can change the displayed text as well as the status, the impact or potential actions that need to be done by the user.',
        link: 'customers/notifications'
    }
]

const CustomerRoutes = [
    {
        path: '',
        element: <PageHome elements={elements} siteHeading={'Customers Home'} />
    },
    {
        path: 'company/*',
        element: <Company />
    },
    {
        path: 'notifications/*',
        element: <Notifications />
    },
    {
        path: 'catalogue/recording/*',
        element: <RecordingCatalogue />
    },
    {
        path: 'catalogue/publishing/*',
        element: <PublishingCatalogue />
    },
    {
        path: 'users/*',
        element: <User />
    },
    {
        path: 'subscription/*',
        element: <PageNotExistend />
    }
]

export default function Customers(props) {
    return (
        <Routes>
            {CustomerRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />
            })}
        </Routes>
    )
}
