import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { InputGroup, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import AppModal from '../Modal/Modal';
import axios from '../../utils/axiosInstance';
import CompanyTable from 'components/CompanyTable/CompanyTable';
import { _ } from 'ajv';

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const INTIIAL_STATE = {
  compositionDetails: {
    id: '',
    title: '',
    subtitle: '',
    releaseDate: '',
    payingParty: null,
    payingPartyName: ''
  },
  updated: false
}

const ELEMENTS = [
  ['id', { type: 'input', label: 'ID', editable: false }],
  ['title', { type: 'input', label: 'Title', editable: true }],
  ['subtitle', { type: 'input', label: 'Subtitle', editable: true }],
  ['payingParty', { type: 'payingParty', label: 'Paying Party', editable: false }],
  ['payingPartyName', { type: 'input', label: 'Paying Party', editable: false }],
  ['releaseDate', { type: 'date', label: 'First Release', editable: true }]
]

const styles = {
  inputFields: {
    width: '20%',
    minWidth: '120px'
  },
  fullWidth: {
    width: '100%'
  }
}

/**
 * Formats the date string into yyyy-mm-dd
 * @param {string} dateString Date as a string
 * @returns Formatted date string
 */
const getDateString = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month && month < 10 ? `0${month}` : month}-${day && day < 10 ? `0${day}` : day}`;
}

/**
 * Manages the state of the component
 * @param {object} state Current state of the component
 * @param {object} action Changes that should be applied to the component
 * @returns The new state of the component
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'load':
      const releaseDate = action.payload.releaseDate;
      if (releaseDate) {
        const date = getDateString(releaseDate);
        action.payload.releaseDate = date;
      }

      return { ...state, compositionDetails: action.payload, updated: false }
    case 'update':
      return {
        ...state,
        compositionDetails: {
          ...state.compositionDetails,
          [action.key]: action.payload
        },
        updated: true
      }
    case 'saved':
      return { ...state, updated: false }
    case 'setPayingParty':
      return {
        ...state,
        compositionDetails: {
          ...state.compositionDetails,
          payingParty: action.payload.id,
          payingPartyName: action.payload.name
        },
        updated: true
      }
    default:
      throw new Error(`Action type ${action.type} is no part of reducer in InputFields.jsx`);
  }
}

const CompositionInputFields = ({ compositionId }) => {
  const itemSettings = new Map(ELEMENTS);
  const [state, dispatch] = useReducer(reducer, INTIIAL_STATE);
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const items = useMemo(() => createComponents(), [state.compositionDetails]);

  /**
   * Requests the information about the composition every time
   * the compositionID changes
   */
  useEffect(() => {
    if (compositionId) {
      populateCompositionData(compositionId);
    }
  }, [compositionId]);

  /**
   * Gets the information of the composition from the api
   * @param {number} compositionId ID of the composition
   */
  function populateCompositionData(compositionId) {
    axios.get('catalogue/publishing', {
      params: {
        work: compositionId
      }
    }).then(response => {
      if (response.status === 200) {
        dispatch({ type: 'load', payload: response.data });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  /**
   * Saves all changes of the data in the database.
   */
  function saveChangesHandler() {
    if (state.updated === false) {
      return;
    }

    const data = {
      id: state.compositionDetails.id,
      title: state.compositionDetails.title,
      subtitle: state.compositionDetails.subtitle,
      releaseDate: state.compositionDetails.releaseDate,
      payingParty: state.compositionDetails.payingParty,
    }
    axios.put('catalogue/publishing', data).then(response => {
      if (response.status === 200) {
        setModal({
          show: true,
          title: 'Update successfull',
          body: (
            <p>
              The Update of the data was successfull!
            </p>
          )
        });
        dispatch({ type: 'saved' });
      }
    }).catch(err => {
      console.log(err);
      setModal({
        show: true,
        title: 'Update failed',
        body: (
          <p>
            The Update of the composition details failed because of: {err.message}
          </p>
        )
      });
    });
  }

  function showSelectPayingPartyHandler() {
    setModal({
      show: true,
      title: 'Select paying party for publishing',
      body: <CompanyTable link={'company/search'} callbackFunction={setPayingPartyHandler} />
    })
  }

  function setPayingPartyHandler(companyId) {
    axios.get('company', {
      params: {
        companyId: companyId
      }
    }).then(response => {
      if (response.status === 200) {
        dispatch({ type: 'setPayingParty', payload: response.data });
        setModal(INITIAL_MODAL_STATE);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  /**
   * Function creates the JSX.Elements for the given input fields in ELEMENTS
   * @returns JSX.Elements of the input fields
   */
  function createComponents() {
    const items = [];
    itemSettings.forEach((item, key) => {
      switch (item.type) {
        case 'input':
          items.push(
            <InputGroup key={items.length} className={'mb-2'}>
              <InputGroup.Text style={styles.inputFields}>{item.label}</InputGroup.Text>
              <Form.Control
                placeholder={item.label + '...'}
                value={state.compositionDetails[key] ? state.compositionDetails[key] : ''}
                disabled={!item.editable}
                readOnly={!item.editable}
                onChange={(e) => dispatch({ type: 'update', key: key, payload: e.target.value })} />
            </InputGroup>
          );
          break;
        case 'date':
          items.push(
            <InputGroup key={items.length} className={'mb-2'}>
              <InputGroup.Text style={styles.inputFields}>{item.label}</InputGroup.Text>
              <Form.Control
                type={'date'}
                placeholder={'release date'}
                value={state.compositionDetails[key] ? state.compositionDetails[key] : ''}
                onChange={(e) => dispatch({ type: 'update', key: key, payload: e.target.value })} />
            </InputGroup>
          )
          break;
        case 'payingParty':
          items.push(
            <InputGroup key={items.length} className={'mb-2'}>
              <InputGroup.Text style={styles.inputFields}>{item.label}</InputGroup.Text>
              <Form.Control
                value={state.compositionDetails[key] ? state.compositionDetails[key] : 'N/A'}
                readOnly={true}
              />
              <Button variant={'outline-secondary'} onClick={showSelectPayingPartyHandler}>Select</Button>
            </InputGroup>
          )
          break;
        default:
          throw new Error(`The item type ${item.type} is not specified in CreateComponents`);
      }
    })
    return items;
  }

  return (
    <div className={'mb-4 mt-4'}>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
      {items}
      <Button style={styles.fullWidth} variant={state.updated ? 'success' : 'secondary'} onClick={() => saveChangesHandler()}>{state.updated ? 'Save changes' : 'Nothing to save'}</Button>
    </div>
  )
}

CompositionInputFields.propTypes = {
  /**
   * ID of the composition
   */
  compositionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default CompositionInputFields;
