import { Route, Routes } from 'react-router-dom';
import UserHome from './UserHome';
import Details from './Details';
import UserAccount from './UserAccount';


const UserRoutes = [
  {
    path: '',
    element: <UserHome />
  },
  {
    path: ':userId',
    element: <Details />
  },
  {
    path: ':userId/account',
    element: <UserAccount />
  }
]

const User = () => {
  return (
    <Routes>
      {UserRoutes.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />
      })}
    </Routes>
  )
}

export default User;