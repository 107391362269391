import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { Table, FloatingLabel, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Paging from 'components/Paging/Paging';
import axios from 'utils/axiosInstance';
import './UserCompaniesTable.css';

const INITIAL_STATE = {
  startIndex: 0,
  totalCount: 0,
  pageNumber: 0,
  pageSize: 10,
  items: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'load':
      return {
        ...state,
        totalCount: action.payload.totalCount,
        pageNumber: action.payload.pageNumber,
        startIndex: action.payload.recordNumber,
        items: action.payload.items
      }
    case 'reset':
      return { ...INITIAL_STATE };
    case 'pageNumber':
      const maxPageNumber = Math.ceil(state.totalCount / state.pageSize);
      const pageNumber = Math.min(Math.max(action.payload, 1), maxPageNumber)
      return {
        ...state,
        pageNumber: pageNumber,
        startIndex: (pageNumber - 1) * state.pageSize
      }
    default:
      throw new Error(`Action type ${action.type} is no part of reducer in UserCompaniesTable.jsx`);
  }
}

export default function UserCompaniesTable({ userId, reloadTrigger, searchEnabled, actionType, link, callbackFunction }) {
  const [search, setSearch] = useState('');
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const tableRows = useMemo(() => createTableRows(state.items), [state.items]);

  /**
   * Function gets executed every time the search parameter or
   * the reload trigger changes.
   */
  useEffect(() => {
    populateCompaniesData(search);
  }, [search, searchEnabled, actionType, link, userId, state.startIndex, state.pageSize, state.pageNumber]);

  useEffect(() => {
    populateCompaniesData(search);
  }, [reloadTrigger])

  /**
   * Function fetches the user companies from the backend. Depending
   * on if the search is enabled, an additional parameter "search"
   * is added to the request.
   * @param {string} searchParam Searchtext that was entered by the customer
   */
  function populateCompaniesData(searchParam) {
    const queryParameters = {
      userId: userId,
      startIndex: state.startIndex,
      pageSize: state.pageSize
    }

    // If search is enabled, also give the current search parameter as query parameter
    if (searchEnabled) {
      queryParameters['search'] = searchParam;
    }

    axios.get(link, {
      params: queryParameters
    }).then(response => {
      if (response.status === 200) {
        dispatch({ type: 'load', payload: response.data });
      }
    }).catch(err => {
      console.log(err);
      dispatch({ type: 'reset' });
    });
  }

  /**
   * Calls the callback function and reloads the data for the table
   * @param {number} assignedCompany ID of the company
   */
  async function actionClickHandler(assignedCompany) {
    const result = await callbackFunction(assignedCompany);
    if (result) {
      populateCompaniesData(search);
    }
  }

  /**
   * Maps the array with the items for the user companies and creates the table rows
   * @param {array} data User companies that need to be displayed
   * @returns JSX.Elements that represent the table rows
   */
  function createTableRows(data) {
    return data.map((item, idx) => {
      return (
        <tr key={idx}>
          <td>{item.id}</td>
          <td>{item.name}</td>
          <td>{item.artistName}</td>
          <td style={{ 'textAlign': 'center' }}>
            <Button
              size='sm'
              variant={actionType === 'delete' ? 'outline-danger' : 'outline-success'}
              onClick={() => actionClickHandler(item.id)}>{actionType === 'delete' ? 'Delete' : 'Add'}</Button>
          </td>
        </tr>
      );
    });
  }

  return (
    <div>
      {searchEnabled &&
        <FloatingLabel className="mb-2" label={'🔍 Searchtext'}>
          <Form.Control
            placeholder="Searchtext"
            aria-label="Searchtext"
            value={search}
            onChange={(e) => setSearch(e.target.value)} />
        </FloatingLabel>
      }
      <div className="UserCompaniesTable">
        <Table bordered striped hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>ArtistName</th>
              <th style={{ 'textAlign': 'center' }}>{actionType === 'delete' ? 'Delete' : 'Add'}</th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </Table>
      </div>
      <Paging currentPage={state.pageNumber} totalCount={state.totalCount} pageSize={state.pageSize} callbackFunction={(i) => dispatch({ type: 'pageNumber', payload: i })} />
    </div>
  )
}

UserCompaniesTable.propTypes = {
  /**
   * ID of the company for which the associated users should be requested
   */
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * Information about wheather the item can be deleted or added
   */
  actionType: PropTypes.oneOf(['delete', 'add']).isRequired,
  /**
   * Callback function that gets executed when the user clicks on the button in
   * the respective row. It is expected that the function takes the userid as input.
   */
  callbackFunction: PropTypes.func.isRequired,
  /**
   * URL from where the data about the usercompanies gets requested
   */
  link: PropTypes.string.isRequired,
  /**
   * Information if searching of a company is enabled or disabled
   */
  searchEnabled: PropTypes.bool.isRequired,
  /**
   * Something that triggers the table to reload the data
   */
  reloadTrigger: PropTypes.any
}