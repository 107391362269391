import axios, { AxiosError } from 'axios';
import { shouldRenewToken } from './requireAuth';

const instance = axios.create({
    baseURL: '/api/'
});

let isRefreshing = false;

const renewToken = async (token) => {
    try {
        const response = await instance.post('account/renewtoken', {}, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (response.status === 200) {
            return response.data.token;
        }

        return '';
    } catch (error) {
        console.log(error);
        return '';
    }
}

instance.interceptors.request.use(
    async (config) => {
        const userData = localStorage.getItem('user');
        const user = JSON.parse(userData);
        if (user && user.token) {
            const tokenRenew = shouldRenewToken(user.token);

            if (tokenRenew === -1) {
                throw new AxiosError(
                    `Token is invalid and cannot be refreshed`,
                    401,
                    {},
                    { responseURL: '' },
                    { status: 401 }
                );
            }

            if (tokenRenew === 1 && !isRefreshing) {
                isRefreshing = true;
                const newToken = await renewToken(user.token);
                user.token = newToken;
                localStorage.setItem('user', JSON.stringify(user));
                isRefreshing = false;
            }
        }

        if (user && user.token) {
            config.headers['Authorization'] = `Bearer ${user.token}`;
        }

        return config;
    }, (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => res,
    (error) => {
        console.log(error);
        if ([401].includes(error.response.status) && !error.request.responseURL.includes('/api/account')) {
            localStorage.clear();
            window.location.href = '/signin';
        } else {
            return Promise.reject(error);
        }
    }
)

export default instance;
