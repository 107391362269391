import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, InputGroup, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import Paging from '../Paging/Paging';
import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';

const INITIAL_STATE = {
    tracks: [],
    selectedTracks: [],
    startIndex: 0,
    totalCount: 0,
    pageNumber: 0,
    pageSize: 10
}

const styles = {
    center: { textAlign: 'center' },
    small: { width: '100px' }
}

/**
 * Manipulates the state of the component.
 * @param {object} state Current state of the component
 * @param {object} action Action that specifies how the state should be manipulated
 * @returns New state of the component
 */
const reducer = (state, action) => {
    switch (action.type) {
        case 'load':
            return {
                ...state,
                tracks: action.payload.items,
                pageNumber: action.payload.pageNumber,
                startIndex: action.payload.recordNumber,
                totalCount: action.payload.totalCount
            }
        case 'add':
            return {
                ...state,
                selectedTracks: [...state.selectedTracks, action.payload]
            };
        case 'remove':
            let tracks = [...state.selectedTracks];
            tracks.splice(action.payload, 1);
            return { ...state, selectedTracks: tracks }
        case 'paging':
            const maxPageNumber = Math.ceil(state.totalCount / state.pageSize);
            const pageNumber = Math.min(Math.max(action.payload, 1), maxPageNumber);
            return {
                ...state,
                pageNumber: pageNumber,
                startIndex: (pageNumber - 1) * state.pageSize
            }
        default:
            throw new Error(`Action type ${action.type} is no part of reducer in TrackExportSelection.jsx`);
    }
}

/**
 * Creates the JSX elements for displaying the track selection for the export.
 * @param {object} props Properties of the component
 * @returns JSX elements
 */
export default function TrackExportSelection(props) {
    const [search, setSearch] = useState('');
    const [company, setCompany] = useState(undefined);
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    // Create the table rows
    const tableRows = useMemo(() => mapTableRows(state.tracks), [state.tracks]);
    const selectedTrackRows = useMemo(() => mapTableRows(state.selectedTracks, false), [state.selectedTracks]);

    const user = getUser();
    let navigate = useNavigate();

    /**
     * Loads the company details when loading the page
     */
    useEffect(() => {
        getCompanyDetails();
    }, []);

    /**
     * Function gets executed every time a track gets selected
     * or when the user changes the search parameter or the page
     */
    useEffect(() => {
        populateTracksData();
    }, [state.pageNumber, state.pageSize, search, state.selectedTracks]);

    /**
     * Function requests the tracks that are not already
     * selected by the user and that correspond to the
     * given parameters
     */
    function populateTracksData() {
        let params = `?startIndex=${state.startIndex}&pageSize=${state.pageSize}&companyId=${props.companyId}`;

        for (let i = 0; i < state.selectedTracks.length; i++) {
            params += `&avoidTrack=${state.selectedTracks[i].track}`;
        }

        if (search !== '') {
            params += `&search=${search}`
        }

        axios.get('/trackcatalogue/search/forexport' + params).then(response => {
            dispatch({ type: 'load', payload: response.data });
        }).catch(err => {
            console.log(err);
        });
    }

    /**
     * Requests the company details for displaying the name of the
     * company on the page
     */
    function getCompanyDetails() {
        const token = user ? user.token : '';
        axios.get('company/details', {
            params: { companyid: props.companyId },
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            setCompany(response.data);
        }).catch(err => {
            console.log(err);
        });
    }

    /**
     * Adds a track from the search table to the table
     * with selected tracks
     * @param {number} idx Index of the track
     */
    function addTrackToSelected(idx) {
        let track = { ...state.tracks[idx] };

        // Check if track is already in the list of selected tracks
        for (let i = 0; i < state.selectedTracks.length; i++) {
            const element = state.selectedTracks[i];
            if (element.track === track.track) {
                return;
            }
        }

        if (track !== null) {
            dispatch({ type: 'add', payload: track });
        }
    }

    /**
     * Function calls the endpoint for creating the csv file and
     * downloads it
     */
    function exportTrackHandler() {
        if (state.selectedTracks.length === 0) {
            return;
        }
        const token = user ? user.token : '';
        let params = `?companyId=${props.companyId}`;
        for (let i = 0; i < state.selectedTracks.length; i++) {
            const element = state.selectedTracks[i];
            params += `&track=${element.track}`;
        }
        axios.get('trackcatalogue/export/selected' + params, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;

            if (company != null) {
                let companyName = company.name;
                while (companyName.includes(" ")) {
                    companyName = companyName.replace(" ", "_");
                }
                link.download = 'Trackcatalogue_' + companyName + '.csv';
            } else {
                link.download = 'Trackcatalogue.csv';
            }
            link.click();
        }).catch(err => {
            console.log(err);
        });
    }

    /**
     * Creates the JSX elements for the table rows.
     * @param {array} data Array of the track data
     * @param {bool} showAddButton Value indicates if the add button
     * or the remove button should be shown. Defaults to true
     * @returns JSX.Elements
     */
    function mapTableRows(data, showAddButton = true) {
        if (data.length === 0) {
            return <tr style={styles.center}>
                <td colSpan={5}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return (
                <tr key={idx}>
                    <td>{item.track}</td>
                    <td>{item.title}</td>
                    <td>{item.artists}</td>
                    <td>{item.attentionNeeded ? 'Yes' : 'No'}</td>

                    {
                        showAddButton ?
                            <td style={styles.center}><Button variant='outline-secondary' size='sm' onClick={() => addTrackToSelected(idx)}>Add</Button></td>
                            :
                            <td style={styles.center}><Button variant='outline-danger' size='sm' onClick={() => dispatch({ type: 'remove', payload: idx })}>Remove</Button></td>
                    }
                </tr>
            )
        });
    }

    return (
        <div>
            <h3>Select Tracks{company ? ' from ' + company.name : ''}</h3>
            <InputGroup className='mb-2'>
                <InputGroup.Text>Searchtext</InputGroup.Text>
                <Form.Control
                    placeholder='Searchtext...'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} />
            </InputGroup>
            <div className='TrackExportSelectionTable'>
                <Table bordered hover striped>
                    <thead>
                        <tr>
                            <th style={styles.small}>Track</th>
                            <th>Title</th>
                            <th>Artists</th>
                            <th>Attention</th>
                            <th style={{ ...styles.center, ...styles.small }}>Add</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </Table>
            </div>
            <Paging currentPage={state.pageNumber} totalCount={state.totalCount} pageSize={state.pageSize} callbackFunction={(i) => dispatch({ type: 'paging', payload: i })} />

            <h3>Selected Tracks</h3>
            <div className='TrackExportSelectionTable'>
                <Table bordered hover striped>
                    <thead>
                        <tr>
                            <th style={styles.small}>Track</th>
                            <th>Title</th>
                            <th>Artists</th>
                            <th>Attention</th>
                            <th style={{ ...styles.center, ...styles.small }}>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedTrackRows}
                    </tbody>
                </Table>
            </div>

            <h3>Track Export as csv</h3>
            <Button variant={state.selectedTracks.length > 0 ? 'success' : 'secondary'} onClick={() => exportTrackHandler()}>Export Track Catalogue</Button>
        </div>
    )
}

TrackExportSelection.propTypes = {
    /**
     * ID of the company
     */
    companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}