import React, { useState, useEffect, useMemo } from 'react';
import { Button, Col, Row, Table, FloatingLabel, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AppModal from 'components/Modal/Modal';
import axios from 'utils/axiosInstance';
import { Callbacks } from 'jquery';
import { propTypes } from 'react-bootstrap/esm/Image';

const INITIAL_MODAL_STATE = {
  show: false,
  title: '',
  body: null
}

const AddWorkIdentifierModal = ({ workId, callbackFunction }) => {
  const [identifierTypes, setIdentifierTypes] = useState([]);
  const [identifier, setIdentifier] = useState('');
  const [type, setType] = useState(1);

  useEffect(() => {
    populateIdentifierTypes();
  }, []);

  function populateIdentifierTypes() {
    axios.get('options/identifierTypes').then(response => {
      if (response.status === 200) {
        setIdentifierTypes(response.data);
      }
    }).catch(err => {
      console.log(err);
      setIdentifierTypes([]);
    })
  }

  function saveWorkIdentifier() {
    if (identifier === '' || type === 0) {
      return;
    }

    const data = {
      work: workId,
      identifier: identifier,
      identifierType: type
    }

    callbackFunction(data);
  }

  return (
    <div>
      <FloatingLabel label={"Identifier"} className={'mb-2'}>
        <Form.Control placeholder="Identifier" value={identifier} onChange={(e) => setIdentifier(e.target.value)} />
      </FloatingLabel>

      <FloatingLabel label={'Identifier Type'} className={'mb-2'}>
        <Form.Select value={type} onChange={(e) => setType(Number(e.target.value))}>
          <option value={0}>Select</option>
          {
            identifierTypes.length > 0 &&
            identifierTypes.map((item, idx) => {
              return <option key={idx} value={item.id}>{item.name}</option>
            })
          }
        </Form.Select>
      </FloatingLabel>

      <Button variant={'success'} onClick={saveWorkIdentifier} className={'w-100'}>Save</Button>

    </div>
  )
}

AddWorkIdentifierModal.propTypes = {
  /**
   * The ID of the musical work
   */
  workId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * The callback function that takes an workIdentifier as an input and creates it then
   */
  callbackFunction: PropTypes.func.isRequired
}

const WorkIdentifiers = ({ workId }) => {
  const [identifierTypes, setIdentifierTypes] = useState(new Map());
  const [modal, setModal] = useState(INITIAL_MODAL_STATE);
  const [identifiers, setIdentifiers] = useState([]);
  const tableRows = useMemo(() => createTableRows(identifiers), [identifiers]);

  useEffect(() => {
    populateWorkIdentifiers();
  }, []);

  function populateWorkIdentifiers() {
    axios.get('catalogue/publishing/identifier', {
      params: {
        workId: workId
      }
    }).then(response => {
      if (response.status === 200) {
        setIdentifiers(response.data);
      }
    }).catch(err => {
      console.log(err);
      setIdentifiers([]);
    });
  }

  useEffect(() => {
    populateIdentifierTypes();
  }, []);

  function populateIdentifierTypes() {
    axios.get('options/identifierTypes').then(response => {
      if (response.status === 200) {
        const data = new Map(response.data.map(item => [item.id, item]));
        setIdentifierTypes(data);
      }
    }).catch(err => {
      console.log(err);
      setIdentifierTypes([]);
    })
  }

  function createTableRows(data) {
    if (data.length === 0) {
      return <tr className={'text-center'}>
        <td colSpan={3}>No data available</td>
      </tr>
    }

    return data.map((item, idx) => {
      var identifierType;
      if (identifierTypes) {
        identifierType = identifierTypes.get(item.identifierType);
      }
      return <tr key={idx}>
        <td>{item.identifier}</td>
        <td>{identifierType?.name || item.identifierType}</td>
        <td><Button variant={'outline-danger'} size={'sm'} onClick={() => deleteIdentifierModal(item)}>Delete</Button></td>
      </tr>
    })
  }

  function showAddIdentifierModal() {
    setModal({
      show: true,
      title: 'Add new Work Identifier',
      body: <AddWorkIdentifierModal workId={workId} callbackFunction={addIdentifierHandler} />
    });
  }

  function addIdentifierHandler(identifier) {
    axios.post('catalogue/publishing/identifier', identifier).then(response => {
      if (response.status === 201) {
        populateWorkIdentifiers();
        setModal(INITIAL_MODAL_STATE);
      }
    }).catch(err => {
      setModal({
        show: true,
        title: 'Failed to add the work identifier',
        body: (
          <p>
            The work identifier could not be created due to an error:
            {err?.response?.data?.message || err.message}
          </p>
        )
      });
    });
  }

  function deleteIdentifierModal(identifier) {
    setModal({
      show: true,
      title: `Delete Work Identifier ${identifier.identifier}`,
      body: (
        <div>
          <p>
            Do you really want to delete the work identifier?
          </p>
          <Row>
            <Col>
              <Button className={'w-100'} variant={'secondary'} onClick={() => setModal(INITIAL_MODAL_STATE)}>Cancel</Button>
            </Col>
            <Col>
              <Button className={'w-100'} variant={'danger'} onClick={() => deleteIdentifierHandler(identifier)}>Delete</Button>
            </Col>
          </Row>
        </div>
      )
    })
  }

  function deleteIdentifierHandler(identifier) {
    axios.delete('catalogue/publishing/identifier', {
      params: {
        workId: identifier.work,
        identifier: identifier.identifier,
        identifierType: identifier.identifierType
      }
    }).then(response => {
      if (response.status === 204) {
        populateWorkIdentifiers();
        setModal(INITIAL_MODAL_STATE);
      }
    }).catch(err => {
      setModal({
        show: true,
        title: 'Failed to delete the identifier',
        body: (
          <p>
            The Identifier could not be deleted due to an error:
            {err?.response?.data?.message || err.message}
          </p>
        )
      });
    });
  }

  return (
    <div className={'mb-4'}>
      <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
      <h5>Work Identifiers</h5>
      <p>
        The work identifiers can be an ISWC, a GEMA ID or any other ID that
        uniquely identifies a musical work.
      </p>
      <Table bordered striped hover responsive>
        <thead>
          <tr>
            <th>Identifier</th>
            <th>Identifier Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </Table>
      <Button variant={'success'} onClick={showAddIdentifierModal}>Add Identifier</Button>
    </div>
  )
}

WorkIdentifiers.propTypes = {
  /**
   * The ID of the musical work
   */
  workId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default WorkIdentifiers;
