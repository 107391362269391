import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb, InputGroup, Form, Button, BreadcrumbItem, Dropdown, DropdownButton, Alert, Table } from 'react-bootstrap';

import { getUser } from '../../utils/requireAuth';
import axios from '../../utils/axiosInstance';
import CompanyTable from '../CompanyTable/CompanyTable';
import AppModal from '../Modal/Modal';
import NotificationPayload from './NotificationPayload';

const INITIAL_MODAL_STATE = {
    show: false,
    title: '',
    body: null
}

const INITIAL_STATE = {
    notification: {
        id: undefined,
        company: undefined,
        type: undefined,
        callToAction: undefined,
        status: undefined,
        impact: undefined,
        subject: undefined,
        message: undefined,
        solved: undefined,
        createdAt: undefined,
        updatedAt: undefined
    },
    types: new Map(),
    actions: new Map(),
    impacts: new Map(),
    statuses: new Map(),
    error: false
}

const ELEMENTS = [
    ['id', { type: 'input', label: 'Notification ID', editable: false }],
    ['company', { type: 'company', label: 'Company', editable: false }],
    ['type', { type: 'modalSelect', label: 'Type', editable: false, dataKey: 'types' }],
    ['callToAction', { type: 'modalSelect', label: 'Action', editable: false, dataKey: 'actions' }],
    ['status', { type: 'modalSelect', label: 'Status', editable: false, dataKey: 'statuses' }],
    ['impact', { type: 'modalSelect', label: 'Impact', editable: false, dataKey: 'impacts' }],
    ['subject', { type: 'input', label: 'Subject', editable: true, maxLength: 100 }],
    ['message', { type: 'input', label: 'Message', editable: true, maxLength: 255 }],
    ['solved', { type: 'boolSelect', label: 'Solved', editable: false }],
    ['createdAt', { type: 'date', label: 'Created at', editable: false }],
    ['updatedAt', { type: 'date', label: 'Updated at', editable: false }]
]

const styles = {
    inputWidth: {
        width: '20%',
        minWidth: '120px'
    },
    alignCenter: {
        textAlign: 'center'
    },
    pointer: {
        cursor: 'pointer'
    },
    maxHeight: {
        maxHeight: '50vh',
        overflow: 'auto',
    },
    fullWidth: {
        width: '100%'
    },
    card: {
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '12px'
    },
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'loadNotification':
            return {
                ...state,
                notification: action.payload,
                error: false
            }
        case 'updateNotification':
            return {
                ...state,
                notification: { ...state.notification, [action.key]: action.payload }
            }
        case 'setTypes':
            return { ...state, types: new Map(action.payload.map(item => [item.id, item])) }
        case 'setImpacts':
            return { ...state, impacts: new Map(action.payload.map(item => [item.id, item])) }
        case 'setActions':
            return { ...state, actions: new Map(action.payload.map(item => [item.id, item])) }
        case 'setStatus':
            return { ...state, statuses: new Map(action.payload.map(item => [item.id, item])) }
        case 'errorLoading':
            return {
                ...INITIAL_STATE,
                error: true
            }
        default:
            throw new Error(`Action type ${action.type} is no part of reducer in NotificationDetails.jsx`);
    }
}

const ModalSelection = ({ link, itemKey, callbackFunction }) => {
    const [items, setItems] = useState([]);
    const [search, setSearch] = useState('');
    const rows = useMemo(() => createRows(items), [items]);
    const user = getUser();

    useEffect(() => {
        populateItemsData();
    }, [search]);

    function populateItemsData() {
        const token = user ? user.token : '';
        axios.get(link, {
            params: {
                search: search
            },
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                setItems(response.data);
            }
        }).catch(err => {
            console.warn(err);
            setItems([]);
        })
    }

    function createRows(data) {
        let items = [];
        if (!data || data.length === 0) {
            return <tr style={styles.alignCenter} key={items.length}>
                <td colSpan={2}>No data available</td>
            </tr>
        }

        return data.map((item, idx) => {
            return <tr style={styles.pointer} key={idx} onClick={() => callbackFunction({ key: itemKey, value: item.id })}>
                <td>{item.id}</td>
                <td>{item[itemKey]}</td>
            </tr>
        })
    }

    return (
        <div style={styles.maxHeight}>
            <InputGroup className={'mb-2'}>
                <InputGroup.Text>Searchtext</InputGroup.Text>
                <Form.Control
                    placeholder='Searchtext...'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} />
            </InputGroup>
            <Table bordered striped hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>name</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        </div>
    )
}

const NotificationDetails = () => {
    const itemSettings = new Map(ELEMENTS);
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [modal, setModal] = useState(INITIAL_MODAL_STATE);
    const components = useMemo(() => createComponents(), [state]);

    const user = getUser();
    const { notificationId } = useParams();

    useEffect(() => {
        populateNotificationData({ link: '', key: 'loadNotification' });
        populateNotificationData({ link: '/calltoaction', key: 'setActions' })
        populateNotificationData({ link: '/type', key: 'setTypes' })
        populateNotificationData({ link: '/status', key: 'setStatus' })
        populateNotificationData({ link: '/impact', key: 'setImpacts' })
    }, []);

    function populateNotificationData({ link, key }) {
        const token = user ? user.token : '';
        axios.get(`/notifications${link}`, {
            params: { notificationId },
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                dispatch({ type: key, payload: response.data });
            }
        }).catch(err => {
            console.warn(err);
            if (key === 'loadNotification') {
                dispatch({ type: 'errorLoading' });
            } else {
                dispatch({ type: key, payload: [] });
            }
        });
    }

    function openCompanySelectionModal() {
        setModal({
            show: true,
            title: 'Select company',
            body: <CompanyTable link={'company/search'} callbackFunction={() => { }} />
        })
    }

    function openModalSelection({ key, name }) {
        setModal({
            show: true,
            title: `Select ${name}`,
            body: <ModalSelection itemKey={key} link={`/notifications/${key}`} callbackFunction={updateNotificationHandler} />
        })
    }

    function updateNotificationHandler({ key, value }) {
        dispatch({ type: 'updateNotification', key: key, payload: value });
        setModal(INITIAL_MODAL_STATE);
    }

    function saveChangesHandler() {
        const token = user ? user.token : '';
        const data = { ...state.notification }
        axios.put('/notifications', data, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.status === 200) {
                setModal({
                    show: true,
                    title: 'Update successfull',
                    body: <p>
                        The Update of the notification was successfull.
                    </p>
                });
                populateNotificationData({ link: '', key: 'loadNotification' });
            }
        }).catch(err => {
            console.warn(err);
            setModal({
                show: true,
                title: 'Failed to update Notification',
                body: <p>
                    The Update of the notification failed because of an error: {err?.response?.data?.message || err.message}
                </p>
            });
        })
    }

    function createComponents() {
        const items = [];
        itemSettings.forEach((item, key) => {
            let component;
            switch (item.type) {
                case 'input':
                    component = (
                        <InputGroup key={items.length} className={'mb-2'}>
                            <InputGroup.Text style={styles.inputWidth}>{item.label}</InputGroup.Text>
                            <Form.Control
                                placeholder={`${item.label}...`}
                                value={state.notification[key] ? state.notification[key] : 'N/A'}
                                readOnly={!item.editable}
                                onChange={(e) => dispatch({ type: 'updateNotification', key: key, payload: e.target.value })}
                            />
                        </InputGroup>
                    )
                    items.push(component);
                    break;
                case 'boolSelect':
                    component = (
                        <InputGroup key={items.length} className={'mb-2'}>
                            <InputGroup.Text style={styles.inputWidth}>{item.label}</InputGroup.Text>
                            <Form.Control value={state.notification[key] === true ? 'Yes' : state.notification[key] === false ? 'No' : 'N/A'} readOnly={true} />
                            <DropdownButton variant={'outline-secondary'} title={'Select'} id={key}>
                                <Dropdown.Item onClick={() => dispatch({ type: 'updateNotification', key: key, payload: true })}>True</Dropdown.Item>
                                <Dropdown.Item onClick={() => dispatch({ type: 'updateNotification', key: key, payload: false })}>False</Dropdown.Item>
                            </DropdownButton>
                        </InputGroup>
                    )
                    items.push(component);
                    break;
                case 'date':
                    const date = new Date(state.notification[key]);
                    component = (
                        <InputGroup key={items.length} className={'mb-2'}>
                            <InputGroup.Text style={styles.inputWidth}>{item.label}</InputGroup.Text>
                            <Form.Control
                                placeholder={`${item.label}...`}
                                value={date.toLocaleString()}
                                readOnly={!item.editable}
                                onChange={(e) => dispatch({ type: 'updateNotification', key: key, payload: e.target.value })}
                            />
                        </InputGroup>
                    )
                    items.push(component);
                    break;
                case 'company':
                    component = (
                        <InputGroup key={items.length} className={'mb-2'}>
                            <InputGroup.Text style={styles.inputWidth}>{item.label}</InputGroup.Text>
                            <Form.Control
                                placeholder={`${item.label}...`}
                                value={state.notification[key] ? state.notification[key] : 'N/A'}
                                readOnly={!item.editable}
                            />
                            <Button variant={'outline-secondary'} onClick={openCompanySelectionModal}>Select</Button>
                        </InputGroup>
                    )
                    items.push(component);
                    break;
                case 'modalSelect':
                    let elem = state[item.dataKey].get(state.notification[key]);
                    if (elem) {
                        elem = elem[key];
                    } else {
                        elem = `${key.charAt(0).toUpperCase()}${key.slice(1)} is not available`;
                    }
                    component = (
                        <InputGroup key={items.length} className={'mb-2'}>
                            <InputGroup.Text style={styles.inputWidth}>{item.label}</InputGroup.Text>
                            <Form.Control
                                placeholder={`${item.label}...`}
                                value={elem}
                                readOnly={true}
                            />
                            <Button variant={'outline-secondary'} onClick={() => openModalSelection({ key: key, name: item.label })}>Select</Button>
                        </InputGroup>
                    )
                    items.push(component);
                    break;
                default:
                    break;
            }
        });
        return items;
    }

    return (
        <div>
            <Breadcrumb>
                <BreadcrumbItem href={'/customers'}>Home</BreadcrumbItem>
                <BreadcrumbItem href={'/customers/notifications'}>Notifications</BreadcrumbItem>
                <BreadcrumbItem href={`/customers/notifications/${notificationId}`} active={true}>Details</BreadcrumbItem>
            </Breadcrumb>
            <AppModal show={modal.show} title={modal.title} body={modal.body} closeHandler={() => setModal(INITIAL_MODAL_STATE)} />
            <h1>Notification Details</h1>
            {
                !state.error ?
                    <div style={styles.card}>
                        {components}
                        <Button style={styles.fullWidth} variant={'success'} onClick={saveChangesHandler}>Save changes</Button>
                        <NotificationPayload notificationId={notificationId} />
                    </div>
                    :
                    <Alert variant={'danger'}>
                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                        <p>
                            It looks as if the notification with the ID {notificationId} is not existend
                            in the database. Please make sure that you requested the right information.
                        </p>

                    </Alert>
            }
        </div>
    )
}

export default NotificationDetails;
