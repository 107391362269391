import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'utils/axiosInstance';
import SourceInformation from 'components/SourceDetails/SourceInformation';
import SourceDangerZone from 'components/SourceDetails/SourceDangerZone';
import SourcePos from 'components/SourceDetails/SourcePos';
import SourceRights from 'components/SourceDetails/SourceRights';
import SourceSystem from 'components/SourceDetails/SourceSystem';


const SourceDetails = () => {
    const [sourceName, setSourceName] = useState('');
    const { sourceId } = useParams();

    useEffect(() => {
        function getSourceInformation() {
            axios.get('source', {
                params: {
                    sourceId: sourceId
                }
            }).then(response => {
                if (response.status === 200) {
                    const data = response.data;
                    if (!data) {
                        return;
                    }

                    setSourceName(data.name);
                }
            })
        }

        getSourceInformation();
    }, []);

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item href={'/metadata'}>Home</Breadcrumb.Item>
                <Breadcrumb.Item href={'/metadata/source'}>Sources</Breadcrumb.Item>
                <Breadcrumb.Item href={`/metadata/source/${sourceId}`} active={true}>{sourceName}</Breadcrumb.Item>
            </Breadcrumb>

            <h1>Details for source {sourceName}</h1>

            <SourceInformation sourceId={sourceId} />
            <SourcePos sourceId={sourceId} />
            <SourceRights sourceId={sourceId} />
            <SourceSystem sourceId={sourceId} />

            <hr />

            <SourceDangerZone sourceId={sourceId} />
        </div>
    )
}

export default SourceDetails;
